import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./index.css"
import { resetFilterIcon } from "../../assets/index"
import GenAIDocumentTypesFilterItem from './GenAIDocumentTypesFilterItem';
import FilterService from '../../services/FilterService';
import { AllContent, MISEHCP } from '../../utils/utilityServices'
import { processGenAIDocumentTypeFilters, DocumentTypeFilters, GenAI2DocTypes } from '../../utils/documentTypeUtilityServices'
import { isEqual, sortBy, clone } from 'underscore'

class GenAIDocumentTypesFilters extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFilters: props.selectedFilters,
            renderfilters: props.renderfilters,
            filters: processGenAIDocumentTypeFilters(DocumentTypeFilters),
            openedDocumentType: '',
            isOpenInPopup: props.isOpenInPopup
        }
    }
    componentWillReceiveProps(nextProps) {

        if (nextProps.selectedFilters && this.props.selectedFilters && !isEqual(sortBy(nextProps.selectedFilters), sortBy(this.props.selectedFilters))) {
            this.setState({ selectedFilters: nextProps.selectedFilters });
        }

        // if (nextProps.selectedFilters.length !== this.props.selectedFilters.length) {
        //     this.setState({ selectedFilters: nextProps.selectedFilters });
        // }
        if (nextProps.isOpenInPopup !== this.props.isOpenInPopup) {
            this.setState({ isOpenInPopup: nextProps.isOpenInPopup });
        }


    }
    handleChangeFilter = (filter, isChecked) => {
        let { filters, selectedFilters, renderfilters } = this.state;
        const filterService = new FilterService();
        let newFilters, updatedFilters, tempFilters;
        if (isChecked) {
            newFilters = filterService.addChildLeavesToFilterSet(
                filters,
                filter,
                selectedFilters
            );
            const parentNode = filterService.isFilterInFilterSet(filter, newFilters);
            if (!parentNode) {
                newFilters = filterService.getChildKeysOfFilter(filters, filter);
                newFilters = selectedFilters.concat(newFilters);
                newFilters.push(filter);
                if (
                    filterService.getImmediateChild(
                        filterService.getChildKeysOfFilter(
                            filters,
                            filterService.getParentOfFilter(filter)
                        ),
                        filterService.getParentOfFilter(filter)
                    ).length === 1
                ) {
                    renderfilters.push(filter);
                } else {
                    //let filterArr = filter.split("/");
                    let parentfilter, childcheck, nextNewFilters, immediateChildCheck;
                    // if (filterArr.length > 2) {
                    //   for (let index = 0; index < filterArr.length - 2; index++) {
                    //     // const element = filterArr[index];
                    //     if (filterArr.length > 3) {
                    //       filterArr.pop()
                    //     }
                    //     let element = filterArr.join('/')
                    //     // parentfilter = filterService.getParentOfFilter(element)
                    //     parentfilter = filter.split("/").length > 2
                    //       ? filterService.getParentOfFilter(element)
                    //       : filter;
                    //     childcheck = filterService.getChildKeysOfFilter(filters, parentfilter)
                    //     immediateChildCheck = filterService.getImmediateChild(
                    //       childcheck,
                    //       parentfilter
                    //     );
                    //     nextNewFilters = newFilters.filter((el) => childcheck.includes(el));
                    //     nextNewFilters.push(element)
                    //   }
                    // }
                    // else {
                    //  WORKING WITH IMMEDIATE CHILD
                    parentfilter =
                        filter.split("/").length > 2
                            ? filterService.getParentOfFilter(filter)
                            : filter;
                    childcheck = filterService.getChildKeysOfFilter(
                        filters,
                        parentfilter
                    );
                    immediateChildCheck = filterService.getImmediateChild(
                        childcheck,
                        parentfilter
                    );
                    // newFilters.push(filter);
                    // let filterChildren = filterService.getChildKeysOfFilter(filters, filter)
                    nextNewFilters = newFilters.filter((el) => childcheck.includes(el));
                    // }
                    let commanElements = filterService.findCommonElements(
                        nextNewFilters,
                        immediateChildCheck
                    );
                    if (commanElements) {
                        // if (childcheck.length === nextNewFilters.length) {
                        let newrenderfilter = renderfilters.filter(
                            (item) => !item.includes(parentfilter)
                        );
                        let updatdNewRenderFilter = newrenderfilter.concat(parentfilter);
                        this.setState({ renderfilters: updatdNewRenderFilter }, () => {
                            this.props.handleRenderfilters(updatdNewRenderFilter);
                        });
                    } else {
                        renderfilters.push(filter);
                    }
                }
                // renderfilters.push(filter)
                // newFilters.push(filter)
            } else {
                // if (filterService.getChildKeysOfFilter(filters, filterService.getParentOfFilter(filter)).length === 1) {
                if (
                    filterService.getImmediateChild(
                        filterService.getChildKeysOfFilter(
                            filters,
                            filterService.getParentOfFilter(filter)
                        ),
                        filterService.getParentOfFilter(filter)
                    ).length === 1
                ) {
                    updatedFilters = filterService.addChildLeavesToFilterSet(
                        filters,
                        filter,
                        renderfilters
                    );
                    this.setState({ renderfilters: updatedFilters }, () => {
                        this.props.handleRenderfilters(updatedFilters);
                    });
                } else {
                    // let filterArr = filter.split("/");
                    let parentfilter, childcheck, nextNewFilters, immediateChildCheck;
                    // if (filterArr.length > 2) {
                    //   for (let index = 0; index < filterArr.length - 2; index++) {
                    //     // const element = filterArr[index];
                    //     if (filterArr.length > 3) {
                    //       filterArr.pop()
                    //     }
                    //     let element = filterArr.join('/')
                    //     // parentfilter = filterService.getParentOfFilter(element)
                    //     parentfilter = filter.split("/").length > 2
                    //       ? filterService.getParentOfFilter(element)
                    //       : filter;
                    //     childcheck = filterService.getChildKeysOfFilter(filters, parentfilter)
                    //     immediateChildCheck = filterService.getImmediateChild(
                    //       childcheck,
                    //       parentfilter
                    //     );
                    //     nextNewFilters = newFilters.filter((el) => childcheck.includes(el));
                    //     nextNewFilters.push(element)
                    //   }
                    // }
                    // else {
                    //  WORKING WITH IMMEDIATE CHILD
                    parentfilter =
                        filter.split("/").length > 2
                            ? filterService.getParentOfFilter(filter)
                            : filter;
                    childcheck = filterService.getChildKeysOfFilter(
                        filters,
                        parentfilter
                    );
                    immediateChildCheck = filterService.getImmediateChild(
                        childcheck,
                        parentfilter
                    );
                    nextNewFilters = newFilters.filter((el) => childcheck.includes(el));
                    // }
                    let commanElements = filterService.findCommonElements(
                        nextNewFilters,
                        immediateChildCheck
                    );
                    if (commanElements) {
                        // if (childcheck.length === nextNewFilters.length) {
                        let newrenderfilter = renderfilters.filter(
                            (item) => !item.includes(parentfilter)
                        );
                        let updatdNewRenderFilter = newrenderfilter.concat(parentfilter);
                        this.setState({ renderfilters: updatdNewRenderFilter }, () => {
                            this.props.handleRenderfilters(updatdNewRenderFilter);
                        });
                    } else {
                        updatedFilters = filterService.addChildLeavesToFilterSet(
                            filters,
                            filter,
                            renderfilters
                        );
                        this.setState({ renderfilters: updatedFilters }, () => {
                            this.props.handleRenderfilters(updatedFilters);
                        });
                    }
                }
            }
        } else {
            const hasFilterAndChildren =
                filterService.doesFilterSetHaveAllChildLeaves(
                    filters,
                    filter,
                    selectedFilters
                );
            if (hasFilterAndChildren) {
                newFilters = filterService.removeChildLeavesFromFilterSet(
                    filters,
                    filter,
                    selectedFilters
                );
                let filterChildren = filterService.getChildKeysOfFilter(
                    filters,
                    filter
                );
                newFilters = newFilters.filter((el) => !filterChildren.includes(el));
                // newFilters = newFilters.filter(i => i.split('/').length === filter.split('/').length || i.split('/').length < filter.split('/').length)
            } else {
                newFilters = filterService.addChildLeavesToFilterSet(
                    filters,
                    filter,
                    selectedFilters
                );
            }
            /*Check if the unchecked value is a parentNode*/
            if (newFilters.length > 0) {
                const parentLevel = filterService.findLevelOfSelectedFilter(filter);
                if (parentLevel <= 2) {
                    const parent = filterService.findParentAlreadyExists(
                        filter,
                        renderfilters
                    );
                    if (parent) {
                        let removeChildFilter;
                        tempFilters = filterService.removeChildLeavesFromFilterSet(
                            filters,
                            filter,
                            renderfilters
                        );
                        if (tempFilters.length > 0) {
                            removeChildFilter = tempFilters.filter(
                                (item) => !item.startsWith(filter)
                            );
                            updatedFilters = filterService.addFilterToFilterSet(
                                filter,
                                removeChildFilter
                            );
                        } else {
                            updatedFilters = filterService.addFilterToFilterSet(
                                filter,
                                tempFilters
                            );
                        }
                    } else {
                        tempFilters = filterService.removeFilterFromFilterSet(
                            filter,
                            renderfilters
                        );
                        updatedFilters = filterService.removeChildLeavesFromFilterSet(
                            filters,
                            filter,
                            tempFilters
                        );
                        newFilters = newFilters.filter((x) => x !== filter);
                    }
                } else {
                    const parentNode = filterService.isFilterInFilterSet(
                        filter,
                        newFilters
                    );
                    filterService.removeChildLeavesFromFilterSet(
                        filters,
                        filter,
                        renderfilters
                    );
                    if (parentNode) {
                        var idx1 = newFilters.indexOf(filter);
                        var idx = renderfilters.indexOf(filter);
                        if (idx1 !== -1) newFilters.splice(idx1, 1);
                        if (idx !== -1) renderfilters.splice(idx, 1);
                        updatedFilters = renderfilters;
                    } else {
                        newFilters = filterService.removeChildLeavesFromFilterSet(
                            filters,
                            filter,
                            selectedFilters
                        );
                        updatedFilters = newFilters;
                    }
                    updatedFilters = newFilters.filter(
                        (i) =>
                            i.split("/").length === filter.split("/").length ||
                            i.split("/").length < filter.split("/").length ||
                            i.split("/").length > filter.split("/").length
                    );
                    // updatedFilters = newFilters
                    // updatedFilters = filterService.removeChildLeavesFromFilterSet(
                    //   filters,
                    //   filter,
                    //   renderfilters
                    // )
                    // let parent = filterService.getParentOfFilter(filter)
                    let parent = filter;
                    let parentArr = parent.split("/");
                    // const parent = filterService.findParentAlreadyExists(
                    //   filter,
                    //   renderfilters
                    // );
                    for (let i = 0; i < parentArr.length; i++) {
                        parent = filterService.getParentOfFilter(parent);
                        if (parent) {
                            updatedFilters = updatedFilters.filter((x) => x !== parent);
                            newFilters = newFilters.filter((x) => x !== parent);
                        }
                    }
                    let merged = filterService.getChildKeysForUpdatedFilters(
                        updatedFilters,
                        filters
                    );
                    updatedFilters = updatedFilters.filter(
                        (el) => /* !xyz.includes( el ) && */ !merged.includes(el)
                    );
                }
            } else {
                updatedFilters = filterService.removeChildLeavesFromFilterSet(
                    filters,
                    filter,
                    renderfilters
                );
            }
            if (newFilters.length === 0) {
                updatedFilters = [];
            }
            this.setState({ renderfilters: updatedFilters }, () => {
                this.props.handleRenderfilters(updatedFilters);
            });
        }
        this.setState({ selectedFilters: newFilters }, () => {
            this.props.handleSelectedFilters(newFilters);
        });
    };
    updateOpenedDocumentType = (_openedDocumentType) => {
        this.setState({ openedDocumentType: _openedDocumentType })

    }
    handleClearFilters = () => {
        this.setState({
            selectedFilters: [],
            renderfilters: [],
        }, () => {
            this.props.handleSelectedFilters([]);
            this.props.handleRenderfilters([]);
        })
    }
    handleClickDocumentType = (dt) => {
        let { selectedFilters, renderfilters } = this.state;

        if (!selectedFilters.includes(GenAI2DocTypes[dt])) {
            this.setState({
                selectedFilters: selectedFilters.concat([GenAI2DocTypes[dt]]),
                openedDocumentType: dt
            }, () => {
                //this.props.handleSelectedFilters(selectedFilters.concat([GenAI2DocTypes[dt]]))
                this.props.handleSelectedFilters(this.state.selectedFilters);
            });
        } else {
            this.setState({
                selectedFilters: selectedFilters.filter(x => x !== GenAI2DocTypes[dt]),
                openedDocumentType: dt
            }, () => {
                //this.props.handleSelectedFilters(selectedFilters.filter(x => x !== GenAI2DocTypes[dt]))
                this.props.handleSelectedFilters(this.state.selectedFilters);
            })
        }
        if (!renderfilters.includes(GenAI2DocTypes[dt])) {
            this.setState({
                renderfilters: renderfilters.concat([GenAI2DocTypes[dt]]),
                openedDocumentType: dt
            }, () => {
                this.props.handleRenderfilters(this.state.renderfilters);
            });
        } else {
            this.setState({
                renderfilters: renderfilters.filter(x => x !== GenAI2DocTypes[dt]),
                openedDocumentType: dt
            }, () => {
                this.props.handleRenderfilters(this.state.renderfilters);
            })
        }
    }
    render() {
        let { selectedFilters, openedDocumentType, renderfilters } = this.state;
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));

        if (userAccessGroup.includes(MISEHCP) && !userAccessGroup.includes(AllContent)) {
            return (<></>);
        } else {

            return (
                <>
                    <div className={"gen-ai-answering-main-focus-wrapper"}>
                        {
                            Object.keys(GenAI2DocTypes).map((doctype, doctypeIndex) => {
                                return (
                                    <GenAIDocumentTypesFilterItem key={`document-filter-${doctypeIndex}`} doctype={doctype}
                                        handleClickDocumentType={this.handleClickDocumentType}
                                        customWidth={this.state.customWidth} customHeight={this.state.customHeight}
                                        selectedFilters={selectedFilters}
                                        handleChangeFilter={this.handleChangeFilter} openedDocumentType={openedDocumentType}
                                        updateOpenedDocumentType={this.updateOpenedDocumentType} isOpenInPopup={this.state.isOpenInPopup} />
                                )
                            })
                        }
                    </div>
                    {(renderfilters && renderfilters.length > 0) && <div className="gen-ai-answering-filter-display-remove-button-wrapper">
                        <button
                            onClick={this.handleClearFilters}
                            className="gen-ai-answering-clear-all-button" title="Remove All Filters">
                            Reset Selection <img src={resetFilterIcon} alt="" style={{ paddingBottom: "3%" }} />
                        </button>
                    </div>}
                </>)
        }

    }
}
export default withRouter(GenAIDocumentTypesFilters);