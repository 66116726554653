import TTSSidebar from "./TTSSidebar";
const TSSMyTask = () => {
    return (<>
        <div className="TTS-mytask-contents-wrapper" >
            <TTSSidebar />
            <div className="right-panel" >
                task component
            </div>
        </div>
    </>)
}
export default TSSMyTask;