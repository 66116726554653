import React, { Component, createRef } from "react";
import { withRouter } from 'react-router-dom';
import GenAILibraryThreadsList from './GenAILibraryThreadsList';
import GenAILibraryCollectionsList from './GenAILibraryCollectionsList';
import "./index.css"
import { Modal } from "react-bootstrap";
import { ContextMenu, MenuItem, ContextMenuTrigger, showMenu, hideMenu } from "react-contextmenu";
import ConfirmMessagePopUpModal from "./../PopUp/ConfirmMessagePopUpModal"
import { UserData } from "../../auth/Authorization";
import { imsLoader, crossIcon } from "../../assets";
import threadingService from "../../services/threadingService";
import { GenAIAnsweringContext } from './GenAIAnsweringContextProvider';
import CollectionpopUp from '../PopUp/CollectionpopUp';
import ApiServices from "../../utils/apiServices";
import GenAIAskQuestion from './GenAIAskQuestion'
import GenAIClose from "./GenAIClose";

class GenAILibraryIndex extends Component {
    static contextType = GenAIAnsweringContext;
    constructor(props) {
        super(props);
        this.myDiv = createRef();
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            itemsPerPage: "25",
            currentPage: "1",
            scrollingUp: false,
            openedContextMenuId: '',
            confirmMessageContent: '',
            confirmMessageShowModal: false,
            confirmMessageId: -1,
            isProcessing: false,
            collectionPopup: false,
            isNewThread: false,
            searchKeyword: ""
        }
    }
    componentDidMount = async () => {
        await this.context.updateLibrarySearchKeyword('');
        await this.context.updatelibraryCollectionCurrentPage('1');
        await this.context.updatelibraryThreadCurrentPage('1');
        await this.context.getLatestAnswerThread();
        window.addEventListener('scroll', this.handleScroll, true);
    }
    async componentWillUnmount() {
        await this.context.updateLibrarySearchKeyword('');
        await this.context.updatelibraryCollectionCurrentPage('1');
        await this.context.updatelibraryThreadCurrentPage('1');
        window.removeEventListener('scroll', this.handleScroll, true);
    }
    handleScroll = () => {
        let currScroll = 0;
        if (this.myDiv && this.myDiv.scrollTop) {
            currScroll = this.myDiv.scrollTop;
        }
        this.setState({ scrollingUp: currScroll > 30 })

    }
    handleDeleteAllThreadsClick = (e) => {
        this.showConfirmMessage("Are you sure you want to delete this thread?", "")
    }
    handleDeleteAllThreadshideMenu = () => {
        hideMenu({
            id: "gen-ai-answering-library-threads-deleteall"
        });
    }

    handleLeftMouseClick = (e, _id) => {
        const x = e.clientX;
        const y = e.clientY;
        this.setState({ openedContextMenuId: _id });
        showMenu({
            position: { x, y },
            id: _id
        });
    }
    showConfirmMessage = (message, id) => {
        this.setState({
            confirmMessageContent: message,
            confirmMessageShowModal: true,
            confirmMessageId: id
        })
    }

    confirmHandler = async () => {
        this.setState(
            {

                confirmMessageContent: "",
                confirmMessageShowModal: false,
                // confirmMessageId: -1
            },
            async () => {
                await this.deleteAllAnswerThreadDataByUser();
                this.setState({ confirmMessageId: -1 });
            }
        );
    }

    closeConfirmMessage = () => {
        this.setState({
            confirmMessageContent: "",
            confirmMessageShowModal: false,
            confirmMessageIndex: -1
        })
    }
    handleAddCollectionClick = () => {
        this.setState({ collectionPopup: true });
    }
    updateThreadAndCollection = async () => {
        try {
            await this.context.getAnswerThreadDataByUser();
            this.context.getAnswerCollectionsDataByUser();
        } catch (error) {
            console.log(error);
        }

    }
    handleCloseCollection = async (isUpdateAnything) => {
        this.setState({ collectionPopup: false });
        if (isUpdateAnything) {
            await this.updateThreadAndCollection();
        }
    };
    deleteAllAnswerThreadDataByUser = async () => {
        // this.setState({ isProcessing: true })
        this.context.updateLibraryThreadProcessing(true);
        const ts = new threadingService();
        if (UserData && UserData.sub) {
            await ts.deleteAllAnswerThreadDataByUser(UserData.sub)
                .then(async (response) => {
                    let service = new ApiServices();
                    await service.deleteAllThreadByUserId(UserData.sub);
                })
                .catch((error) => {
                    console.log(error);
                }).finally(() => {
                    this.setState(
                        {
                            isProcessing: false,
                        }, () => {
                            setTimeout(async () => {
                                await this.context.getLatestAnswerThread();
                                setTimeout(async () => {
                                    await this.context.getLatestAnswerThread();
                                    setTimeout(async () => {
                                        await this.context.getLatestAnswerThread();
                                    }, 1000);
                                }, 1000);
                                await this.updateThreadAndCollection();
                                this.context.updateLibraryThreadProcessing(false);
                            }, 500);

                        })

                });
            ;
        } else {
            this.setState(
                {
                    isProcessing: false,
                }, () => {
                    this.context.updateLibraryThreadProcessing(false);
                })
        }
    }
    handelCloseNewThreadModal = () => {
        this.setState({
            isNewThread: false
        })
    }
    handleCancelSearchKeyword = (e) => {
        this.setState({
            searchKeyword: ''
        }, async () => {
            await this.context.updateLibrarySearchKeyword('');
            await this.handleSearchKeywords();
        })
    }
    handleOnClickSearchIcon = async (e) => {
        let { searchKeyword } = this.state;
        await this.context.updateLibrarySearchKeyword(searchKeyword);
        await this.handleSearchKeywords();
    }
    handleChangeInSearch = (e) => {
        this.setState({
            searchKeyword: e.target.value,
        })
    }

    handleKeyDown = async (e) => {
        if (["Enter"].includes(e.key)) {
            let { searchKeyword } = this.state;
            await this.context.updateLibrarySearchKeyword(searchKeyword);
            await this.handleSearchKeywords();
        }
    }
    handleSearchKeywords = async () => {
        this.context.updatelibraryCollectionCurrentPage("1");
        this.context.updatelibraryThreadCurrentPage("1");
        this.context.getAnswerThreadDataByUser();
        this.context.getAnswerCollectionsDataByUser();
    }
    renderModelNewThread() {
        let { isNewThread } = this.state;
        return (<Modal
            show={isNewThread}
            size="lg"
            centered
            onBackdropClick={e => { this.setState({ isNewThread: false }) }}
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="savePopUp"
            className="document-processing-newthread"
            scrollable={false}
            backdropClassName="document-processing-newthread-backdrop"
            onHide={e => { this.setState({ isNewThread: false }) }}
        >
            <Modal.Body>
                <GenAIAskQuestion isOpenInModelPopup={false} customWidth={this.state.customWidth} customHeight={this.state.customHeight} handelCloseNewThreadModal={this.handelCloseNewThreadModal} key='GenAISidebar' customeKey='GenAISidebar' />
            </Modal.Body>
        </Modal>)
    }
    render() {
        let { scrollingUp, isProcessing } = this.state;

        const { confirmMessageContent, confirmMessageShowModal } = this.state;
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (
                <>
                    <div className="gen-ai-answering-library-main" ref={(el) => { this.myDiv = el; }}>
                        <header className={`${scrollingUp ? this.context.isCollapsedSpin ? "stickyHeaderCollapse" : "stickyHeaderExpand" : ""}`}>
                            <div className="gen-ai-answering-library-header">
                                <div className="gen-ai-answering-library-header-h1-main">
                                    <h1 className="gen-ai-answering-library-header-h1">
                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="rectangle-vertical-history" className="svg-inline--fa fa-rectangle-vertical-history fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                            <path fill="currentColor" d="M256 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H512c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H256zM192 64c0-35.3 28.7-64 64-64H512c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H256c-35.3 0-64-28.7-64-64V64zM96 72c0-13.3 10.7-24 24-24s24 10.7 24 24V440c0 13.3-10.7 24-24 24s-24-10.7-24-24V72zM0 120c0-13.3 10.7-24 24-24s24 10.7 24 24V392c0 13.3-10.7 24-24 24s-24-10.7-24-24V120z"></path>
                                        </svg></h1>
                                    <h1 className="gen-ai-answering-library-header-h1">&nbsp;Library</h1>
                                </div>
                                <div className="gen-ai-answering-library-header-right">
                                    <div className="gen-ai-answering-library-header-input-box-wrapper">
                                        <input name="searchBar" placeholder="Search your threads or collections..." className="gen-ai-answering-library-header-search-box" value={this.state.searchKeyword} onChange={this.handleChangeInSearch} onKeyDown={this.handleKeyDown} />
                                        <span className="gen-ai-answering-library-header-icon-wrapper">
                                            {this.state.searchKeyword &&
                                                <img
                                                    src={crossIcon}
                                                    className="gen-ai-answering-library-header-input-search-cancel-icon"
                                                    alt="clear"
                                                    title="Click here to clear the search terms"
                                                    onClick={this.handleCancelSearchKeyword}
                                                />
                                            }
                                            <span className="gen-ai-answering-library-header-search-icon-wrapper-webView" onClick={this.handleOnClickSearchIcon}>
                                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="magnifying-glass" className="gen-ai-answering-library-header-magnifyingGlass-mobileView svg-inline--fa fa-magnifying-glass " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path fill="currentColor" d="M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                    <GenAIClose />
                                </div>
                            </div>
                        </header>
                        <div className="mx-auto h-full w-full max-w-threadWidth px-md md:px-lg ">
                            <div className="gen-ai-answering-library-grid-cols-12">
                                <div className="gen-ai-answering-library-col-span-8">
                                    <div className="gen-ai-answering-library-threads-title-wrapper">
                                        <div className="gen-ai-answering-library-threads-title">
                                            <div className="k">
                                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="layer-group" className="svg-inline--fa fa-layer-group fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M288 0c-8.5 0-17 1.7-24.8 5.1L53.9 94.8C40.6 100.5 32 113.5 32 128s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2L312.8 5.1C305 1.7 296.5 0 288 0zm-5.9 49.2C284 48.4 286 48 288 48s4 .4 5.9 1.2L477.7 128 293.9 206.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 128 282.1 49.2zM53.9 222.8C40.6 228.5 32 241.5 32 256s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 235.5 477.7 256 293.9 334.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 256 146 235.5 85.1 209.4 53.9 222.8zm0 128C40.6 356.5 32 369.5 32 384s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 363.5 477.7 384 293.9 462.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 384 146 363.5 85.1 337.4 53.9 350.8z"></path></svg>
                                            </div>
                                            <div className="">&nbsp;Threads</div>
                                        </div>
                                        <div className="gen-ai-answering-library-threads-controle">
                                            <span>
                                                <ContextMenuTrigger id="gen-ai-answering-library-threads-deleteall" >
                                                    <button type="button" onClick={e => this.handleLeftMouseClick(e, "gen-ai-answering-library-threads-deleteall")} className="bg-offsetPlus dark:bg-offsetPlusDark text-textMain dark:text-textMainDark  md:hover:text-textOff md:dark:hover:text-textOffDark font-sans focus:outline-none outline-none outline-transparent transition duration-300 ease-in-out font-sans  select-none items-center relative group/button  justify-center text-center items-center rounded cursor-point active:scale-95 origin-center whitespace-nowrap inline-flex text-sm aspect-square h-8">
                                                        <div className="flex items-center leading-none justify-center gap-xs">
                                                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis" className="svg-inline--fa fa-ellipsis fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"></path></svg>
                                                        </div>
                                                    </button>
                                                </ContextMenuTrigger>

                                            </span>
                                            {/* <div className="h-full relative flex items-center">
                                                <button type="button" title="New Thread" onClick={e => { this.setState({ isNewThread: true }) }} className="bg-offsetPlus dark:bg-offsetPlusDark text-textMain dark:text-textMainDark  md:hover:text-textOff md:dark:hover:text-textOffDark font-sans focus:outline-none outline-none outline-transparent transition duration-300 ease-in-out font-sans  select-none items-center relative group/button  justify-center text-center items-center rounded cursor-point active:scale-95 origin-center whitespace-nowrap inline-flex text-sm aspect-square h-8">
                                                    <div className="flex items-center leading-none justify-center gap-xs">
                                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" className="svg-inline--fa fa-plus fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"></path></svg>
                                                    </div>
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <ContextMenu id="gen-ai-answering-library-threads-deleteall" className="gen-ai-answering-library-thread-item-contextMenu">
                                        <MenuItem onClick={this.handleDeleteAllThreadsClick}>
                                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash" class="svg-inline--fa fa-trash fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M177.1 48h93.7c2.7 0 5.2 1.3 6.7 3.6l19 28.4h-145l19-28.4c1.5-2.2 4-3.6 6.7-3.6zM354.2 80L317.5 24.9C307.1 9.4 289.6 0 270.9 0H177.1c-18.7 0-36.2 9.4-46.6 24.9L93.8 80H80.1 32 24C10.7 80 0 90.7 0 104s10.7 24 24 24H35.6L59.6 452.7c2.5 33.4 30.3 59.3 63.8 59.3H324.6c33.5 0 61.3-25.9 63.8-59.3L412.4 128H424c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8H367.9 354.2zm10.1 48L340.5 449.2c-.6 8.4-7.6 14.8-16 14.8H123.4c-8.4 0-15.3-6.5-16-14.8L83.7 128H364.3z"></path></svg>
                                            <span>Delete all Threads</span>
                                        </MenuItem>
                                    </ContextMenu>
                                    {isProcessing ?
                                        <div style={{ textAlign: "center", marginBottom: "10px" }}>
                                            <img src={imsLoader} alt="Loading Filters" style={{ width: "10%", marginTop: "200px" }} />
                                        </div> :
                                        <GenAILibraryThreadsList openedContextMenuId={this.state.openedContextMenuId} handleDeleteAllThreadshideMenu={this.handleDeleteAllThreadshideMenu} customWidth={this.state.customWidth} customHeight={this.state.customHeight} />
                                    }

                                </div>
                                <div className="gen-ai-answering-library-col-span-4">
                                    <div className="gen-ai-answering-library-collections-title-wrapper">
                                        <div className="gen-ai-answering-library-collections-title">
                                            <div className="">
                                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="grid-round-2" className="svg-inline--fa fa-grid-round-2 fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path fill="currentColor" d="M128 176a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm96-48A96 96 0 1 1 32 128a96 96 0 1 1 192 0zM128 432a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm96-48A96 96 0 1 1 32 384a96 96 0 1 1 192 0zM336 128a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm48 96a96 96 0 1 1 0-192 96 96 0 1 1 0 192zm0 208a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm96-48a96 96 0 1 1 -192 0 96 96 0 1 1 192 0z">
                                                    </path>
                                                </svg>
                                            </div>
                                            <div className="">&nbsp;Collections</div>
                                        </div>

                                        <div className="gen-ai-answering-library-collections-controle">
                                            <div className="h-full relative flex items-center">
                                                <button title="New Collection" data-testid="" className="bg-offsetPlus dark:bg-offsetPlusDark text-textMain dark:text-textMainDark  md:hover:text-textOff md:dark:hover:text-textOffDark font-sans focus:outline-none outline-none outline-transparent transition duration-300 ease-in-out font-sans  select-none items-center relative group/button  justify-center text-center items-center rounded cursor-point active:scale-95 origin-center whitespace-nowrap inline-flex text-sm aspect-square h-8" onClick={this.handleAddCollectionClick}>
                                                    <div className="flex items-center leading-none justify-center gap-xs">
                                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" className="svg-inline--fa fa-plus fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                            <path fill="currentColor" d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {isProcessing ?
                                        <div style={{ textAlign: "center", marginBottom: "10px" }}>
                                            <img src={imsLoader} alt="Loading Filters" style={{ width: "10%", marginTop: "200px" }} />
                                        </div> :
                                        <GenAILibraryCollectionsList openedContextMenuId={this.state.openedContextMenuId} handleDeleteAllThreadshideMenu={this.handleDeleteAllThreadshideMenu} customWidth={this.state.customWidth} customHeight={this.state.customHeight} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
                    {this.state.collectionPopup && (
                        <CollectionpopUp
                            showModal={this.state.collectionPopup}
                            handleClose={this.handleCloseCollection}
                            customWidth={this.state.customWidth}
                            sessionId={''}
                            threadCollections={[]}
                        />
                    )}
                    {this.renderModelNewThread()}
                </>)
        }
    }

}
export default withRouter(GenAILibraryIndex);