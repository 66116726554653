/* eslint-disable array-callback-return */
import { Component } from "react";
import HeaderSearch from "../../components/header/HeaderSearch";
import Footer from "../../components/footer/Footer";
import DocumentCard from "../DocumentResults/DocumentCard";
import queryString from "query-string";
import SearchFilter from "../FilterComponent/SearchFilter";
import axios from "axios";
import FilterService from "../../services/FilterService";
import PageItems from "../../components/Paginate/PageItems";
import Pagination from "../../components/Paginate/Pagination";
import processFilters from "../FilterComponent/ProcessFilter";
import FilterDisplay from "../../components/FilterDisplay/FilterDisplay";
import Services from "../../utils/services";
import ApiServices from "../../utils/apiServices";
import { popCancelBttn } from "../../assets";
import trackingService from "../../services/trackingService";
import {
  imsLoader,
  filterIconWhite,
  collapseFilterWhite,
  expandFilterWhite,
  clearSearchMobView,
  ActionsArrowIcon,
  expandAppliedFilterMobile,
} from "../../assets";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import NavigationMenu from "../../components/Navigation/NavigationMenu";
import "./Results.css";
import ResponsiveFilterComponent from "../ResponsiveFilterComponent/ResponsiveFilterComponent";
import GlobalSearchFeaturePopUpModal from "../../components/PopUp/GlobalSearchFeaturesPopUpModal";
import NotifyMePopUp from "../../components/PopUp/NotifyMePopUp";
import BookmarkPopUp from "../../components/PopUp/BookmarkpopUp";
import ReadingListPopup from "../../components/PopUp/ReadingListPopup";
import AlertMessagePopUpModal from "../../components/PopUp/AlertMessagePopUpModal";
import KnowledgePanelData from "../../components/ClinicalStudiesKnowledgePanel/KnowledgePanelData";
import { LocationOrigin, Answers } from "../../utils/utilityServices";
import { UtilityServices } from "../../utils/utilityServices";
import moment from "moment";
import { Link } from "react-router-dom";
import { isEmptyObject } from "jquery";
import ChatBotSection from "../../components/ChatBot/ChatBotSection";
var category;
class Results extends Component {
  userid;
  userExists;
  globalCollapseAll = false;
  documentsUrl = [];
  markedDocumentsUrl = [];
  constructor(props) {
    super(props);
    this.state = {
      response: [],
      username: "",
      userExists: false,
      collapsed: false,
      searchKeyword: "",
      dropdownValue: "",
      allFilters: {},
      documents: [],
      documentCount: 0,
      appliedFilters: [], //Filters that are applied for while search is happening
      selectedFilters: [],
      itemsPerPage: "25",
      currentPage: "1",
      presentDocRendered: "0",
      isLoaded: false,
      isProcessing: true,
      filters: {},
      f1: "",
      fval1: "",
      fieldedSearchArr: {},
      selectedParentFilterArr: [],
      showMajorTopicSearch: false,
      majorTopicSearchState: true,
      showFilters: true,
      width: window.innerWidth,
      height: window.innerHeight,
      citationSelectStatus: [],
      // selectedDocumentIndex: 0,
      isClearSearch: false,
      showResultType: false,
      resultDropdownValue: "iMS Search Results",
      selectedResults: [],
      prevUrl: "",
      globalCollapseAll: false,
      collapseFilter: false,
      globalSelectAll: false,
      displayedResults: [],
      globalEmailPopUp: false,
      globalNotifyMePopUp: false,
      csPath: "",
      csproduct: "",
      readingListPopup: false,
      bookmarkPopUp: false,
      showSearch: false,
      showContentShortcutSearch: false,
      sortByValue: "Relevance",
      sortBy: "",
      sortField: "",
      synonymsForDocumentPreview: [],
      cskpSearchData: [],
      recentlyUsedFilters: [],
      fromRecentlyAddedUpdated: 0,
      toRecentlyAddedUpdated: 6,
      collapsedSearchIDs: [],
      didYouKnow: "",
      removeFieldedSearchItem: {},
      error: "",
      errorHTML: "",
      isContentShortcutsSearch: false,
      alertMessageModelColor: "",
      alertMessageContent: "",
      alertMessageShowModal: false,
    };
  }
  async componentDidMount() {
    try {
      document.title = "iMedical Search Results";
      /* if(this.state.width<1000){
                        this.setState({
                            showFilters : false
                        })
                    } */
      window.scrollTo({ behavior: "smooth", top: "0px" });
      this.userid = JSON.parse(localStorage.getItem("username"));
      this.wwid = JSON.parse(localStorage.getItem('wwid'));
      this.userExists = localStorage.getItem("userExists");
      var itemsPerPage = localStorage.getItem("ItemsPerPage");
      var activePage = localStorage.getItem("ActivePage");
      let service = new Services();
      let apiService = new ApiServices();
      service.logService(
        `Results page successfully mounted for ${this.userid.toLowerCase()}`,
        "info"
      );
      if (this.userExists) {
        this.setState({
          userExists: this.userExists,
        });
        /*To detect if user is coming from View Selected Page with some selected data*/
        if (sessionStorage.getItem("selectedResultsDetails") === null) {
          this.setState({
            globalSelectAll: false,
          });
        } else {
          if (
            JSON.parse(sessionStorage.getItem("selectedResultsDetails"))
              .length > 0
          ) {
            this.setState({
              globalSelectAll: true,
            });
          } else {
            this.setState({
              globalSelectAll: false,
            });
          }
        }
        if (itemsPerPage !== null) {
          this.setState({
            itemsPerPage: itemsPerPage,
          });
        }
        if (activePage !== null) {
          this.setState({
            currentPage: activePage,
          });
        }
        if (this.props.location.search === "") {
          this.props.history.replace("/results?query=&q=All%20Fields&filters=");
        }
        if (this.props.location.search) {
          const queryParams = new URLSearchParams(this.props.location.search);
          const q = queryParams.get("q");
          const filters = queryParams.get("filters");
          const query = queryParams.get("query");
          let _replaceURL = `${this.props.location.pathname}${this.props.location.search}`;

          if (!query && query !== '') {
            _replaceURL += `&query=`
          }
          if (!q) {
            _replaceURL += `&q=All%20Fields`
          }
          if (!filters && filters !== '') {
            _replaceURL += `&filters=`
          }
          this.props.history.replace(_replaceURL);
        }
        /*Get Approval Status of user and assign localStorage Values*/
        let approvedState = await apiService.getUserStatus(
          this.wwid
        );
        let apiRes = await apiService.getUserApprovedRegionRole(
          this.wwid
        );
        if (approvedState.status === "Accepted") {
          localStorage.setItem("region", approvedState.regions);
          localStorage.setItem(
            "function",
            JSON.stringify(approvedState.profession)
          );
        } else {
          if (apiRes) {
            localStorage.setItem("region", apiRes.region);
            localStorage.setItem("function", JSON.stringify(apiRes.function));
          } else {
            localStorage.setItem("region", approvedState.regions);
            localStorage.setItem("function", JSON.stringify("Commercial"));
          }
        }
        /*Get the localStorage values to getAllFilters POST Call*/
        if (localStorage.getItem("contentpreference") == null) {
          let res = await apiService.getContentPreference(
            this.userid.toLowerCase()
          );
          localStorage.setItem(
            "contentpreference",
            JSON.stringify(res.contentprefernce)
          );
        }
        let reqBody = {
          function: localStorage.getItem("function").replace(/"/g, ""),
          doctypepref: localStorage.getItem("doctypepref"),
          userproducts: localStorage.getItem("userproducts"),
          region: localStorage.getItem("region"),
          username: localStorage
            .getItem("username")
            .toLowerCase()
            .replace(/"/g, ""),
          contentpreference: localStorage
            .getItem("contentpreference")
            .replace(/"/g, ""),
        };
        let response = await service.getAllFilters(reqBody);
        if (response) {
          this.setState({ allFilters: {} });
          let processedFilters = processFilters(response.data.body);
          this.setState({
            allFilters: processedFilters,
            isProcessing: false,
          });
        }
        this.setState({
          recentlyUsedFilters: JSON.parse(
            localStorage.getItem("recentlyUsedFilters")
          ),
        });
        const queryParams = new URLSearchParams(this.props.location.search);
        const params = queryParams.get("query");
        const filters = queryParams.get("filters");
        const fieldedSearch = queryParams.get("FS");
        let csPath = queryParams.get("cspath");
        let csproduct = queryParams.get("csproduct");
        let searchTerm = params;
        if (params) {
          if (params.toLowerCase() === "risperidone long acting injection") {
            searchTerm = "Risperidone";
          }
          if (params.toLowerCase() === "golimumab aria") {
            searchTerm = "Golimumab";
          }
          searchTerm = searchTerm.replace(/'/g, "''");
          let findSearchTypeRes = await apiService.findsearchtype(
            encodeURIComponent(searchTerm)
          );
          this.setState({ cskpSearchData: findSearchTypeRes });
        }
        let isDateRange = queryParams.get("isDateRange");
        let rangeField = queryParams.get("rangeField");
        let toRange = queryParams.get("toRange");
        let fromRange = queryParams.get("fromRange");
        if (fieldedSearch) {
          if (this.props.location.state) {
            let dateRange = { min: 0, max: 6 };
            let RecentlyAddedUpdatedAppliedFilters =
              this.props.location.state.appliedFilters.filter((c) =>
                c.startsWith("Recently Added_Updated")
              );
            if (RecentlyAddedUpdatedAppliedFilters.length > 0) {
              const filterParts =
                RecentlyAddedUpdatedAppliedFilters[0].split("/");
              const filterTop = filterParts[1];
              dateRange =
                UtilityServices.getSelectedYearForRecentlyAddedUpdated(
                  filterTop
                );
            }
            this.setState(
              {
                appliedFilters: this.props.location.state.appliedFilters,
                fromRecentlyAddedUpdated: dateRange.min,
                toRecentlyAddedUpdated: dateRange.max,
                /* f1:f1,
                                                                  fval1:fval1 */
              },
              () => {
                this.setState({
                  selectedParentFilterArr: this.generateSelectParentArray(
                    this.state.appliedFilters
                  ),
                });
                this.runFieldedSearch();
              }
            );
          } else if (filters) {
            let _appliedFilters = filters.split(";").map((ele) => {
              if (typeof ele === "string") {
                return ele.trim();
              }
              return ele;
            });
            let dateRange = { min: 0, max: 6 };
            let RecentlyAddedUpdatedAppliedFilters = _appliedFilters.filter(
              (c) => c.startsWith("Recently Added_Updated")
            );
            if (
              RecentlyAddedUpdatedAppliedFilters.length > 0 &&
              isDateRange &&
              rangeField &&
              (fromRange || toRange)
            ) {
              const filterParts =
                RecentlyAddedUpdatedAppliedFilters[0].split("/");
              const filterTop = filterParts[1];
              dateRange =
                UtilityServices.getSelectedYearForRecentlyAddedUpdated(
                  filterTop
                );
            } else {
              // _appliedFilters = filters.split(";");
              _appliedFilters = _appliedFilters.filter(
                (c) => !c.startsWith("Recently Added_Updated")
              );
            }
            this.setState(
              {
                //appliedFilters: filters.split(';'),
                appliedFilters: _appliedFilters,
                fromRecentlyAddedUpdated: dateRange.min,
                toRecentlyAddedUpdated: dateRange.max,
                /* f1:f1,
                                                                  fval1:fval1 */
              },
              () => {
                this.setState({
                  selectedParentFilterArr: this.generateSelectParentArray(
                    this.state.appliedFilters
                  ),
                });
                this.runFieldedSearch();
              }
            );
          } else {
            this.setState(
              {
                appliedFilters: [],
                /* f1:f1,
                                                                  fval1:fval1 */
              },
              () => {
                this.setState({
                  selectedParentFilterArr: this.generateSelectParentArray(
                    this.state.appliedFilters
                  ),
                });
                this.runFieldedSearch();
              }
            );
          }
          /*Check for selectedFilter*/
          if (this.props.location.state) {
            this.setState({
              selectedFilters: this.props.location.state.selectedFilters,
            });
          } else if (filters) {
            this.setState({
              selectedFilters: filters.split(";"),
            });
          } else {
            this.setState({
              selectedFilters: [],
            });
          }
        } else {
          if (this.props.location.state) {
            let dateRange = { min: 0, max: 6 };
            let RecentlyAddedUpdatedAppliedFilters =
              this.props.location.state.appliedFilters.filter((c) =>
                c.startsWith("Recently Added_Updated")
              );
            if (RecentlyAddedUpdatedAppliedFilters.length > 0) {
              const filterParts =
                RecentlyAddedUpdatedAppliedFilters[0].split("/");
              const filterTop = filterParts[1];
              dateRange =
                UtilityServices.getSelectedYearForRecentlyAddedUpdated(
                  filterTop
                );
            }
            this.setState(
              {
                appliedFilters: this.props.location.state.appliedFilters,
                fromRecentlyAddedUpdated: dateRange.min,
                toRecentlyAddedUpdated: dateRange.max,
                /* f1:f1,
                                                                  fval1:fval1 */
              },
              () => {
                this.setState({
                  selectedParentFilterArr: this.generateSelectParentArray(
                    this.state.appliedFilters
                  ),
                });
                if (params !== "" || params !== undefined || params !== null) {
                  this.runSearch(params, this.state.appliedFilters);
                }
              }
            );
          } else if (filters) {
            let _appliedFilters = filters.split(";").map((ele) => {
              if (typeof ele === "string") {
                return ele.trim();
              }
              return ele;
            });
            let dateRange = { min: 0, max: 6 };
            let RecentlyAddedUpdatedAppliedFilters = _appliedFilters.filter(
              (c) => c.startsWith("Recently Added_Updated")
            );
            if (
              RecentlyAddedUpdatedAppliedFilters.length > 0 &&
              isDateRange &&
              rangeField &&
              (fromRange || toRange)
            ) {
              const filterParts =
                RecentlyAddedUpdatedAppliedFilters[0].split("/");
              const filterTop = filterParts[1];
              dateRange =
                UtilityServices.getSelectedYearForRecentlyAddedUpdated(
                  filterTop
                );
            } else {
              // _appliedFilters = filters.split(";");
              _appliedFilters = _appliedFilters.filter(
                (c) => !c.startsWith("Recently Added_Updated")
              );
            }
            this.setState(
              {
                //appliedFilters: filters.split(';'),
                appliedFilters: _appliedFilters,
                fromRecentlyAddedUpdated: dateRange.min,
                toRecentlyAddedUpdated: dateRange.max,
                /* f1:f1,
                                                                  fval1:fval1 */
              },
              () => {
                this.setState({
                  selectedParentFilterArr: this.generateSelectParentArray(
                    this.state.appliedFilters
                  ),
                });
                if (params !== "" || params !== undefined || params !== null) {
                  this.runSearch(params, this.state.appliedFilters);
                }
              }
            );
          } else {
            this.setState(
              {
                appliedFilters: [],
                /* f1:f1,
                                                                  fval1:fval1 */
              },
              () => {
                this.setState({
                  selectedParentFilterArr: this.generateSelectParentArray(
                    this.state.appliedFilters
                  ),
                });
                if (params !== "" || params !== undefined || params !== null) {
                  this.runSearch(params, this.state.appliedFilters);
                }
              }
            );
          }
          /*Check for selectedFilter*/
          if (this.props.location.state) {
            this.setState({
              selectedFilters: this.props.location.state.selectedFilters,
            });
          } else if (filters) {
            this.setState({
              selectedFilters: filters.split(";"),
            });
          } else {
            this.setState({
              selectedFilters: [],
            });
          }
        }
        this.setState({
          searchKeyword: params === null ? "" : params.query,
          dropdownValue: params === null ? "All Fields" : params.q,
          csPath: csPath,
          csproduct: csproduct,
        });
        window.addEventListener("resize", this.updateDimensions);
      } else {
        console.log("Access Denied");
        this.props.history.push("/");
      }

      this.setState({ prevUrl: window.location.search });

      // VIEW SELECTED LOGIC
      let data = this.state.selectedResults;
      let citationSelectStatus = this.state.citationSelectStatus;
      let markedUrl = this.markedDocumentsUrl;
      var sessiondata = sessionStorage.getItem("selectedResultsDetails");
      // for (let index = 0; index < this.state.documentCount; index++) {
      if (localStorage.getItem("selectedResults") === null) {
        if (window.location.search !== this.state.prevUrl) {
          data = [];
          citationSelectStatus = [];
          markedUrl = [];
        }
        /* else {
                            citationSelectStatus.push(false)
                        } */
      }
      if (
        localStorage.getItem("selectedResults") !== null &&
        sessiondata !== null
      ) {
        if (this.props.location.search !== this.state.prevUrl) {
          data = [];
          citationSelectStatus = [];
          localStorage.removeItem("selectedResults");
          localStorage.removeItem("ItemsPerPage");
          localStorage.removeItem("ActivePage");
          localStorage.removeItem("markedResultsUrl");
          sessionStorage.clear();
        } else {
          localStorage
            .getItem("selectedResults")
            .split(",")
            .map((x, key) => {
              let i = parseInt(x);
              citationSelectStatus[i - 1] = true;
              if (citationSelectStatus[i - 1]) {
                data = JSON.parse(sessiondata);
                markedUrl = JSON.parse(
                  localStorage.getItem("markedResultsUrl")
                );
                // data.push(JSON.parse(sessiondata))
                // data.push({
                //     detail: this.state.response[i - 1]._source,
                //     itemTeaser: this.state.response[i - 1].highlight.body,
                //     index: i
                // })
              } else {
                data.splice(i - 1, 1);
                markedUrl.splice(i - 1, 1);
              }
            });
        }
      }
      this.markedDocumentsUrl = markedUrl;

      let params = new URLSearchParams(this.props.location.search);
      const ts = new trackingService();
      if (params.get("query") !== "" && params.get("query")) {
        const resp = await ts.getDidYouMean(params.get("query"));
        this.setState({
          citationSelectStatus: citationSelectStatus,
          selectedResults: data,
          didYouKnow: resp,
          searchKeyword: params.get("query"),
        });
      }
    } catch (error) {
      this.setState({ error: error });
    }
  }
  async componentDidUpdate(prevProp, prevState) {
    try {
      let params = new URLSearchParams(this.props.location.search);
      let prevParams = new URLSearchParams(prevProp.location.search);
      let query = params.get("query");
      let prevQuery = prevParams.get("query");
      let service = new ApiServices();
      if (this.props.location.search !== prevProp.location.search) {
        window.scrollTo({ behavior: "smooth", top: "0px" });
      }
      // if (prevState.searchKeyword !== undefined && this.state.searchKeyword !== undefined && this.state.searchKeyword !== '') {

      const ts = new trackingService();
      if (
        query !== prevState.searchKeyword &&
        prevState.searchKeyword !== undefined &&
        query !== prevQuery
      ) {
        if (query) {
          // if (prevState.searchKeyword !== this.state.searchKeyword) {
          // console.log(prevState.searchKeyword, query)
          // let findSearchTypeRes = await service.findsearchtype(this.state.searchKeyword);
          if (query.toLowerCase() === "risperidone long acting injection") {
            query = "Risperidone";
          }
          if (query.toLowerCase() === "golimumab aria") {
            query = "Golimumab";
          }
          query = query.replace(/'/g, "''");
          let findSearchTypeRes = await service.findsearchtype(
            encodeURIComponent(query)
          );
          this.setState({ cskpSearchData: findSearchTypeRes });
        }
        if (params.get("query") !== "" && params.get("query")) {
          const resp = await ts.getDidYouMean(params.get("query"));
          this.setState({
            didYouKnow: resp,
            searchKeyword: params.get("query"),
          });
        }
      }
    } catch (error) {
      this.setState({ error: error });
    }
  }

  didYouMeanClickHandle(item, url) {
    try {
      const ts = new trackingService();
      ts.indexTrackingData(
        localStorage.getItem("userdisplayName"),
        localStorage.getItem("wwid"),
        localStorage.getItem("username"),
        localStorage.getItem("function"),
        localStorage.getItem("region"),
        localStorage.getItem("userproducts"),
        localStorage.getItem("doctypepref"),
        localStorage.getItem("sid"),
        localStorage.getItem("deviceType"),
        localStorage.getItem("deviceOS"),
        localStorage.getItem("browserName"),
        localStorage.getItem("browserVersion"),
        localStorage.getItem("jnjDepartment"),
        localStorage.getItem("companyname"),
        "Conducted Search",
        "",
        // window.location.href,
        // "/results" + window.location.search,
        url,
        item,
        this.props.appliedFilters,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        this.props.csPath !== null ? this.props.csPath : "",
        this.props.csproduct !== null ? this.props.csproduct : ""
      );
      window.open(url, "_top");
    } catch (error) {
      this.setState({ error: error });
    }
  }

  createSearchCriteriaString(item = "", url = "") {
    try {
      const queryParams =
        url === ""
          ? new URLSearchParams(document.location.search)
          : new URLSearchParams(url);
      const params = item === "" ? queryParams.get("query") : item;
      const filters = queryParams.get("filters");
      const q = queryParams.get("q");
      const majorTopicsSynonyms = queryParams.get("majorTopicsSynonyms");
      const authors = queryParams.get("authors");
      const docTitle = queryParams.get("docTitle");
      const fcount = queryParams.get("fcount");
      let str = "";
      let fieldedSearch = queryParams.get("FS");
      let limistArr = [];
      let f1 = "", fval1 = "";
      if (window.location.pathname !== "/ReferencesUsed") {
        if (fcount && fcount > 0) {
          f1 = queryParams.get("f1");
          fval1 = queryParams.get("fval1");
        }
        if ((f1 && (f1 === "tempJNJDrugs" || f1 === "taIRO")) &&
          params !== "" &&
          filters !== "" &&
          q !== "All Fields" &&
          !fieldedSearch
        ) {
          if (f1 === "tempJNJDrugs") {
            str = `(Products: ${fval1}): ${q}: ${params}: ${filters}`;
          } else {
            str = `(Therapeutic Area: ${fval1}): ${q}: ${params}: ${filters}`;
          }
        }
        else if ((f1 && (f1 === "tempJNJDrugs" || f1 === "taIRO")) && params !== "" &&
          filters !== "" &&
          q === "All Fields" &&
          !fieldedSearch) {
          if (f1 === "tempJNJDrugs") {
            str = `(Products: ${fval1}): ${params}: ${filters}`;
          } else {
            str = `(Therapeutic Area: ${fval1}): ${params}: ${filters}`;
          }
        }
        else if ((f1 && (f1 === "tempJNJDrugs" || f1 === "taIRO")) &&
          params !== "" &&
          filters === "" &&
          q === "All Fields" &&
          !fieldedSearch
        ) {
          if (f1 === "tempJNJDrugs") {
            str = `(Products: ${fval1}): ${params}`;
          } else {
            str = `(Therapeutic Area: ${fval1}): ${params}`;
          }
        } else if ((f1 && (f1 === "tempJNJDrugs" || f1 === "taIRO")) &&
          params !== "" &&
          filters === "" &&
          q !== "All Fields" &&
          !fieldedSearch
        ) {
          if (f1 === "tempJNJDrugs") {
            str = `(Products: ${fval1}): ${q}: ${params}`;
          } else {
            str = `(Therapeutic Area: ${fval1}): ${q}: ${params}`;
          }
        }
        else if (
          params !== "" &&
          filters !== "" &&
          q !== "All Fields" &&
          !fieldedSearch
        ) {
          str = `${q}: ${params}: ${filters}`;
        }
        else if (
          params !== "" &&
          filters !== "" &&
          q === "All Fields" &&
          !fieldedSearch
        ) {
          str = `${params}: ${filters}`;
        } else if (
          params !== "" &&
          filters === "" &&
          q === "All Fields" &&
          !fieldedSearch
        ) {
          str = `${params}`;
        } else if (
          params !== "" &&
          filters === "" &&
          q !== "All Fields" &&
          !fieldedSearch
        ) {
          str = `${q}: ${params}`;
        } else if (fieldedSearch) {
          // Publication Year
          let toRange = queryParams.get("toYear");
          let fromRange = queryParams.get("fromYear");
          let PublicationYear = "";
          if (toRange) {
            toRange = parseInt(toRange);
          }
          if (fromRange) {
            fromRange = parseInt(fromRange);
          }
          let strYear = limistArr.find((x) => x.key === "strYear");
          if (strYear) {
            let strYearValue = parseInt(strYear.value);
            if (
              toRange &&
              fromRange &&
              fromRange <= strYearValue &&
              toRange >= strYearValue
            ) {
              PublicationYear = `${fromRange} To ${toRange}`;
            } else if (
              toRange &&
              fromRange &&
              (fromRange > strYearValue || toRange < strYearValue)
            ) {
              PublicationYear = `${fromRange} To ${toRange} AND ${strYearValue}`;
            } else if (toRange && strYearValue) {
              PublicationYear = `${toRange} and earlier AND ${strYearValue}`;
            } else if (fromRange && strYearValue) {
              PublicationYear = `${fromRange} and later AND ${strYearValue}`;
            } else if (strYearValue) {
              PublicationYear = `${strYearValue}`;
            }
          } else if (toRange && fromRange) {
            PublicationYear = `${fromRange} To ${toRange}`;
          } else if (toRange) {
            PublicationYear = `${toRange} and earlier`;
          } else if (fromRange) {
            PublicationYear = `${fromRange} and later`;
          }
          limistArr = limistArr.filter((x) => x.key !== "strYear");
          ///////
          let fcount = queryParams.get("fcount");
          for (let i = 0; i < fcount; i++) {
            let key = queryParams.get(`f${i + 1}`);
            let value = queryParams.get(`fval${i + 1}`);
            limistArr[i] = { key: key, value: value };
          }

          str = params ? `(${params}) ${filters ? ":" + filters : ""}` : "";

          if (!params && params === "" && filters !== "" && filters !== null) {
            str = str + `${filters ? filters : ""}`;
          }

          str = str + (docTitle ? ` (Title: ${docTitle})` : "");
          str = str + (authors ? ` (Authors: ${authors})` : "");
          str =
            str +
            (majorTopicsSynonyms
              ? ` (Major Topics: ${majorTopicsSynonyms})`
              : "");
          str =
            str +
            (PublicationYear ? ` (Publication Year: ${PublicationYear})` : "");
          if (fcount > 0 && limistArr.length > 0) {
            limistArr.map((item, index) => {
              str =
                str +
                (item.key
                  ? ` (${this.conversionOfFieldType(item.key)}: ${item.value})`
                  : "");
            });
          }
        }

        if (window.supportingDocsSearchStrategyDescription) {
          let searchDes = window.supportingDocsSearchStrategyDescription;
          if (str) {
            str = searchDes + `, ${str}`;
          }
        }
        return str;
      }
    } catch (err) {
      let params = new URLSearchParams(this.props.location.search);
      let query = params.get("query");
      return (
        <ErrorBoundary
          FallbackComponent={this.ErrorResults(
            this.props.history,
            category,
            query,
            [],
            [],
            this.state.globalCollapseAll,
            this.state.globalSelectAll,
            this.state.csPath,
            this.state.csproduct,
            this.documentsUrl,
            this.markedDocumentsUrl,
            this.state.itemsPerPage,
            [],
            [],
            err
          )}
        ></ErrorBoundary>
      );
    }
  }
  componentWillUnmount() {
    try {
      let service = new Services();
      window.removeEventListener("resize", this.updateDimensions);
      service.logService(
        `Results page successfully unmounted for ${this.userid.toLowerCase()}`,
        "info"
      );
    } catch (error) {
      this.setState({ error: error });
    }
  }
  updateDimensions = () => {
    try {
      this.setState(
        { width: window.innerWidth, height: window.innerHeight },
        () => {
          /* if(this.state.width < 1200){
                                  this.setState({
                                      showFilters : false
                                  })
                              }
                              else{
                                  this.setState({
                                      showFilters : true
                                  }) 
                              } */
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  runFieldedSearch = () => {
    try {
      this.setState({ isProcessing: true });
      let filterSelected = this.state.appliedFilters;
      var url =
        LocationOrigin +
        process.env.REACT_APP_IMS_API_PREFIX +
        "qp/fieldedSearch";
      //let filterStr = this.generateArrayString(filterSelected);
      var config;
      //let query = querytext;
      let isBoolean = false;
      const queryParams = new URLSearchParams(this.props.location.search);
      /*Check for filededSearch values*/
      let params = queryParams.get("query");
      const fcount = queryParams.get("fcount");
      const andFields = queryParams.get("andFields");
      const orFields = queryParams.get("orFields");
      const docTitle = queryParams.get("docTitle");
      const authors = queryParams.get("authors");
      let queryType = queryParams.get("queryType");
      const majorTopicsSynonyms = queryParams.get("majorTopicsSynonyms");
      const isDateRange = queryParams.get("isDateRange");
      filterSelected = filterSelected.filter(
        (x) => !x.startsWith("Recently Added_Updated")
      );
      let filterStr = this.generateArrayString(filterSelected);
      const sbQuery = queryParams.get("sbQuery");
      /*Check for sbQuery*/
      if (sbQuery !== null) {
        params = sbQuery;
      }
      let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
      //const f1 = queryParams.get('f1')
      //const sbQuery = queryParams.get('sbQuery');
      //const isDateRange = queryParams.get('isDateRange');
      /*Check for sbQuery*/
      /* if (sbQuery !== null) {
                    query = sbQuery
                } */
      /*Check for boolean operator in Query String Start*/
      isBoolean = queryParams.get("isBoolean");
      let booleanCounter;
      if (params) {
        booleanCounter = (params.match(/ OR | AND | NOT | NEAR /g) || [])
          .length;
      }
      if (isBoolean !== null || booleanCounter >= 4) {
        isBoolean = true;
      } else if (booleanCounter >= 1 && params.split(" ").length <= 10) {
        isBoolean = true;
      } else {
        isBoolean = false;
      }
      /*Check for boolean operator in Query String End*/
      let val,
        fromRange,
        toRange,
        checkFieldVal = false;
      if (fcount > 0) {
        let arr = {
          query: params,
          function: localStorage.getItem("function").replace(/"/g, ""),
          doctypepref: localStorage.getItem("doctypepref"),
          userproducts: localStorage.getItem("userproducts"),
          region: localStorage.getItem("region"),
          username: localStorage
            .getItem("username")
            .toLowerCase()
            .replace(/"/g, ""),
          contentpreference: localStorage
            .getItem("contentpreference")
            .replace(/"/g, ""),
          from:
            this.state.currentPage * this.state.itemsPerPage -
            this.state.itemsPerPage,
          size: parseInt(this.state.itemsPerPage),
          sortBy: this.state.sortBy,
          sortField: this.state.sortField,
          fcount: parseInt(fcount),
          boolean: isBoolean,
          filters: filterStr,
          accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
        };
        let isAnyAndCOndition = false;
        for (let i = 1; i <= fcount; i++) {
          let key = queryParams.get(`f${i}`);
          let value = queryParams.get(`fval${i}`);
          value = value.replace(/;/g, " OR ");
          if (value.includes(" AND ") || value.includes(" NOT ")) {
            isAnyAndCOndition = true;
          }
          if (
            value.includes(" AND ") ||
            value.includes(" NOT ") ||
            value.includes(" OR ")
          ) {
            value = value.replace(/ AND /g, ") AND (");
            value = value.replace(/ OR /g, ") OR (");
            value = value.replace(/ NOT /g, ") NOT (");
            value = `(${value})`;
          }
          arr["f" + i] = key;
          arr["fval" + i] = value;

          if (
            key === "congressName" ||
            key === "protocolIDs" ||
            key === "harmonizedProtocolIDs" ||
            key === "docTypeSynonyms" ||
            key === "fullCnSf" ||
            key === "docType" ||
            key === "journalName" ||
            key === "genericName"
          ) {
            checkFieldVal = true;
          }
        }
        if (checkFieldVal && !isAnyAndCOndition) {
          arr["matchPhrase"] = true;
        }
        if (queryType) {
          arr["queryType"] = "alerts";
        }
        val = arr;
        config = {
          method: "POST",
          url: url,
          headers: {
            "Content-Type": "application/json",
          },
          data: val,
        };
      } else {
        config = {
          method: "POST",
          url: url,
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            query: params,
            function: localStorage.getItem("function").replace(/"/g, ""),
            doctypepref: localStorage.getItem("doctypepref"),
            userproducts: localStorage.getItem("userproducts"),
            region: localStorage.getItem("region"),
            username: localStorage
              .getItem("username")
              .toLowerCase()
              .replace(/"/g, ""),
            contentpreference: localStorage
              .getItem("contentpreference")
              .replace(/"/g, ""),
            from:
              this.state.currentPage * this.state.itemsPerPage -
              this.state.itemsPerPage,
            size: parseInt(this.state.itemsPerPage),
            sortBy: this.state.sortBy,
            sortField: this.state.sortField,
            boolean: isBoolean,
            filters: filterStr,
            accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
          },
        };
      }
      /* if (isDateRange !== null) {
                    let rangeField = queryParams.get('rangeField'); */
      toRange = queryParams.get("toYear");
      fromRange = queryParams.get("fromYear");
      //config.data["rangeField"] = rangeField;
      //config.data["isDateRange"] = true
      if (toRange !== null) {
        config.data["toYear"] = toRange;
      }
      if (fromRange !== null) {
        config.data["fromYear"] = fromRange;
      }
      if (andFields !== null) {
        config.data["andFields"] = andFields;
      }
      if (orFields !== null) {
        config.data["orFields"] = orFields;
      }
      if (docTitle !== null) {
        config.data["docTitle"] = docTitle;
      }
      if (authors !== null) {
        config.data["authors"] = authors;
      }
      if (majorTopicsSynonyms !== null) {
        config.data["majorTopicsSynonyms"] = majorTopicsSynonyms;
      }
      if (isDateRange !== null) {
        let rangeField = queryParams.get("rangeField");
        let toRange = queryParams.get("toRange");
        let fromRange = queryParams.get("fromRange");
        if (rangeField && (toRange || fromRange)) {
          config.data["rangeField"] = rangeField;
          config.data["isDateRange"] = true;
        }
        if (toRange !== null) {
          config.data["toRange"] = toRange;
        }
        if (fromRange !== null) {
          config.data["fromRange"] = fromRange;
        }
      }
      if (queryType) {
        config.data["queryType"] = "alerts";
      }
      /* } */
      // let citationSelectStatus = [];
      axios(config)
        .then((response) => {
          var processed = processFilters(response.data.body);
          this.setState(
            {
              documentCount: response.data.body.hits.total.value,
              response: response.data.body.hits.hits,
              filters: processed,
              isProcessing: false,
              isLoaded: true,
              collapseFilter: false,
            },
            () => {
              this.handleAllFilters(
                this.state.filters,
                this.state.appliedFilters,
                this.state.selectedFilters,
                this.state.response,
                this.state.documentCount,
                params
              );
            }
          );
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.setState({ isProcessing: false });
        });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  getSynonym = async () => {
    try {
      let service = new Services();
      const queryParams = new URLSearchParams(this.props.location.search);
      const params = queryParams.get("query");
      if (params) {
        let res = await service.getSynonyms(params);
        this.setState({ synonymsForDocumentPreview: res });
      }
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleDocCount = (presentCount) => {
    this.setState({
      presentDocRendered: presentCount,
    });
  };
  handleNextClick = () => {
    try {
      if (
        this.state.currentPage <
        Math.ceil(this.state.documentCount / this.state.itemsPerPage)
      ) {
        // if (this.state.currentPage <= 1) {
        this.setState(
          {
            currentPage: parseInt(this.state.currentPage) + 1,
          },
          () => {
            this.handlePageChange(this.state.currentPage);
          }
        );
      }
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handlePreviousClick = () => {
    try {
      if (this.state.currentPage >= 2) {
        this.setState(
          {
            currentPage: this.state.currentPage - 1,
          },
          () => {
            this.handlePageChange(this.state.currentPage);
          }
        );
      }
    } catch (error) {
      this.setState({ error: error });
    }
  };
  navigateToLastPage = () => {
    try {
      this.setState(
        {
          currentPage: Math.ceil(
            this.state.documentCount / this.state.itemsPerPage
          ),
        },
        () => {
          this.handlePageChange(this.state.currentPage);
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  navigateToFirstPage = () => {
    try {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.handlePageChange(this.state.currentPage);
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleChangeSelectSortBy = (e) => {
    try {
      if (e.target.value === "Modified Date,Newest") {
        this.setState({ sortBy: "desc", sortField: "modifiedDate" });
      } else if (e.target.value === "Modified Date,Oldest") {
        this.setState({ sortBy: "asc", sortField: "modifiedDate" });
      }
      else if (e.target.value === "Approved Date,Newest") {
        this.setState({ sortBy: "desc", sortField: "approvedDate" });
      } else if (e.target.value === "Approved Date,Oldest") {
        this.setState({ sortBy: "asc", sortField: "approvedDate" });
      } else if (
        e.target.value === "Document Type, Ascending" ||
        e.target.value === "DocType, Asc"
      ) {
        this.setState({ sortBy: "asc", sortField: "docType" });
      } else if (
        e.target.value === "Document Type, Descending" ||
        e.target.value === "DocType, Desc"
      ) {
        this.setState({ sortBy: "desc", sortField: "docType" });
      } else {
        this.setState({ sortBy: "", sortField: "" });
      }
      this.setState(
        {
          sortByValue: e.target.value,
          citationSelectStatus: [],
          selectedResults: [],
          globalSelectAll: false,
          globalCollapseAll: false,
          collapsedSearchIDs: [],
        },
        () => {
          this.runSearchFromQueryParams(
            this.state.allFilters,
            this.state.appliedFilters,
            this.state.searchKeyword
          );
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  resultListCount = (itemsPerPage, currentPage) => {
    try {
      var endIndex, startIndex;
      startIndex = currentPage * itemsPerPage - itemsPerPage;
      endIndex = currentPage * itemsPerPage;
      // var count = startIndex;
      // const totalPages = Math.ceil(this.state.documentCount / itemsPerPage);
      // const previousOff = currentPage == 1;
      // const nextOff = currentPage == totalPages;
      if (this.state.width < 600) {
        return (
          <div
            className="results-count-wrapper"
            style={{
              justifyContent:
                this.state.response.length > 0 ? "space-between" : "flex-end",
            }}
          >
            {
              this.state.response.length > 0 && (
                <Pagination
                  metadata={this.state.documentCount}
                  handlePageChange={this.handlePageChange}
                  itemsPerPage={this.state.itemsPerPage}
                  page={this.state.currentPage}
                  customWidth={this.state.width}
                  customHeight={this.state.height}
                />
              )
              // <div className="resultListCount-container">
              //     {this.state.documentCount > 0 &&
              //         <span className="paginationContainer-mobile">
              //             <span className="startEndIndexText-mobile" onClick={this.navigateToFirstPage}>
              //                 {previousOff ? null : 1}
              //             </span>
              //             {
              //                 previousOff ? <img src={prdtNavButton} alt="leftArrowIcon" className="paginationArrowIcon-mobile" style={{ transform: "matrix(-1, 0, 0, -1, 0, 0)" }} /> : <img src={leftArrowMobileIcon} alt="leftArrowIcon" className="paginationArrowIcon-mobile" onClick={this.handlePreviousClick} style={{ cursor: "pointer" }} />
              //             }
              //             <span className="currentResultText-mobile">
              //                 {currentPage}
              //             </span>
              //             {
              //                 nextOff ? <img src={prdtNavButton} alt="rightArrowIcon" className="paginationArrowIcon-mobile" /> : <img src={rightArrowMobileIcon} alt="rightArrowIcon" className="paginationArrowIcon-mobile" onClick={this.handleNextClick} style={{ cursor: "pointer" }} />
              //             }
              //             <span className="startEndIndexText-mobile" onClick={this.navigateToLastPage}>
              //                 {nextOff ? null : totalPages}
              //             </span>
              //         </span>
              //     }
              // </div>
            }
            <div
              className="clearSearchContainer"
              onClick={this.handleClearSearch}
            >
              <img
                src={clearSearchMobView}
                alt="clearSearch"
                className="clearSearchIcon" /* onClick={this.handleClearSearch} */
              />
              {/* <span className="clearSearchText">Clear Search</span> */}
            </div>
          </div>
        );
      } else {
        return (
          <div
            className="results-count-wrapper"
            style={{
              display:
                this.state.width >= 1225 &&
                this.state.cskpSearchData.length > 0 &&
                "flex",
            }}
          >
            {/* {
                        this.state.width < 1000 &&
                        <div className="clearSearchContainer" onClick={this.handleClearSearch}>
                            <img src={clearSearchMobView} alt="clearSearch" className="clearSearchIcon" />
                            <span className="clearSearchText">Clear Search</span>
                        </div>
                    } */}
            {this.state.response.length > 0 && (
              <>
                <div
                  className="resultListCount-container"
                  style={{
                    width:
                      this.state.width >= 1225 &&
                      this.state.cskpSearchData.length > 0 &&
                      "75%",
                  }}
                >
                  {this.state.documentCount > 0 && (
                    <span
                      className="showingResultsCountContainer" /* style={{ display: "flex", width: "200px" }} */
                    >
                      {endIndex <= this.state.documentCount ? (
                        <div className="results-number-show-wrapper">
                          Showing {(startIndex + 1).toLocaleString("en-us")}-
                          {endIndex.toLocaleString("en-us")} of{" "}
                          {this.state.documentCount.toLocaleString("en-us")}{" "}
                          {this.state.width >
                            1000 /* || this.state.width < 775 */ && "Results"}
                        </div>
                      ) : (
                        <div className="results-number-show-wrapper">
                          Showing {(startIndex + 1).toLocaleString("en-us")}-
                          {this.state.documentCount.toLocaleString("en-us")} of{" "}
                          {this.state.documentCount.toLocaleString("en-us")}{" "}
                          {this.state.width >
                            1000 /* || this.state.width < 775 */ && "Results"}
                        </div>
                      )}
                    </span>
                  )}
                  <span
                    style={{
                      marginRight: "0.5%",
                      display: "flex",
                      width: this.state.width <= 630 ? "160px" : "180px",
                    }}
                  >
                    <PageItems
                      handleChangeSelectItems={this.handleChangeSelectItems}
                      itemsPerPage={this.state.itemsPerPage}
                      customWidth={this.state.width}
                    />
                  </span>
                  {this.state.width >= 1000 ? (
                    <div className="sort-by-wrapper">
                      <span className="sort-by-text">Sort By:</span>
                      <select
                        className="sort-by-select-wrapper"
                        onChange={this.handleChangeSelectSortBy}
                        defaultValue={this.state.sortByValue}
                      >
                        <option>Relevance</option>
                        <option>Modified Date,Newest</option>
                        <option>Modified Date,Oldest</option>
                        <option>Approved Date,Newest</option>
                        <option>Approved Date,Oldest</option>
                        <option>Document Type, Ascending</option>
                        <option>Document Type, Descending</option>
                      </select>
                    </div>
                  ) : (
                    this.state.width >= 600 && (
                      <div className="sort-by-wrapper">
                        <span className="sort-by-text">Sort By:</span>
                        <select
                          className="sort-by-select-wrapper"
                          onChange={this.handleChangeSelectSortBy}
                          defaultValue={this.state.sortByValue}
                        >
                          <option>Relevance</option>
                          <option>Modified Date,Newest</option>
                          <option>Modified Date,Oldest</option>
                          <option>Approved Date,Newest</option>
                          <option>Approved Date,Oldest</option>
                          <option>DocType, Asc</option>
                          <option>DocType, Desc</option>
                        </select>
                      </div>
                    )
                  )}
                </div>
                {this.state.cskpSearchData.length > 0 &&
                  this.state.width >= 1225 && (
                    <div className="clinicalStudies-container">
                      <KnowledgePanelData
                        searchKeyword={this.state.searchKeyword}
                        searchTypeRes={this.state.cskpSearchData}
                        appliedFilters={this.state.appliedFilters}
                      />
                    </div>
                  )}
              </>
            )}
            {this.state.width < 1000 && (
              <div
                className="clearSearchContainer"
                onClick={this.handleClearSearch}
              >
                <img
                  src={clearSearchMobView}
                  alt="clearSearch"
                  className="clearSearchIcon"
                />
                {this.state.width >= 805 && (
                  <span className="clearSearchText">Clear Search</span>
                )}
              </div>
            )}
          </div>
        );
      }
    } catch (error) {
      this.setState({ error: error });
    }
  };
  getSelectedCitationDetails = () => {
    try {
      if (this.state.selectedResults.length > 0) {
        let selectedResultsDetails = this.state.selectedResults;
        sessionStorage.setItem(
          "selectedResultsDetails",
          JSON.stringify(selectedResultsDetails)
        );
        let selectedResultsIndex = this.state.selectedResults.map(
          (x) => x.index
        );
        localStorage.setItem("selectedResults", selectedResultsIndex);
        localStorage.setItem(
          "markedResultsUrl",
          JSON.stringify(this.markedDocumentsUrl)
        );
        localStorage.setItem("ItemsPerPage", this.state.itemsPerPage);
        localStorage.setItem("ActivePage", this.state.currentPage);
        this.props.history.push({
          pathname: "/viewmarked",
          state: { selectedResults: this.state.selectedResults },
        });
      } else {
        this.errorAlertMessage("There are no documents selected");
      }
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleCitationSelection = (index, item, itemTeaser, key) => {
    try {
      let a = this.state.citationSelectStatus;
      a[index - 1] = !this.state.citationSelectStatus[index - 1];
      let data = this.state.selectedResults;
      let urls = this.markedDocumentsUrl;
      if (this.state.citationSelectStatus[index - 1]) {
        data.push({
          detail: item,
          itemTeaser: itemTeaser,
          index: index,
        });
        urls.push(this.documentsUrl[key]);
      } else {
        const i = data.map((x) => x.index).indexOf(index);
        if (i > -1) {
          data.splice(i, 1); // 2nd parameter means remove one item only
          urls.splice(i, 1);
        }
      }
      this.markedDocumentsUrl = urls;
      this.setState({
        citationSelectStatus: a,
        selectedResults: data,
      });
      let checkSelectStatus = a.filter((item) => item);
      if (checkSelectStatus.length === parseInt(this.state.itemsPerPage)) {
        this.setState({ globalSelectAll: true });
      } else if (checkSelectStatus.length === this.state.response.length) {
        this.setState({ globalSelectAll: true });
      } else {
        this.setState({ globalSelectAll: false });
      }
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleCitationSelectionGlobal = (index, item, itemTeaser, key) => {
    try {
      let a = this.state.citationSelectStatus;
      //console.log(a, index)
      a[index - 1] = true;
      let data = this.state.selectedResults;
      let urls = this.markedDocumentsUrl;
      if (this.state.citationSelectStatus[index - 1]) {
        //console.log("selectAll-1")
        data.push({
          detail: item,
          itemTeaser: itemTeaser,
          index: index,
        });
        urls.push(this.documentsUrl[key - 1]);
      } else {
        //console.log("selectAll-2")
        const i = data.map((x) => x.index).indexOf(index);
        if (i > -1) {
          data.splice(i, 1); // 2nd parameter means remove one item only
          urls.splice(i, 1);
        }
      }
      // console.log(a,data);
      this.markedDocumentsUrl = urls;
      this.setState({
        citationSelectStatus: a,
        selectedResults: data,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleCitationUnSelection = (index, item, itemTeaser) => {
    try {
      let a = this.state.citationSelectStatus;
      a[index - 1] = false;
      let data = this.state.selectedResults;
      let urls = this.markedDocumentsUrl;
      /* if (this.state.citationSelectStatus[index - 1]) {
                        data.push({
                            detail: item,
                            itemTeaser: itemTeaser,
                            index: index,
                        })
                    }
                    else { */
      const i = data.map((x) => x.index).indexOf(index);
      if (i > -1) {
        data.splice(i, 1); // 2nd parameter means remove one item only
        urls.splice(i, 1);
      }
      /* } */
      // console.log(a,data)
      // let selectedResultsDetails = data;
      // sessionStorage.setItem("selectedResultsDetails", JSON.stringify(selectedResultsDetails));
      // let selectedResultsIndex = data.map(x => x.index)
      // localStorage.setItem('selectedResults', selectedResultsIndex)
      this.markedDocumentsUrl = urls;
      this.setState({
        citationSelectStatus: a,
        selectedResults: data,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  citationUrl = (url, index, item, displayedResultsUrl) => {
    try {
      if (item.domain === "CTGov") {
        displayedResultsUrl[index] = url;
      } else {
        displayedResultsUrl[index] = window.location.origin + url;
        // displayedResultsUrl[index] = "https://predev-ims.jnj.com" + url;
      }
      this.documentsUrl = displayedResultsUrl;
    } catch (error) {
      this.setState({ error: error });
    }
  };
  manageCollapseAndExpand = (iscollapsed, searchID) => {
    try {
      let { collapsedSearchIDs, response } = this.state;
      let responseSearchIDs = response.map((x) => x._source.searchID);
      if (iscollapsed) {
        collapsedSearchIDs.push(searchID);
      } else {
        collapsedSearchIDs = collapsedSearchIDs.filter((x) => x !== searchID);
      }
      let tempGlobalCollapseAll = responseSearchIDs.every((x) =>
        collapsedSearchIDs.includes(x)
      );
      this.setState({
        collapsedSearchIDs: collapsedSearchIDs,
        globalCollapseAll: tempGlobalCollapseAll,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };

  resultList = (results, itemsPerPage, currentPage) => {
    try {
      let { collapsedSearchIDs } = this.state;
      // window.scrollTo({ behavior: 'smooth', top: '0px' });
      var startIndex;
      startIndex = currentPage * itemsPerPage - itemsPerPage;
      var count = startIndex;
      let displayedResults = [];
      let displayedResultsUrl = [];
      let resp = this.state.didYouKnow;
      let params = new URLSearchParams(window.location.search);
      // For OR, AND, NEAR and NOT replacement
      if (this.state.searchKeyword) {
        if (this.state.searchKeyword.includes("OR")) {
          resp = resp !== "" && resp ? resp.replace(/(or)/g, "OR") : "";
        } else if (this.state.searchKeyword.includes("AND")) {
          resp = resp !== "" && resp ? resp.replace(/(and)/g, "AND") : "";
        } else if (this.state.searchKeyword.includes("NEAR")) {
          resp = resp !== "" && resp ? resp.replace(/(near)/g, "NEAR") : "";
        } else if (this.state.searchKeyword.includes("NOT")) {
          resp = resp !== "" && resp ? resp.replace(/(not)/g, "NOT") : "";
        }
      }
      /////////
      resp = resp !== "" && resp ? resp.replace(/(<b>)/g, "") : "";
      resp = resp !== "" && resp ? resp.replace(/(<\/b>)/g, "") : "";

      // let regEx = new RegExp(encodeURIComponent(params.get("query")), "g");
      // let url = window.location.href.replace(regEx, encodeURIComponent(resp));
      let url = window.location.href
        .replaceAll(
          encodeURIComponent(params.get("query")),
          encodeURIComponent(resp)
        )
        .replace(window.location.origin + "/", "");
      if (results.length > 0) {
        return (
          <>
            {/* {resp !== "No did you mean suggestions returned." && resp !== "" ? (
            <div className="srch-result-didYouMean">
              Did you mean&nbsp;
              <a id="DidYouMean" href={url} className="bold italic">
                {resp}&nbsp;
              </a>
              ?&nbsp;
            </div>
          ) : (
            ""
          )} */}
            {results.map((item, index) => {
              count++;
              // console.log(item.highlight)
              if (
                item.highlight === undefined ||
                item.highlight?.body[0] === "Empty File" ||
                item.highlight?.body[0] === "Not an empty file"
              ) {
                displayedResults.push({
                  detail: item._source,
                  itemTeaser: [],
                  index: index + 1,
                });
                // item.highlight = { 'body': ['Test body until indexed value is available'] }
              } else {
                displayedResults.push({
                  detail: item._source,
                  itemTeaser: item.highlight.body,
                  index: index + 1,
                });
              }
              let responseSearchIDs = item._source.searchID;
              let globalCollapseAll = collapsedSearchIDs.some(
                (x) => x === responseSearchIDs
              );
              return (
                <>
                  <div key={`result-id-${index}`} id={`result-id-${index}`}>
                    {
                      <div className="resultDetailsDiv">
                        <DocumentCard
                          item={item._source}
                          count={count}
                          appliedFilters={this.state.appliedFilters}
                          searchKeyword={this.state.searchKeyword}
                          pageNumber={this.state.currentPage}
                          pageSize={this.state.itemsPerPage}
                          itemTeaser={
                            item.highlight === undefined ||
                              item.highlight?.body[0] === "Empty File" ||
                              item.highlight?.body[0] === "Not an empty file"
                              ? []
                              : item.highlight.body
                          }
                          handleCitationSelection={(i, item, itemTeaser) =>
                            this.handleCitationSelection(
                              i,
                              item,
                              itemTeaser,
                              index
                            )
                          }
                          isChecked={this.state.citationSelectStatus[count - 1]}
                          customWidth={this.state.width}
                          //globalCollapseAll={this.state.globalCollapseAll}
                          globalCollapseAll={globalCollapseAll}
                          csPath={this.state.csPath}
                          csproduct={this.state.csproduct}
                          citationUrl={(url) =>
                            this.citationUrl(
                              url,
                              index,
                              item._source,
                              displayedResultsUrl
                            )
                          }
                          synonymsForDocumentPreview={
                            this.state.synonymsForDocumentPreview
                          }
                          manageCollapseAndExpand={this.manageCollapseAndExpand}
                        />
                        <div className="resultsDivFooter"></div>
                      </div>
                    }
                  </div>
                </>
              );
            })}
            {localStorage.setItem(
              "displayedResults",
              JSON.stringify(displayedResults)
            )}
          </>
        );
      } else {
        localStorage.removeItem("displayedResults");
        let item = this.createSearchCriteriaString(resp, url);
        return (
          <>
            {resp !== "No did you mean suggestions returned." && resp !== "" ? (
              <div className="srch-result-didYouMean">
                Did you mean&nbsp;
                <Link
                  onClick={() => {
                    return this.didYouMeanClickHandle(item, url);
                  }}
                  id="DidYouMean"
                  className="bold italic"
                  to={url}
                >
                  {resp}&nbsp;
                </Link>
                ?&nbsp;
              </div>
            ) : (
              ""
            )}
            <div className="results-no-search-content">
              <span>Nothing here matches your search</span>
              <br />
              <br />
              <span>Suggestions</span>
              <li>Make sure all words are spelled correctly</li>
              <li>Try different search terms</li>
              <li>Try more general search terms</li>
              <li>Try fewer search terms</li>
            </div>
          </>
        );
      }
      //this.setState({displayedResults:displayedResults})
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleAllFilters = (
    filters,
    appliedfilters,
    selectedFilters,
    response,
    docCount,
    query
  ) => {
    this.setState({
      filters: filters,
      appliedFilters: appliedfilters,
      selectedFilters: selectedFilters,
      response: response,
      documentCount: docCount,
      searchKeyword: query,
    });
  };
  handleChangeInSearch = (query) => {
    this.setState(
      {
        searchKeyword: query,
        currentPage: "1",
        globalSelectAll: false,
        globalCollapseAll: false,
        collapsedSearchIDs: [],
      },
      () => {
        if (this.state.error !== "") {
          this.setState({ error: "" });
        }
        this.runSearchFromQueryParams(
          this.state.allFilters,
          this.state.appliedFilters,
          query
        );
      }
    );
  };
  handlePageChange = (currentPage) => {
    try {
      window.scrollTo({ behavior: "smooth", top: "0px" });
      let checkSelectStatus = this.state.citationSelectStatus.filter(
        (item) => item
      );
      if (
        this.state.globalSelectAll &&
        (checkSelectStatus.length.toString() === this.state.itemsPerPage ||
          this.state.citationSelectStatus.length === this.state.documentCount)
      ) {
        this.setState({ citationSelectStatus: [], selectedResults: [] });
      }
      this.setState(
        {
          currentPage: currentPage,
          response: [],
          isProcessing: true,
          isLoaded: false,
          globalCollapseAll: false,
          globalSelectAll: false,
          collapsedSearchIDs: [],
        },
        () => {
          let setSelectStatus = false;
          let startIndex =
            this.state.currentPage * this.state.itemsPerPage -
            this.state.itemsPerPage;
          let endIndex = this.state.currentPage * this.state.itemsPerPage;
          let citationStatusForCurrentPage =
            this.state.citationSelectStatus.slice(startIndex, endIndex);
          //console.log(startIndex,endIndex,citationStatusForCurrentPage)
          setSelectStatus = citationStatusForCurrentPage.some(
            (e) => e === true
          );
          //console.log(setSelectStatus)
          if (setSelectStatus) {
            this.setState({
              globalSelectAll: true,
            });
          } else {
            this.setState({
              globalSelectAll: false,
            });
          }
          this.runSearchFromQueryParams(
            this.state.allFilters,
            this.state.appliedFilters,
            this.state.searchKeyword
          );
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  recentlyAddedUpdatedbuildUrl = (
    searchKeyword,
    dropdownValue,
    fieldedVal,
    filters,
    isClearSearch,
    dateRange
  ) => {
    try {
      let filterStr = this.generateArrayString(filters);
      filterStr = encodeURIComponent(filterStr);
      let params = new URLSearchParams(this.props.location.search);
      let sbQuery = params.get("sbQuery");
      let fieldedSearch = params.get("FS");
      let csPath = params.get("cspath");
      let csproduct = params.get("csproduct");
      let queryType = params.get("queryType");
      let fcount = params.get("fcount");
      let isClickOnSuggestion = params.get("isClickOnSuggestion");
      let url;
      if (fieldedSearch) {
        url = this.buildFieldedUrl(searchKeyword, filters);
      } else {
        if (fieldedVal === "") {
          url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`;
        } else if (
          (dropdownValue === "All Fields" && fieldedVal === "") ||
          fieldedVal === undefined
        ) {
          url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`;
        } else {
          //url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}&f1=${fieldedVal}&fval1=${searchKeyword}&fcount=1`;
          url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`;
          if (fcount) {
            for (let i = 1; i <= fcount; i++) {
              let key = params.get(`f${i}`);
              let value = params.get(`fval${i}`);
              url += `&f${i}=${key}&fval${i}=${value}`;
            }
            url += `&fcount=${fcount}`;
          }
        }
        if (sbQuery !== null && searchKeyword !== "") {
          url = url + `&sbQuery=${sbQuery}`;
        }
        if (csPath !== null && csproduct !== null && searchKeyword !== "") {
          url = url + `&cspath=${csPath}&csproduct=${csproduct}`;
        }
      }
      if (
        (dateRange && dateRange.fromDate) ||
        (dateRange && dateRange.toDate)
      ) {
        url = url + `&isDateRange=Yes&rangeField=modifiedDate`;
        if (dateRange.fromDate) {
          url = url + `&fromRange=${dateRange.fromDate}`;
        }
        if (dateRange.toDate) {
          url = url + `&toRange=${dateRange.toDate}`;
        }
      }
      if (queryType) {
        url += `&queryType=alerts`;
      }
      if (isClickOnSuggestion) {
        url += `&isClickOnSuggestion=true`;
      }
      if (isClearSearch === true) {
        url = `?query=&q=All Fields&filters=`;
      }
      return url;
    } catch (error) {
      this.setState({ error: error });
    }
  };
  gotoPageRecentlyAddedUpdated = (yearSelection) => {
    try {
      this.setState(
        {
          fromRecentlyAddedUpdated: yearSelection.min,
          toRecentlyAddedUpdated: yearSelection.max,
        },
        () => {
          let dateRange = this.getToDateAndFromDate();
          let params = queryString.parse(this.props.location.search);
          let appliedFilters = this.state.appliedFilters.filter(
            (x) => !x.startsWith("Recently Added_Updated")
          );
          let displayYear =
            UtilityServices.getSelectedYearDisplay(yearSelection);

          if (displayYear && displayYear !== "All") {
            appliedFilters.push(`Recently Added_Updated/${displayYear}`);
          }
          var filters = appliedFilters;
          let selectedFacets = this.generateSelectParentArray(appliedFilters);
          const newUrl = this.recentlyAddedUpdatedbuildUrl(
            params.query,
            params.q,
            params.f1,
            filters,
            false,
            dateRange
          );

          this.props.history.push({
            pathname: "/results",
            search: newUrl,
            state: {
              selectedFilters: selectedFacets,
              allFilters: this.state.allFilters,
              appliedFilters: appliedFilters,
            },
          });
          this.setState(
            {
              currentPage: "1",
              selectedParentFilterArr: selectedFacets,
              collapseFilter: true,
              globalSelectAll: false,
              selectedResults: [],
              citationSelectStatus: [],
            },
            () => {
              this.runSearchFromQueryParams(
                this.state.allFilters,
                appliedFilters,
                this.state.searchKeyword
              );
            }
          );
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  getToDateAndFromDate = () => {
    try {
      let { fromRecentlyAddedUpdated, toRecentlyAddedUpdated } = this.state;
      let dateRange = { toDate: null, fromDate: null };

      if (fromRecentlyAddedUpdated === 1) {
        // dateRange.fromDate = moment()
        //   .subtract(12, "month")
        //   .startOf("date")
        //   .format("YYYY-MM-DD");
        dateRange.fromDate = moment()
          .subtract(365, "days")
          .startOf("date")
          .format("YYYY-MM-DD");
      } else if (fromRecentlyAddedUpdated === 2) {
        // dateRange.fromDate = moment()
        //   .subtract(6, "month")
        //   .startOf("date")
        //   .format("YYYY-MM-DD");
        dateRange.fromDate = moment()
          .subtract(180, "days")
          .startOf("date")
          .format("YYYY-MM-DD");
      } else if (fromRecentlyAddedUpdated === 3) {
        // dateRange.fromDate = moment()
        //   .subtract(1, "month")
        //   .startOf("date")
        //   .format("YYYY-MM-DD");
        dateRange.fromDate = moment()
          .subtract(30, "days")
          .startOf("date")
          .format("YYYY-MM-DD");
      } else if (fromRecentlyAddedUpdated === 4) {
        dateRange.fromDate = moment()
          .subtract(7, "days")
          .startOf("date")
          .format("YYYY-MM-DD");
      } else if (fromRecentlyAddedUpdated === 5) {
        dateRange.fromDate = moment()
          .subtract(1, "days")
          .startOf("date")
          .format("YYYY-MM-DD");
      }

      if (toRecentlyAddedUpdated === 1) {
        // dateRange.toDate = moment()
        //   .subtract(12, "month")
        //   .endOf("date")
        //   .format("YYYY-MM-DD");
        dateRange.toDate = moment()
          .subtract(365, "days")
          .endOf("date")
          .format("YYYY-MM-DD");
      } else if (toRecentlyAddedUpdated === 2) {
        // dateRange.toDate = moment()
        //   .subtract(6, "month")
        //   .endOf("date")
        //   .format("YYYY-MM-DD");
        dateRange.toDate = moment()
          .subtract(180, "days")
          .endOf("date")
          .format("YYYY-MM-DD");
      } else if (toRecentlyAddedUpdated === 3) {
        dateRange.toDate = moment()
          .subtract(30, "days")
          .endOf("date")
          .format("YYYY-MM-DD");
      } else if (toRecentlyAddedUpdated === 4) {
        // dateRange.toDate = moment()
        //   .subtract(7, "days")
        //   .endOf("date")
        //   .format("YYYY-MM-DD");
        dateRange.toDate = moment()
          .subtract(7, "days")
          .endOf("date")
          .format("YYYY-MM-DD");
      } else if (toRecentlyAddedUpdated === 5) {
        dateRange.toDate = moment()
          .subtract(1, "days")
          .endOf("date")
          .format("YYYY-MM-DD");
      }
      return dateRange;
    } catch (error) {
      this.setState({ error: error });
    }
  };
  generateSelectParentArray = (appliedfilters) => {
    try {
      let parentFacet = [];
      appliedfilters.forEach((filter) => {
        const filterParts = filter.split("/");
        const filterTop = filterParts[0];
        parentFacet.push(filterTop);
      });
      return parentFacet;
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleOnChangeInfilter = (
    filters,
    appliedfilters,
    selectedFilters,
    response,
    doc,
    query
  ) => {
    try {
      let params = queryString.parse(this.props.location.search);
      //var filters = appliedfilters;
      filters = appliedfilters;
      let selectedFacets = this.generateSelectParentArray(appliedfilters);
      const newUrl = this.buildUrl(
        params.query,
        params.q,
        params.f1,
        filters,
        false
      );
      this.props.history.push({
        pathname: "/results",
        search: newUrl,
        state: {
          selectedFilters: selectedFilters,
          allFilters: this.state.allFilters,
          appliedFilters: appliedfilters,
        },
      });
      this.setState(
        {
          currentPage: "1",
          selectedParentFilterArr: selectedFacets,
          collapseFilter: true,
          selectedFilters: selectedFilters,
          globalSelectAll: false,
        },
        () => {
          this.runSearchFromQueryParams(
            this.state.allFilters,
            appliedfilters,
            this.state.searchKeyword
          );
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleChangeSelectItems = (items) => {
    try {
      // console.log(this.state.citationSelectStatus);
      if (
        this.state.globalSelectAll &&
        (this.state.citationSelectStatus.length.toString() ===
          this.state.itemsPerPage ||
          this.state.citationSelectStatus.length === this.state.documentCount)
      ) {
        this.setState({ citationSelectStatus: [], selectedResults: [] });
      }
      this.setState(
        {
          itemsPerPage: items,
          globalCollapseAll: false,
          globalSelectAll: false,
          collapsedSearchIDs: [],
          selectedResults: [],
          citationSelectStatus: [],
        },
        () => {
          let totalPages = Math.ceil(
            this.state.documentCount / this.state.itemsPerPage
          );
          if (totalPages < this.state.currentPage) {
            this.setState(
              {
                currentPage: totalPages,
              },
              () => {
                let setSelectStatus = false;
                let startIndex =
                  this.state.currentPage * this.state.itemsPerPage -
                  this.state.itemsPerPage;
                let endIndex = this.state.currentPage * this.state.itemsPerPage;
                let citationStatusForCurrentPage =
                  this.state.citationSelectStatus.slice(startIndex, endIndex);
                //console.log(startIndex,endIndex,citationStatusForCurrentPage)
                setSelectStatus = citationStatusForCurrentPage.some(
                  (e) => e === true
                );
                //console.log(setSelectStatus)
                if (setSelectStatus) {
                  this.setState({
                    globalSelectAll: true,
                  });
                } else {
                  this.setState({
                    globalSelectAll: false,
                  });
                }
                this.runSearchFromQueryParams(
                  this.state.allFilters,
                  this.state.appliedFilters,
                  this.state.searchKeyword
                );
              }
            );
          } else {
            this.runSearchFromQueryParams(
              this.state.allFilters,
              this.state.appliedFilters,
              this.state.searchKeyword
            );
          }
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };

  buildFieldedUrl = (searchKeyword, filters) => {
    try {
      let { removeFieldedSearchItem } = this.state;
      let filterStr = this.generateArrayString(filters);
      filterStr = encodeURIComponent(filterStr);
      let queryParams = new URLSearchParams(this.props.location.search);
      let params = queryParams.get("query");
      let q = queryParams.get("q");
      let fcount = queryParams.get("fcount");
      let andFields = queryParams.get("andFields");
      let orFields = queryParams.get("orFields");
      let docTitle = queryParams.get("docTitle");
      let authors = queryParams.get("authors");
      let majorTopicsSynonyms = queryParams.get("majorTopicsSynonyms");
      let fromYear = queryParams.get("fromYear");
      let toYear = queryParams.get("toYear");
      let DS = queryParams.get("DS");
      let sbQuery = queryParams.get("sbQuery");
      let isFieldedSearch = false;
      params = encodeURIComponent(params);
      let url = `?query=${params}&q=${q}&filters=${filterStr}`;
      if (DS && !isEmptyObject(removeFieldedSearchItem) && DS === `${removeFieldedSearchItem.key}ǂ${removeFieldedSearchItem.value}`) {
        url = `?query=&q=All Fields&filters=${filterStr}`;
      }
      if (fcount > 0) {
        let newFCount = 0;
        for (let i = 1; i <= fcount; i++) {
          if (!(!isEmptyObject(removeFieldedSearchItem) && removeFieldedSearchItem.fCountValue && removeFieldedSearchItem.fCountValue === i)) {
            let key = queryParams.get(`f${i}`);
            let value = queryParams.get(`fval${i}`);
            newFCount++;
            url += `&f${newFCount}=${key}&fval${newFCount}=${encodeURIComponent(value)}`;
            if (DS) {
              let DSArr0 = DS.split("ǂ")[0]; //DSArr0 store Dropdown select value
              let DSArr1 = DS.split("ǂ")[1]; //DSArr1 store Search value
              if (key !== DSArr0 && value !== DSArr1) {
                isFieldedSearch = true;
              }
            } else {
              isFieldedSearch = true;
            }
          }
        }
        if (newFCount > 0) {
          url += `&fcount=${newFCount}`;
        }
        if (DS) {
          let DSArr0 = DS.split("ǂ")[0]; //DSArr0 store Dropdown select value
          let DSArr1 = DS.split("ǂ")[1]; //DSArr1 store Search value
          DSArr1 = encodeURIComponent(DSArr1);
          DS = DSArr0.concat("ǂ", DSArr1);
          if (!(!isEmptyObject(removeFieldedSearchItem) && DS === `${removeFieldedSearchItem.key}ǂ${removeFieldedSearchItem.value}`)) {
            url += `&DS=${DS}`;
          }
        }
      }
      if (!isEmptyObject(removeFieldedSearchItem)) {
        if (docTitle !== "" && docTitle !== null && removeFieldedSearchItem.key === 'docTitle') {
          docTitle = "";
        }
        if (authors !== "" && authors !== null && removeFieldedSearchItem.key === 'authors') {
          authors = "";
        }
        if (majorTopicsSynonyms !== "" && majorTopicsSynonyms !== null && removeFieldedSearchItem.key === 'majorTopicsSynonyms') {
          majorTopicsSynonyms = "";
        }
        if (toYear !== "" && toYear !== null && removeFieldedSearchItem.key === 'toYear') {
          toYear = "";
          fromYear = "";
        }
        else if (fromYear !== "" && fromYear !== null && removeFieldedSearchItem.key === 'toYear') {
          toYear = "";
          fromYear = "";
        }
        if (andFields !== "" && andFields !== null) {
          let splitAndFields = andFields.split(";");
          splitAndFields = splitAndFields.filter(x => x !== removeFieldedSearchItem.key);
          andFields = splitAndFields.join(";")
        }
        if (orFields !== "" && orFields !== null) {
          let splitOrFields = orFields.split(";");
          splitOrFields = splitOrFields.filter(x => x !== removeFieldedSearchItem.key);
          orFields = splitOrFields.join(";")
        }
      }
      if (andFields !== "" && andFields !== null) {
        url += `&andFields=${andFields}`;
      }
      if (orFields !== "" && orFields !== null) {
        url += `&orFields=${orFields}`;
      }
      if (docTitle !== "" && docTitle !== null) {
        url += `&docTitle=${encodeURIComponent(docTitle)}`;
        isFieldedSearch = true;
      }
      if (authors !== "" && authors !== null) {
        url += `&authors=${encodeURIComponent(authors)}`;
        isFieldedSearch = true;
      }
      if (majorTopicsSynonyms !== "" && majorTopicsSynonyms !== null) {
        url += `&majorTopicsSynonyms=${encodeURIComponent(majorTopicsSynonyms)}`;
        isFieldedSearch = true;
      }
      if (fromYear !== "" && fromYear !== null) {
        url += `&fromYear=${fromYear}`;
        isFieldedSearch = true;
      }
      if (toYear !== "" && toYear !== null) {
        url += `&toYear=${toYear}`;
        isFieldedSearch = true;
      }
      if (isFieldedSearch) {
        url += `&FS=true`;
      }
      if (isFieldedSearch || (q && q !== "All Fields")) {
        sbQuery = sbQuery ? sbQuery : "";
        url = url + `&sbQuery=${sbQuery}`;
      }
      return url;
    } catch (error) {
      this.setState({ error: error });
    }
  };
  buildUrl = (
    searchKeyword,
    dropdownValue,
    fieldedVal,
    filters,
    isClearSearch,
    isRemoveRecentlyAddedUpdatedAppliedFilter = false
  ) => {
    try {
      searchKeyword = encodeURIComponent(searchKeyword);
      let filterStr = this.generateArrayString(filters);
      filterStr = encodeURIComponent(filterStr);
      let params = new URLSearchParams(this.props.location.search);
      let sbQuery = params.get("sbQuery");
      let isDateRange = params.get("isDateRange");
      let fieldedSearch = params.get("FS");
      let csPath = params.get("cspath");
      let csproduct = params.get("csproduct");
      let fcount = params.get("fcount");
      let queryType = params.get("queryType");
      let isClickOnSuggestion = params.get("isClickOnSuggestion");
      let url;
      if (fieldedSearch) {
        url = this.buildFieldedUrl(searchKeyword, filters);
      } else {
        const { isContentShortcutsSearch } = this.state;
        if (isContentShortcutsSearch) {
          fcount = "";
          csPath = null;
          csproduct = null;
          sbQuery = null;
        }
        if (fieldedVal === "") {
          url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`;
        } else if (
          (dropdownValue === "All Fields" && fieldedVal === "") ||
          fieldedVal === undefined
        ) {
          url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`;
        } else {
          //url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}&f1=${fieldedVal}&fval1=${searchKeyword}&fcount=1`;
          url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`;
          if (fcount) {
            for (let i = 1; i <= fcount; i++) {
              let key = params.get(`f${i}`);
              let value = params.get(`fval${i}`);
              url += `&f${i}=${key}&fval${i}=${encodeURIComponent(value)}`;
            }
            url += `&fcount=${fcount}`;
          }
        }
        if (sbQuery !== null && searchKeyword !== "") {
          url = url + `&sbQuery=${sbQuery}`;
        }
        if (csPath !== null && csproduct !== null && searchKeyword !== "") {
          url = url + `&cspath=${csPath}&csproduct=${csproduct}`;
        }
        if (
          isDateRange !== null &&
          !isRemoveRecentlyAddedUpdatedAppliedFilter
        ) {
          let rangeField = params.get("rangeField");
          let toRange = params.get("toRange");
          let fromRange = params.get("fromRange");
          if (isDateRange && rangeField && (toRange || fromRange)) {
            url = url + `&isDateRange=true&rangeField=${rangeField}`;
          }
          if (toRange !== null) {
            url += `&toRange=${toRange}`;
          }
          if (fromRange !== null) {
            url += `&fromRange=${fromRange}`;
          }
        }
      }
      if (queryType) {
        url += `&queryType=alerts`;
      }
      if (isClickOnSuggestion) {
        url += `&isClickOnSuggestion=true`;
      }
      if (isClearSearch === true) {
        url = `?query=&q=All Fields&filters=`;
      }
      return url;
    } catch (error) {
      this.setState({ error: error });
    }
  };
  runSearchFromQueryParams(allfilters, appliedfilters, querytext) {
    try {
      //console.log(querytext);
      this.setState({ isProcessing: true, isLoaded: false });
      const filterService = new FilterService();
      const query = querytext;
      const inFilters = appliedfilters;
      // const [filters] = filterService.expandFilterSet(allfilters, inFilters)
      const [filters] = filterService.expandFilterSet(
        this.state.filters,
        inFilters
      );
      const filterSelected = inFilters;
      let selectedFiltered = this.state.selectedFilters.concat(filters);
      selectedFiltered = [...new Set(selectedFiltered)];
      this.setState(
        {
          appliedFilters: inFilters,
          selectedFilters: selectedFiltered,
          // selectedFilters: filters
        },
        () => {
          const queryParams = new URLSearchParams(this.props.location.search);
          const fieldedSearch = queryParams.get("FS");
          if (fieldedSearch) {
            this.runFieldedSearch();
          } else {
            this.runSearch(query, filterSelected);
          }
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  }
  generateArrayString = (array) => {
    try {
      let arrStr = "";
      array.map((item, index) => {
        if (index === array.length - 1) {
          arrStr += item;
        } else {
          arrStr += item + ";";
        }
        return null;
      });
      return arrStr;
    } catch (error) {
      this.setState({ error: error });
    }
  };
  runSearch(querytext, filterSelected) {
    try {
      this.setState({
        isProcessing: true,
        globalCollapseAll: false /* , globalSelectAll: false */,
        collapsedSearchIDs: [],
      });
      //var url = LocationOrigin + process.env.REACT_APP_IMS_QP_API + 'runSearch'
      var url =
        LocationOrigin + process.env.REACT_APP_IMS_API_PREFIX + "qp/runSearch";
      filterSelected = filterSelected.filter(
        (x) => !x.startsWith("Recently Added_Updated")
      );
      let filterStr = this.generateArrayString(filterSelected);
      var config;
      let query = querytext;
      let isBoolean = false;
      const queryParams = new URLSearchParams(this.props.location.search);
      /*Check for filededSearch values*/
      const params = queryParams.get("query");
      const fcount = queryParams.get("fcount");
      const f1 = queryParams.get("f1");
      const sbQuery = queryParams.get("sbQuery");
      const isDateRange = queryParams.get("isDateRange");
      let csPath = queryParams.get("cspath");
      let csproduct = queryParams.get("csproduct");
      let q = queryParams.get("q");
      let isClickOnSuggestion = queryParams.get("isClickOnSuggestion");
      let queryType = queryParams.get("queryType");
      let matchPhrase = queryParams.get("matchPhrase");
      const boolArr = ["OR", "AND", "NOT", "NEAR"];
      let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
      const contains = boolArr.some((element) => {
        if (params.includes(element)) {
          return true;
        }
        return false;
      });
      this.setState({
        csPath: csPath,
        csproduct: csproduct,
      });
      /*Check for sbQuery*/
      if (sbQuery !== null) {
        query = sbQuery;
      }
      /*Check for boolean operator in Query String Start*/
      isBoolean = queryParams.get("isBoolean");
      let booleanCounter;
      if (query) {
        booleanCounter = (query.match(/ OR | AND | NOT | NEAR /g) || []).length;
      }
      if (isBoolean !== null || booleanCounter >= 4) {
        isBoolean = true;
      } else if (booleanCounter >= 1 && query.split(" ").length <= 10) {
        isBoolean = true;
      } else {
        isBoolean = false;
      }
      /*Check for boolean operator in Query String End*/
      if (params !== "" && f1 === "majorTopicsSynonyms") {
        this.setState({
          showMajorTopicSearch: true,
        });
      } else if (
        (params !== "" && f1 === undefined) ||
        f1 === null ||
        f1 === ""
      ) {
        this.setState({
          showMajorTopicSearch: true,
        });
      } else if (params !== "" && f1 !== "majorTopicsSynonyms") {
        this.setState({
          showMajorTopicSearch: false,
        });
      }
      if (f1 === "majorTopicsSynonyms") {
        this.setState({
          majorTopicSearchState: false,
          showMajorTopicSearch: true,
        });
      }
      let val, checkFieldVal = false,
        rlView = false;
      if (fcount > 0) {
        let arr = {
          query: query,
          filters: filterStr,
          function: localStorage.getItem("function").replace(/"/g, ""),
          doctypepref: localStorage.getItem("doctypepref"),
          userproducts: localStorage.getItem("userproducts"),
          region: localStorage.getItem("region"),
          username: localStorage
            .getItem("username")
            .toLowerCase()
            .replace(/"/g, ""),
          contentpreference: localStorage
            .getItem("contentpreference")
            .replace(/"/g, ""),
          from:
            this.state.currentPage * this.state.itemsPerPage -
            this.state.itemsPerPage,
          size: parseInt(this.state.itemsPerPage),
          sortBy: this.state.sortBy,
          sortField: this.state.sortField,
          fcount: parseInt(fcount),
          boolean: isBoolean,
          accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
        };
        for (let i = 1; i <= fcount; i++) {
          let key = queryParams.get(`f${i}`);
          let value = queryParams.get(`fval${i}`);
          arr["f" + i] = key;
          arr["fval" + i] = value;
          // if (key === 'congressName' || key === 'protocolIDs' || key === 'docTypeSynonyms') {
          if (q !== "All Fields" && !contains) {
            checkFieldVal = true;
          }
          if (arr["f" + i] === "searchID") {
            rlView = true;
          }
        }
        if (queryType) {
          arr["queryType"] = "alerts";
        }
        if (checkFieldVal || rlView) {
          arr["matchPhrase"] = true;
        } else {
          arr["matchPhrase"] = false;
        }
        if (
          params.trim().includes(" ") &&
          !isClickOnSuggestion &&
          q !== "All Fields"
        ) {
          arr["matchPhrase"] = false;
        }
        if (matchPhrase) {
          arr["matchPhrase"] = true;
        }
        val = arr;
        config = {
          method: "POST",
          url: url,
          headers: {
            "Content-Type": "application/json",
          },
          data: val,
        };
      } else {
        config = {
          method: "POST",
          url: url,
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            query: query,
            filters: filterStr,
            function: localStorage.getItem("function").replace(/"/g, ""),
            doctypepref: localStorage.getItem("doctypepref"),
            userproducts: localStorage.getItem("userproducts"),
            region: localStorage.getItem("region"),
            username: localStorage
              .getItem("username")
              .toLowerCase()
              .replace(/"/g, ""),
            contentpreference: localStorage
              .getItem("contentpreference")
              .replace(/"/g, ""),
            from:
              this.state.currentPage * this.state.itemsPerPage -
              this.state.itemsPerPage,
            size: parseInt(this.state.itemsPerPage),
            sortBy: this.state.sortBy,
            sortField: this.state.sortField,
            boolean: isBoolean,
            accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
          },
        };
      }
      if (isDateRange !== null) {
        let rangeField = queryParams.get("rangeField");
        let toRange = queryParams.get("toRange");
        let fromRange = queryParams.get("fromRange");
        if (rangeField && (toRange || fromRange)) {
          config.data["rangeField"] = rangeField;
          config.data["isDateRange"] = true;
        }
        if (toRange !== null) {
          config.data["toRange"] = toRange;
        }
        if (fromRange !== null) {
          config.data["fromRange"] = fromRange;
        }
      }
      if (queryType) {
        config.data["queryType"] = "alerts";
      }
      if (matchPhrase) {
        config.data["matchPhrase"] = true;
      }
      // let citationSelectStatus = [];
      axios(config)
        .then((response) => {
          var processed = processFilters(response.data.body);
          const filterService = new FilterService();
          const [filters] = filterService.expandFilterSet(
            processed,
            this.state.appliedFilters
          );
          let selectedFiltered = this.state.selectedFilters.concat(filters);
          // selectedFiltered = [...new Set(selectedFiltered)]
          selectedFiltered = this.state.appliedFilters.concat(filters);
          this.setState(
            {
              documentCount: response.data.body.hits.total.value,
              response: response.data.body.hits.hits,
              filters: processed,
              isProcessing: false,
              isLoaded: true,
              collapseFilter: false,
              selectedFilters: selectedFiltered,
            },
            () => {
              if (
                this.state.globalSelectAll &&
                (this.state.citationSelectStatus.length.toString() ===
                  this.state.itemsPerPage ||
                  this.state.citationSelectStatus.length ===
                  this.state.documentCount)
              ) {
                this.setState({ globalSelectAll: true });
              } else {
                this.setState({ globalSelectAll: false });
              }
              this.handleAllFilters(
                this.state.filters,
                this.state.appliedFilters,
                this.state.selectedFilters,
                this.state.response,
                this.state.documentCount,
                querytext
              );
            }
          );
          // VIEW SELECTED LOGIC
          let data = this.state.selectedResults;
          let citationSelectStatus = this.state.citationSelectStatus;
          let markedUrl = this.markedDocumentsUrl;
          var sessiondata = sessionStorage.getItem("selectedResultsDetails");
          // for (let index = 0; index < this.state.documentCount; index++) {
          if (localStorage.getItem("selectedResults") === null) {
            if (window.location.search !== this.state.prevUrl) {
              data = [];
              citationSelectStatus = [];
              markedUrl = [];
            }
            /* else {
                                                        citationSelectStatus.push(false)
                                                    } */
          }
          if (
            localStorage.getItem("selectedResults") !== null &&
            sessiondata !== null
          ) {
            // let totalPages = Math.ceil(this.state.documentCount / this.state.itemsPerPage)
            // console.log(this.state.itemsPerPage, totalPages,localStorage.getItem('selectedResults'), Math.max.apply(null,localStorage.getItem('selectedResults').split(',')))
            // else{
            // if (window.location.search != this.state.prevUrl) {
            if (
              decodeURI(this.props.location.search) !==
              decodeURI(this.state.prevUrl)
            ) {
              data = [];
              citationSelectStatus = [];
              localStorage.removeItem("selectedResults");
              localStorage.removeItem("ItemsPerPage");
              localStorage.removeItem("ActivePage");
              localStorage.removeItem("markedResultsUrl");
              sessionStorage.clear();
            }
            // else {
            //     localStorage.getItem('selectedResults').split(',').map((x, key) => {
            //         let i = parseInt(x)
            //         citationSelectStatus[i - 1] = true;
            //         if (citationSelectStatus[i - 1]) {
            //             data = JSON.parse(sessiondata)
            //             // data.push(JSON.parse(sessiondata))
            //             // data.push({
            //             //     detail: this.state.response[i - 1]._source,
            //             //     itemTeaser: this.state.response[i - 1].highlight.body,
            //             //     index: i
            //             // })
            //         }
            //         else {
            //             data.splice(i - 1, 1)
            //         }
            //     })
            // }
          }
          // }
          // console.log(data,citationSelectStatus)
          this.markedDocumentsUrl = markedUrl;
          this.setState({
            citationSelectStatus: citationSelectStatus,
            selectedResults: data,
            recentlyUsedFilters: JSON.parse(
              localStorage.getItem("recentlyUsedFilters")
            ),
          });
        })
        .catch(function (error) {
          this.setState({ error: error });
        })
        .finally(async () => {
          await this.getSynonym();
          this.setState({ isProcessing: false, isLoaded: true });
        });
    } catch (error) {
      this.setState({ error: error });
    }
  }
  handleClearFilters = () => {
    try {
      //console.log('Clear Filters from Breadcrumbs')
      this.setState(
        {
          selectedFilters: [],
          appliedFilters: [],
          selectedParentFilterArr: [],
          filters: [],
          fromRecentlyAddedUpdated: 0,
          toRecentlyAddedUpdated: 6,
          globalSelectAll: false,
          selectedResults: [],
          citationSelectStatus: [],
        },
        () => {
          let params = queryString.parse(this.props.location.search);
          const newUrl = this.buildUrl(
            params.query,
            params.q,
            params.f1,
            this.state.appliedFilters,
            false,
            true
          );
          this.props.history.push(newUrl);
          this.runSearchFromQueryParams(
            this.state.allFilters,
            this.state.appliedFilters,
            this.state.searchKeyword
          );
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleRemoveAppliedFilter = (facet) => {
    try {
      let parentFacetName = facet.split("/")[0];
      let arr = this.state.selectedParentFilterArr;
      const index = arr.indexOf(parentFacetName);
      if (index > -1) {
        arr.splice(index, 1);
      }
      this.setState(
        {
          appliedFilters: this.state.appliedFilters.filter(
            (filter) => !filter.startsWith(facet)
          ),
          selectedParentFilterArr: arr,
          fromRecentlyAddedUpdated: 0,
          toRecentlyAddedUpdated: 6,
          selectedFilters: this.state.selectedFilters.filter(
            (filter) => filter && !filter.startsWith(facet)
          ),
          globalSelectAll: false,
          selectedResults: [],
          citationSelectStatus: [],
        },
        () => {
          let params = queryString.parse(this.props.location.search);
          const newUrl = this.buildUrl(
            params.query,
            params.q,
            params.f1,
            this.state.appliedFilters,
            false,
            parentFacetName === "Recently Added_Updated"
          );
          this.props.history.push(newUrl);
          this.runSearchFromQueryParams(
            this.state.allFilters,
            this.state.appliedFilters,
            this.state.searchKeyword
          );
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleClearSearch = () => {
    try {
      window.supportingDocsSearchStrategyDescription = undefined;
      window.isSupportingDocs = undefined;
      this.setState(
        {
          selectedFilters: [],
          appliedFilters: [],
          filters: [],
          selectedParentFilterArr: [],
          searchKeyword: "",
          isClearSearch: true,
          currentPage: 1,
          fromRecentlyAddedUpdated: 0,
          toRecentlyAddedUpdated: 6,
          globalSelectAll: false,
          selectedResults: [],
          citationSelectStatus: [],
          cskpSearchData: [],
        },
        () => {
          //let params = queryString.parse(this.props.location.search);
          const newUrl = this.buildUrl(
            this.state.searchKeyword,
            "All Fields",
            "",
            this.state.appliedFilters,
            true
          );
          this.props.history.push(newUrl);
          this.runSearchFromQueryParams(
            this.state.allFilters,
            this.state.appliedFilters,
            this.state.searchKeyword
          );
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleMajorTopicSearch = (url) => {
    try {
      this.props.history.replace(url);
      window.location.reload();
      /* this.setState({
                        showMajorTopicSearch:true,
                        majorTopicSearchState:!this.state.majorTopicSearchState
                    }) */
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handelResults = () => {
    this.setState({ showResultType: !this.state.showResultType });
  };
  handleChangeInDropdown = (event) => {
    try {
      event.preventDefault();
      this.setState({
        resultDropdownValue: event.target.attributes.keyvalue.value,
        showResultType: !this.state.showResultType,
      });
      if (event.target.attributes.keyvalue.value === "PubMed Search Results") {
        this.handleOnPubMedTabClick();
      }
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handelSearchResultClose = () => {
    this.setState({ showResultType: false });
  };
  conversionOfFieldType = (item) => {
    try {
      if (item === "docType" || item === "docTypeSynonyms")
        return "Document Type";
      else if (item === "protocolIDs" || item === "harmonizedProtocolIDs") return "Protocol Number";
      else if (item === "genericName") return "Generic Name";
      else if (item === "congressName" || item === "fullCnSf")
        return "Congress Name";
      else if (item === "strYear") return "Publication Year";
      else if (item === "authors") return "Author";
      else if (item === "docTitle") return "Title";
      else return "Journal Name";
    } catch (error) {
      this.setState({ error: error });
    }
  };
  conversionOfFieldValue = (item) => {
    return item.replace(/;/g, " OR ");
    // if (item === "docType" || item === "docTypeSynonyms") return "Document Type";
    // else if (item === "protocolIDs") return "Protocol Number";
    // else if (item === "genericName") return "Generic Name";
    // else if (item === "congressName" || item === "fullCnSf") return "Congress Name";
    // else if (item === "strYear") return "Publication Year";
    // else if (item === "authors") return "Author";
    // else if (item === "docTitle") return "Title";
    // else return "Journal Name";
  };
  removeFieldedSearch = async (fieldedSearchItem) => {
    try {
      const queryParams = new URLSearchParams(this.props.location.search);
      let DS = queryParams.get("DS");
      let isRemoveSearchKeyword = false;
      if (DS) {
        isRemoveSearchKeyword =
          DS === `${fieldedSearchItem.key}ǂ${fieldedSearchItem.value}`;
      }
      this.setState(
        {
          removeFieldedSearchItem: fieldedSearchItem,
          searchKeyword: isRemoveSearchKeyword ? "" : this.state.searchKeyword,
          dropdownValue: isRemoveSearchKeyword ? "" : this.state.dropdownValue,
        },
        async () => {
          let queryStringparams = queryString.parse(this.props.location.search);
          const newUrl = this.buildUrl(
            isRemoveSearchKeyword ? "" : queryStringparams.query,
            queryStringparams.q,
            queryStringparams.f1,
            this.state.appliedFilters,
            false
          );
          this.props.history.push({
            pathname: "/results",
            search: newUrl,
            state: {
              selectedFilters: this.state.selectedFilters,
              allFilters: this.state.allFilters,
              appliedFilters: this.state.appliedFilters,
              removeFieldedSearchItem: {},
            },
          });
          this.runSearchFromQueryParams(
            this.state.allFilters,
            this.state.appliedFilters,
            this.state.searchKeyword
          );
          //this.props.history.push(newUrl);
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  showFieldedSearch = () => {
    try {
      let queryParams = new URLSearchParams(this.props.location.search);
      let limistArr = [];
      let fieldedSearch = false;
      let fcount = queryParams.get("fcount");
      let docTitle = queryParams.get("docTitle");
      let authors = queryParams.get("authors");
      let majorTopicsSynonyms = queryParams.get("majorTopicsSynonyms");
      let toRange = queryParams.get("toYear");
      let fromRange = queryParams.get("fromYear");
      let PublicationYear = "";
      if (toRange) {
        toRange = parseInt(toRange);
      }
      if (fromRange) {
        fromRange = parseInt(fromRange);
      }
      fieldedSearch = queryParams.get("FS");
      for (let i = 0; i < fcount; i++) {
        let key = queryParams.get(`f${i + 1}`);
        let value = queryParams.get(`fval${i + 1}`);
        limistArr[i] = { key: key, value: value, fCountValue: (i + 1) };
      }
      if (toRange && fromRange) {
        PublicationYear = `${fromRange} To ${toRange}`;
      } else if (toRange) {
        PublicationYear = `${toRange} and earlier`;
      } else if (fromRange) {
        PublicationYear = `${fromRange} and later`;
      }
      if (
        fieldedSearch &&
        ((docTitle !== null && docTitle !== " ") ||
          (authors !== " " && authors !== null) ||
          (majorTopicsSynonyms !== " " && majorTopicsSynonyms !== null) ||
          fromRange !== null ||
          toRange ||
          fcount > 0)
      ) {
        if (this.state.width <= 600) {
          return (
            <div className="filter-selection-display-wrapper-mobile">
              <div className="applied-filter-header-section-mobile">
                {/* <span className="applied-filter-header-text">Fielded Search</span> */}
                <div className="applied-filter-header-text-mobile">
                  Fielded Search
                </div>
                <div
                  className="applied-filters-header-icons-wrapper"
                  style={{ justifyContent: "space-around" }}
                >
                  <div
                    onClick={(e) =>
                      this.setState({ showSearch: !this.state.showSearch })
                    }
                  >
                    {!this.state.showSearch ? (
                      <img
                        src={expandAppliedFilterMobile}
                        alt=""
                        width="22"
                        height="22"
                      />
                    ) : (
                      <img
                        src={expandAppliedFilterMobile}
                        alt=""
                        width="22"
                        height="22"
                        style={{ transform: "rotate(180deg)" }}
                      />
                    )}
                  </div>
                </div>
              </div>
              {this.state.showSearch && (
                <div className="filter-display-item-wrapper filter-display-item-wrapper-mobile">
                  <div className="facet-item-wrapper">
                    <span className="facet-inner-wrapper">
                      {docTitle !== " " && docTitle !== null && (
                        <>
                          <span className="fs-parent-field-wrapper-mobile">
                            Title
                          </span>
                          <span className="subfilter-wrapper">
                            <span className="subfilter">
                              <span className="subfilter-text">{docTitle}</span>
                              <img src={popCancelBttn} alt='' className='remove-fieldedsearch clickable' title={`Remove Title: ${docTitle}`} onClick={() => { this.removeFieldedSearch({ key: 'docTitle', value: docTitle }) }} />
                            </span>
                          </span>
                        </>
                      )}
                      {authors !== " " && authors !== null && (
                        <>
                          <span className="fs-parent-field-wrapper-mobile">
                            Author
                          </span>
                          <span className="subfilter-wrapper">
                            <span className="subfilter">
                              <span className="subfilter-text">{authors}</span>
                              <img src={popCancelBttn} alt='' className='remove-fieldedsearch clickable' title={`Remove Authors: ${authors}`} onClick={() => { this.removeFieldedSearch({ key: 'authors', value: authors }) }} />
                            </span>
                          </span>
                        </>
                      )}
                      {majorTopicsSynonyms !== " " &&
                        majorTopicsSynonyms !== null && (
                          <>
                            <span className="fs-parent-field-wrapper-mobile">
                              Major Topics
                            </span>
                            <span className="subfilter-wrapper">
                              <span className="subfilter">
                                <span className="subfilter-text">
                                  {majorTopicsSynonyms}
                                </span>
                                <img src={popCancelBttn} alt='' className='remove-fieldedsearch clickable' title={`Remove Major Topics: ${majorTopicsSynonyms}`} onClick={() => { this.removeFieldedSearch({ key: 'majorTopicsSynonyms', value: majorTopicsSynonyms }) }} />
                              </span>
                            </span>
                          </>
                        )}
                      {PublicationYear && (
                        <>
                          <span className="fs-parent-field-wrapper-mobile">
                            Publication Year
                          </span>
                          <span className="subfilter-wrapper">
                            <span className="subfilter">
                              <span className="subfilter-text">
                                {PublicationYear}
                              </span>
                              <img src={popCancelBttn} alt='' className='remove-fieldedsearch clickable' title={`Remove Publication Year: ${PublicationYear}`} onClick={() => { this.removeFieldedSearch({ key: 'toYear', value: PublicationYear }) }} />
                            </span>
                          </span>
                        </>
                      )}
                      {fcount > 0 && (
                        <>
                          {limistArr.map((item, index) => {
                            return (
                              <>
                                <span className="fs-parent-field-wrapper-mobile">
                                  {this.conversionOfFieldType(item.key)}
                                </span>
                                <span className="subfilter-wrapper">
                                  <span className="subfilter">
                                    <span className="subfilter-text">
                                      {item.value}
                                    </span>
                                    <img src={popCancelBttn} alt='' className='remove-fieldedsearch clickable' title={`Remove ${this.conversionOfFieldType(item.key)}: ${this.conversionOfFieldValue(item.value)}`} onClick={() => { this.removeFieldedSearch({ key: item.key, value: item.value, fCountValue: item.fCountValue }) }} />
                                  </span>
                                </span>
                              </>
                            );
                          })}
                        </>
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          );
        } else {
          return (
            <div className="filter-selection-display-wrapper">
              <div className="applied-filter-header-section">
                <span className="applied-filter-header-text">Fielded Search</span>
              </div>
              <div className="filter-display-item-wrapper">
                <div className="facet-item-wrapper">
                  <span className="facet-inner-wrapper">
                    {docTitle !== " " && docTitle !== null && (
                      <>
                        <span className="fs-parent-field-wrapper">Title</span>
                        <span className="subfilter-wrapper">
                          <span className="subfilter">
                            <span className="subfilter-text">{docTitle}</span>
                            <img src={popCancelBttn} alt='' className='remove-fieldedsearch clickable' title={`Remove Title: ${docTitle}`} onClick={() => { this.removeFieldedSearch({ key: 'docTitle', value: docTitle }) }} />
                          </span>
                        </span>
                      </>
                    )}
                    {authors !== " " && authors !== null && (
                      <>
                        <span className="fs-parent-field-wrapper">Author</span>
                        <span className="subfilter-wrapper">
                          <span className="subfilter">
                            <span className="subfilter-text">{authors}</span>
                            <img src={popCancelBttn} alt='' className='remove-fieldedsearch clickable' title={`Remove Authors: ${authors}`} onClick={() => { this.removeFieldedSearch({ key: 'authors', value: authors }) }} />
                          </span>
                        </span>
                      </>
                    )}
                    {majorTopicsSynonyms !== " " && majorTopicsSynonyms !== null && (
                      <>
                        <span className="fs-parent-field-wrapper">
                          Major Topics
                        </span>
                        <span className="subfilter-wrapper">
                          <span className="subfilter">
                            <span className="subfilter-text">
                              {majorTopicsSynonyms}
                              <img src={popCancelBttn} alt='' className='remove-fieldedsearch clickable' title={`Remove Major Topics: ${majorTopicsSynonyms}`} onClick={() => { this.removeFieldedSearch({ key: 'majorTopicsSynonyms', value: majorTopicsSynonyms }) }} />
                            </span>
                          </span>
                        </span>
                      </>
                    )}
                    {PublicationYear && (
                      <>
                        <span className="fs-parent-field-wrapper">
                          Publication Year
                        </span>
                        <span className="subfilter-wrapper">
                          <span className="subfilter">
                            <span className="subfilter-text">
                              {PublicationYear}
                            </span>
                            <img src={popCancelBttn} alt='' className='remove-fieldedsearch clickable' title={`Remove Publication Year: ${PublicationYear}`} onClick={() => { this.removeFieldedSearch({ key: 'toYear', value: PublicationYear }) }} />
                          </span>
                        </span>
                      </>
                    )}
                    {fcount > 0 && (
                      <>
                        {limistArr.map((item, index) => {
                          return (
                            <>
                              <span className="fs-parent-field-wrapper">
                                {this.conversionOfFieldType(item.key)}
                              </span>
                              <span className="subfilter-wrapper">
                                <span className="subfilter">
                                  <span className="subfilter-text">
                                    {this.conversionOfFieldValue(item.value)}
                                  </span>
                                  <img src={popCancelBttn} alt='' className='remove-fieldedsearch clickable' title={`Remove ${this.conversionOfFieldType(item.key)}: ${this.conversionOfFieldValue(item.value)}`} onClick={() => { this.removeFieldedSearch({ key: item.key, value: item.value, fCountValue: item.fCountValue }) }} />
                                </span>
                              </span>
                            </>
                          );
                        })}
                      </>
                    )}
                  </span>
                </div>
              </div>
            </div>
          );
        }
      }
      return null;
    } catch (error) {
      this.setState({ error: error });
    }
  };
  removeContentShortcutsSearch = async () => {
    try {
      this.setState(
        {
          isContentShortcutsSearch: true,
          // searchKeyword: isRemoveSearchKeyword ? "" : this.state.searchKeyword,
          // dropdownValue: isRemoveSearchKeyword ? "" : this.state.dropdownValue,
        },
        async () => {
          let queryStringparams = queryString.parse(this.props.location.search);
          const newUrl = this.buildUrl(
            //isRemoveSearchKeyword ? "" : queryStringparams.query,
            queryStringparams.query,
            queryStringparams.q,
            queryStringparams.f1,
            this.state.appliedFilters,
            false
          );
          this.props.history.push({
            pathname: "/results",
            search: newUrl,
            state: {
              selectedFilters: this.state.selectedFilters,
              allFilters: this.state.allFilters,
              appliedFilters: this.state.appliedFilters,
              isContentShortcutsSearch: false,
            },
          });
          this.runSearchFromQueryParams(
            this.state.allFilters,
            this.state.appliedFilters,
            this.state.searchKeyword
          );
          //this.props.history.push(newUrl);
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  showContentShortcutsSearch = () => {
    try {
      let queryParams = new URLSearchParams(this.props.location.search);
      let fcount = queryParams.get("fcount");
      let fName = "", fValue = "";
      if (fcount > 0) {
        fName = queryParams.get(`f1`);
        fValue = queryParams.get(`fval1`);

      }
      if (fName && fValue && (fName === 'tempJNJDrugs' || fName === 'taIRO')) {
        if (this.state.width <= 600) {
          return (
            <div className="filter-selection-display-wrapper-mobile">
              <div className="applied-filter-header-section-mobile">
                <div className="applied-filter-header-text-mobile">
                  Content Shortcuts Search
                </div>
                <div
                  className="applied-filters-header-icons-wrapper"
                  style={{ justifyContent: "space-around" }}
                >
                  <div
                    onClick={(e) =>
                      this.setState({ showContentShortcutSearch: !this.state.showContentShortcutSearch })
                    }
                  >
                    {!this.state.showContentShortcutSearch ? (
                      <img
                        src={expandAppliedFilterMobile}
                        alt=""
                        width="22"
                        height="22"
                      />
                    ) : (
                      <img
                        src={expandAppliedFilterMobile}
                        alt=""
                        width="22"
                        height="22"
                        style={{ transform: "rotate(180deg)" }}
                      />
                    )}
                  </div>
                </div>
              </div>
              {this.state.showContentShortcutSearch && (
                <div className="filter-display-item-wrapper filter-display-item-wrapper-mobile">
                  <div className="facet-item-wrapper">
                    <span className="facet-inner-wrapper">
                      {fName && fName === "tempJNJDrugs" && (
                        <>
                          <span className="fs-parent-field-wrapper-mobile">
                            Products
                          </span>
                          <span className="subfilter-wrapper">
                            <span className="subfilter">
                              <span className="subfilter-text">{fValue}</span>
                              <img src={popCancelBttn} alt='' className='remove-fieldedsearch clickable' title={`Remove Products: ${fValue}`} onClick={() => { this.removeContentShortcutsSearch() }} />
                            </span>
                          </span>
                        </>
                      )}
                      {fName && fName === "taIRO" && (
                        <>
                          <span className="fs-parent-field-wrapper-mobile">
                            Therapeutic Area
                          </span>
                          <span className="subfilter-wrapper">
                            <span className="subfilter">
                              <span className="subfilter-text">{fValue}</span>
                              <img src={popCancelBttn} alt='' className='remove-fieldedsearch clickable' title={`Remove Therapeutic Area: ${fValue}`} onClick={() => { this.removeContentShortcutsSearch() }} />
                            </span>
                          </span>
                        </>
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          );
        } else {
          return (
            <div className="filter-selection-display-wrapper">
              <div className="applied-filter-header-section">
                <span className="applied-filter-header-text">Content Shortcuts Search</span>
              </div>
              <div className="filter-display-item-wrapper">
                <div className="facet-item-wrapper">
                  <span className="facet-inner-wrapper">
                    {fName && fName === "tempJNJDrugs" && (
                      <>
                        <span className="fs-parent-field-wrapper">Products</span>
                        <span className="subfilter-wrapper">
                          <span className="subfilter">
                            <span className="subfilter-text">{fValue}</span>
                            <img src={popCancelBttn} alt='' className='remove-fieldedsearch clickable' title={`Remove Products: ${fValue}`} onClick={() => { this.removeContentShortcutsSearch() }} />
                          </span>
                        </span>
                      </>
                    )}
                    {fName && fName === "taIRO" && (
                      <>
                        <span className="fs-parent-field-wrapper">Therapeutic Area</span>
                        <span className="subfilter-wrapper">
                          <span className="subfilter">
                            <span className="subfilter-text">{fValue}</span>
                            <img src={popCancelBttn} alt='' className='remove-fieldedsearch clickable' title={`Remove Therapeutic Area: ${fValue}`} onClick={() => { this.removeContentShortcutsSearch() }} />
                          </span>
                        </span>
                      </>
                    )}
                  </span>
                </div>
              </div>
            </div>
          );
        }
      }
      return null;
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleCollapseAllGlobal = () => {
    try {
      let { response, collapsedSearchIDs } = this.state;
      if (!this.state.globalCollapseAll) {
        collapsedSearchIDs = response.map((x) => x._source.searchID);
      } else {
        collapsedSearchIDs = [];
      }
      this.setState({
        globalCollapseAll: !this.state.globalCollapseAll,
        collapsedSearchIDs: collapsedSearchIDs,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleSelectAll = () => {
    try {
      //console.log('Change reflecting till result');
      this.setState(
        {
          globalSelectAll: !this.state.globalSelectAll,
        },
        () => {
          let startIndex =
            this.state.currentPage * this.state.itemsPerPage -
            this.state.itemsPerPage +
            1;
          var count = startIndex;
          let endIndex = this.state.itemsPerPage;
          if (this.state.globalSelectAll) {
            this.markedDocumentsUrl = [];
            this.setState({ selectedResults: [] }, () => {
              for (let i = 1; i <= endIndex; i++) {
                //console.log(i,this.state.response[i-1])
                if (this.state.response[i - 1] !== undefined) {
                  this.handleCitationSelectionGlobal(
                    count,
                    this.state.response[i - 1]._source,
                    this.state.response[i - 1].highlight === undefined ||
                      this.state.response[i - 1].highlight?.body[0] ===
                      "Empty File" ||
                      this.state.response[i - 1].highlight?.body[0] ===
                      "Not an empty file"
                      ? []
                      : this.state.response[i - 1].highlight.body,
                    i
                  );
                  count++;
                }
              }
            });
          } else {
            for (let i = 1; i <= endIndex; i++) {
              if (this.state.response[i - 1] !== undefined) {
                // console.log(count)
                this.handleCitationUnSelection(
                  count,
                  this.state.response[i - 1]._source,
                  this.state.response[i - 1].highlight === undefined ||
                    this.state.response[i - 1].highlight?.body[0] ===
                    "Empty File" ||
                    this.state.response[i - 1].highlight?.body[0] ===
                    "Not an empty file"
                    ? []
                    : this.state.response[i - 1].highlight.body
                );
                count++;
              }
            }
          }
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleGlobalEmailPopUp = (status) => {
    this.setState({ globalEmailPopUp: status });
  };
  handleEmailPopUpClose = () => {
    this.setState({ globalEmailPopUp: false });
  };
  handleGlobalNotifyMePopUp = (status) => {
    this.setState({ globalNotifyMePopUp: status });
  };
  handleNotifyMePopUpClose = () => {
    this.setState({ globalNotifyMePopUp: false });
  };
  handleGlobalBookmark = (status) => {
    this.setState({ bookmarkPopUp: status });
  };
  handleCloseBookmark = () => {
    this.setState({ bookmarkPopUp: false });
  };
  handleOnClickReadingList = (status) => {
    this.setState({ readingListPopup: status });
  };
  handleCloseReadingList = () => {
    this.setState({ readingListPopup: false });
  };
  handleOnPubMedTabClick = () => {
    try {
      let queryStringparams = queryString.parse(this.props.location.search);
      const pubMedUrl = this.buildUrl(
        queryStringparams.query,
        queryStringparams.q,
        queryStringparams.f1,
        this.state.appliedFilters,
        false
      );
      this.props.history.push(`/pubMedResults${pubMedUrl}`);
    } catch (error) {
      this.setState({ error: error });
    }
  };
  createErrorHtml = () => {
    if (this.state.error.response) {
      let JSON = this.state.error.response.data;

      return (
        <>
          <p>
            <b>ERROR: </b>
            {JSON.ERROR}
            <br />
            <b>MESSAGE: </b>
            {JSON.MESSAGE}
            <br />
            <b>REQUEST-ID: </b>
            {JSON["REQUEST-ID"]}
            <br />
            Please contact{" "}
            <a
              href={`mailto:imks.jnj.com?subject= iMS Query Error - ${JSON["REQUEST-ID"]}`}
            >
              iMedical Search Support Team
            </a>
          </p>
        </>
      );
    } else {
      return this.state.error.message;
    }
  };
  errorAlertMessage = (message) => {
    this.setState({
      alertMessageContent: message,
      alertMessageModelColor: "#de1327",
      alertMessageShowModal: true
    })
  }

  closeAlertMessage = () => {
    this.setState({
      alertMessageContent: "",
      alertMessageModelColor: "",
      alertMessageShowModal: false
    })
  }
  render() {
    let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
    const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
    let params = new URLSearchParams(this.props.location.search);
    let query = params.get("query");
    /* const filters = queryParams.get('filters') 
                    let params = queryString.parse(this.props.location.search);*/
    // var category;
    var filters = params.get("filters");
    if (params.get("q") === undefined) {
      category = "All Fields";
    } else {
      category = params.get("q");
    }
    if (filters === "" || filters === undefined || filters === null) {
      filters = "";
    }
    if (this.state.width < 600) {
      return (
        <div>
          <ErrorBoundary>
            {this.state.userExists ? (
              <>
                <div className="resultsInnerWrapper">
                  <NavigationMenu
                    pathName={window.location.pathname}
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                  <div className="header-wrapper">
                    <HeaderSearch
                      history={this.props.history}
                      dropdownValue={category}
                      searchKeyword={query}
                      appliedFilters={this.state.appliedFilters}
                      handleChangeInSearch={this.handleChangeInSearch}
                      handleClearSearch={this.handleClearSearch}
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                      isCitationSelected={this.state.citationSelectStatus}
                      isClearSearch={this.state.isClearSearch}
                      csPath={this.state.csPath}
                      csproduct={this.state.csproduct}
                    />
                  </div>
                  <div className="results-contents-wrapper">
                    <div className="imsResultsMenuWrapper-mobile">
                      {this.state.error === "" ? (
                        <ResponsiveFilterComponent
                          pathName={window.location.pathname}
                          customWidth={this.state.width}
                          customHeight={this.state.height}
                          allFilters={this.state.allFilters}
                          filters={this.state.filters}
                          appliedFilters={this.state.appliedFilters}
                          handleOnChangeInfilter={this.handleOnChangeInfilter}
                          selectedParentFilterArr={
                            this.state.selectedParentFilterArr
                          }
                          handleChangeInSearch={this.handleChangeInSearch}
                          selectedFilters={this.state.selectedFilters}
                          querytext={this.state.searchKeyword}
                          documentCount={this.state.documentCount}
                          response={this.state.response}
                          isProcessing={this.state.isProcessing}
                          handelSelectedCitation={
                            this.getSelectedCitationDetails
                          }
                          handleCollapseAllGlobal={this.handleCollapseAllGlobal}
                          globalCollapseAll={this.state.globalCollapseAll}
                          collapseFilterRes={this.state.collapseFilter}
                          globalSelectAll={this.state.globalSelectAll}
                          handleSelectAll={this.handleSelectAll}
                          handleGlobalEmailPopUp={this.handleGlobalEmailPopUp}
                          handleGlobalNotifyMePopUp={
                            this.handleGlobalNotifyMePopUp
                          }
                          handleGlobalBookmark={this.handleGlobalBookmark}
                          handleOnClickReadingList={
                            this.handleOnClickReadingList
                          }
                          csPath={this.state.csPath}
                          csproduct={this.state.csproduct}
                          recentlyUsedFilters={this.state.recentlyUsedFilters}
                          fromRecentlyAddedUpdated={
                            this.state.fromRecentlyAddedUpdated
                          }
                          toRecentlyAddedUpdated={
                            this.state.toRecentlyAddedUpdated
                          }
                          gotoPageRecentlyAddedUpdated={
                            this.gotoPageRecentlyAddedUpdated
                          }
                        />
                      ) : (
                        <ResponsiveFilterComponent
                          pathName={window.location.pathname}
                          customWidth={this.state.width}
                          customHeight={this.state.height}
                          allFilters={[]}
                          filters={[]}
                          appliedFilters={[]}
                          handleOnChangeInfilter={this.handleOnChangeInfilter}
                          selectedParentFilterArr={[]}
                          handleChangeInSearch={this.handleChangeInSearch}
                          selectedFilters={[]}
                          querytext={this.state.searchKeyword}
                          documentCount={0}
                          response={[]}
                          isProcessing={this.state.isProcessing}
                          handelSelectedCitation={
                            this.getSelectedCitationDetails
                          }
                          handleCollapseAllGlobal={this.handleCollapseAllGlobal}
                          globalCollapseAll={this.state.globalCollapseAll}
                          collapseFilterRes={this.state.collapseFilter}
                          globalSelectAll={this.state.globalSelectAll}
                          handleSelectAll={this.handleSelectAll}
                          handleGlobalEmailPopUp={this.handleGlobalEmailPopUp}
                          handleGlobalNotifyMePopUp={
                            this.handleGlobalNotifyMePopUp
                          }
                          handleGlobalBookmark={this.handleGlobalBookmark}
                          handleOnClickReadingList={
                            this.handleOnClickReadingList
                          }
                          csPath={this.state.csPath}
                          csproduct={this.state.csproduct}
                          recentlyUsedFilters={[]}
                          fromRecentlyAddedUpdated={[]}
                          toRecentlyAddedUpdated={[]}
                          gotoPageRecentlyAddedUpdated={
                            this.gotoPageRecentlyAddedUpdated
                          }
                        />
                      )}
                      <div
                        className="imsResultsMenuHeader-mobile"
                        onClick={this.handelResults}
                        onMouseLeave={this.handelSearchResultClose}
                      >
                        <span>
                          {/* iMS Search Results */}
                          {this.state.resultDropdownValue}
                        </span>
                        <img
                          src={ActionsArrowIcon}
                          alt="ActionsArrowIcon"
                          className="iMSPubMedResults-dropdownIcon-mobile"
                          style={{
                            transform: this.state.showResultType
                              ? "matrix(-1, 0, 0, -1, 0, 0)"
                              : null,
                          }}
                        />
                        {this.state.showResultType && (
                          <ul className="iMSPubMedResults-dropdownContainer-mobile">
                            {this.state.resultDropdownValue ===
                              "iMS Search Results" ? null : (
                              <li
                                className="iMSPubMedResults-dropdownText-mobile"
                                keyvalue="iMS Search Results"
                                onClick={this.handleChangeInDropdown}
                              >
                                iMS Search Results
                              </li>
                            )}
                            {this.state.resultDropdownValue ===
                              "PubMed Search Results" ? null : (
                              <li
                                className="iMSPubMedResults-dropdownText-mobile"
                                keyvalue="PubMed Search Results"
                                onClick={this.handleChangeInDropdown}
                              >
                                PubMed Search Results
                              </li>
                            )}
                            {/* <span className="iMSPubMedResults-dropdownText-mobile">iMS Results</span>
                                                    <span className="iMSPubMedResults-dropdownText-mobile">PubMed Results</span> */}
                          </ul>
                        )}
                        {/* <span>
                                        PubMed Search Results
                                    </span> */}
                      </div>
                      <div className="resultsContentWrapper resultsContentWrapper-mobile">
                        {this.state.isLoaded ? (
                          <>
                            {/* {this.state.response.length > 0 &&
                                                    <> */}
                            {this.state.error === ""
                              ? this.resultListCount(
                                this.state.itemsPerPage,
                                this.state.currentPage
                              )
                              : ""}
                            {this.state.cskpSearchData.length > 0 &&
                              this.state.response.length > 0 && (
                                <div
                                  class="results-count-wrapper"
                                  style={{ display: "block", margin: "0.5% 0" }}
                                >
                                  <div className="clinicalStudies-container">
                                    <KnowledgePanelData
                                      searchKeyword={this.state.searchKeyword}
                                      searchTypeRes={this.state.cskpSearchData}
                                      appliedFilters={this.state.appliedFilters}
                                    />
                                  </div>
                                </div>
                              )}
                            {this.showFieldedSearch()}
                            {this.showContentShortcutsSearch()}
                            {this.state.appliedFilters.length > 0 ? (
                              <FilterDisplay
                                allFilters={this.state.allFilters}
                                appliedFilters={this.state.appliedFilters}
                                handleClearFilters={this.handleClearFilters}
                                handleRemoveAppliedFilter={
                                  this.handleRemoveAppliedFilter
                                }
                                customWidth={this.state.width}
                              />
                            ) : null}
                            <div className="results-wrapper">
                              {/* {this.resultList(
                                this.state.response,
                                this.state.itemsPerPage,
                                this.state.currentPage
                              )} */}
                              {this.state.error === "" ? (
                                this.resultList(
                                  this.state.response,
                                  this.state.itemsPerPage,
                                  this.state.currentPage
                                )
                              ) : (
                                <>
                                  {(() => {
                                    let service = new Services();
                                    service.logService(
                                      this.state.error.stack,
                                      "error"
                                    );
                                  })()}

                                  <div style={{ margin: "2rem 2rem" }}>
                                    <h2>Sorry, something went wrong</h2>
                                    <p
                                      style={{
                                        fontSize: "0.9rem",
                                        marginTop: "30px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          errorExpand: !this.state.errorExpand,
                                        });
                                      }}
                                    >
                                      {this.state.errorExpand
                                        ? `SHOW LESS`
                                        : `SHOW DETAILS`}
                                    </p>
                                    {this.state.errorExpand ? (
                                      <p
                                        style={{
                                          backgroundColor: "#f8d4d4",
                                          borderColor: "#f5a6a7",
                                        }}
                                      >
                                        {this.createErrorHtml()}
                                        {/* {JSON.stringify(
                                          this.state.error.response
                                            ? this.state.error.response.data
                                            : this.state.error.message
                                        )} */}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                            {/* <Pagination metadata={this.state.documentCount} handlePageChange={this.handlePageChange} itemsPerPage={this.state.itemsPerPage} page={this.state.currentPage} customWidth={this.state.width} customHeight={this.state.height}/> */}
                          </>
                        ) : (
                          <div style={{ textAlign: "center", padding: "10%" }}>
                            <img
                              src={imsLoader}
                              alt="Loading Data"
                              style={{ width: "10%" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                  <Footer
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                </div>
                {this.state.globalEmailPopUp && (
                  <GlobalSearchFeaturePopUpModal
                    showModal={this.state.globalEmailPopUp}
                    showEmailModal={true}
                    handleClose={this.handleEmailPopUpClose}
                    selectedResults={this.state.selectedResults}
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                )}
                {this.state.globalNotifyMePopUp && (
                  <NotifyMePopUp
                    showPersonalAlerts={this.state.globalNotifyMePopUp}
                    handleClose={this.handleNotifyMePopUpClose}
                    customWidth={this.state.width}
                  />
                )}
                {this.state.bookmarkPopUp && (
                  <BookmarkPopUp
                    showModal={this.state.bookmarkPopUp}
                    handleClose={this.handleCloseBookmark}
                    customWidth={this.state.width}
                  />
                )}
                {this.state.readingListPopup && (
                  <ReadingListPopup
                    showModal={this.state.readingListPopup}
                    handleClose={this.handleCloseReadingList}
                    width={this.state.width}
                    selectedResults={this.state.selectedResults}
                  />
                )}
              </>
            ) : (
              <div style={{ textAlign: "center", padding: "10%" }}>
                <img
                  src={imsLoader}
                  alt="Loading Data"
                  style={{ width: "20%" }}
                />
              </div>
            )}
            {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
          </ErrorBoundary>
        </div>
      );
    } else if (this.state.width >= 600 && this.state.width <= 1000) {
      return (
        <div>
          <ErrorBoundary>
            {this.state.userExists ? (
              <>
                <div className="resultsInnerWrapper">
                  <NavigationMenu
                    pathName={window.location.pathname}
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                  <div className="header-wrapper">
                    <HeaderSearch
                      history={this.props.history}
                      dropdownValue={category}
                      searchKeyword={query}
                      appliedFilters={this.state.appliedFilters}
                      handleChangeInSearch={this.handleChangeInSearch}
                      handleClearSearch={this.handleClearSearch}
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                      isCitationSelected={this.state.citationSelectStatus}
                      isClearSearch={this.state.isClearSearch}
                      csPath={this.state.csPath}
                      csproduct={this.state.csproduct}
                    />
                  </div>
                  {this.state.error === "" ? (
                    <ResponsiveFilterComponent
                      pathName={window.location.pathname}
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                      allFilters={this.state.allFilters}
                      filters={this.state.filters}
                      appliedFilters={this.state.appliedFilters}
                      handleOnChangeInfilter={this.handleOnChangeInfilter}
                      selectedParentFilterArr={
                        this.state.selectedParentFilterArr
                      }
                      handleChangeInSearch={this.handleChangeInSearch}
                      selectedFilters={this.state.selectedFilters}
                      querytext={this.state.searchKeyword}
                      documentCount={this.state.documentCount}
                      response={this.state.response}
                      isProcessing={this.state.isProcessing}
                      handelSelectedCitation={this.getSelectedCitationDetails}
                      handleCollapseAllGlobal={this.handleCollapseAllGlobal}
                      globalCollapseAll={this.state.globalCollapseAll}
                      collapseFilterRes={this.state.collapseFilter}
                      globalSelectAll={this.state.globalSelectAll}
                      handleSelectAll={this.handleSelectAll}
                      handleGlobalEmailPopUp={this.handleGlobalEmailPopUp}
                      handleGlobalNotifyMePopUp={this.handleGlobalNotifyMePopUp}
                      handleGlobalBookmark={this.handleGlobalBookmark}
                      handleOnClickReadingList={this.handleOnClickReadingList}
                      csPath={this.state.csPath}
                      csproduct={this.state.csproduct}
                      recentlyUsedFilters={this.state.recentlyUsedFilters}
                      fromRecentlyAddedUpdated={
                        this.state.fromRecentlyAddedUpdated
                      }
                      toRecentlyAddedUpdated={this.state.toRecentlyAddedUpdated}
                      gotoPageRecentlyAddedUpdated={
                        this.gotoPageRecentlyAddedUpdated
                      }
                    />
                  ) : (
                    <ResponsiveFilterComponent
                      pathName={window.location.pathname}
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                      allFilters={[]}
                      filters={[]}
                      appliedFilters={[]}
                      handleOnChangeInfilter={this.handleOnChangeInfilter}
                      selectedParentFilterArr={[]}
                      handleChangeInSearch={this.handleChangeInSearch}
                      selectedFilters={[]}
                      querytext={this.state.searchKeyword}
                      documentCount={0}
                      response={[]}
                      isProcessing={this.state.isProcessing}
                      handelSelectedCitation={this.getSelectedCitationDetails}
                      handleCollapseAllGlobal={this.handleCollapseAllGlobal}
                      globalCollapseAll={this.state.globalCollapseAll}
                      collapseFilterRes={this.state.collapseFilter}
                      globalSelectAll={this.state.globalSelectAll}
                      handleSelectAll={this.handleSelectAll}
                      handleGlobalEmailPopUp={this.handleGlobalEmailPopUp}
                      handleGlobalNotifyMePopUp={this.handleGlobalNotifyMePopUp}
                      handleGlobalBookmark={this.handleGlobalBookmark}
                      handleOnClickReadingList={this.handleOnClickReadingList}
                      csPath={this.state.csPath}
                      csproduct={this.state.csproduct}
                      recentlyUsedFilters={[]}
                      fromRecentlyAddedUpdated={[]}
                      toRecentlyAddedUpdated={[]}
                      gotoPageRecentlyAddedUpdated={
                        this.gotoPageRecentlyAddedUpdated
                      }
                    />
                  )}
                  <div className="results-contents-wrapper">
                    <div className="imsResultsMenuWrapper-tablet">
                      <div className="imsResultsMenuHeader-tablet">
                        <span className="imsResultsMenuHeader-tablet-active">
                          iMS Search Results
                        </span>
                        <span
                          className="pubmedResultsMenuHeader-tablet pubmedResultsMenuHeader-tablet-deactive"
                          onClick={(e) => this.handleOnPubMedTabClick()}
                        >
                          PubMed Search Results
                        </span>
                      </div>
                      <div className="resultsContentWrapper resultsContentWrapper-tablet">
                        {this.showFieldedSearch()}
                        {this.showContentShortcutsSearch()}
                        {this.state.appliedFilters.length > 0 ? (
                          <FilterDisplay
                            allFilters={this.state.allFilters}
                            appliedFilters={this.state.appliedFilters}
                            handleClearFilters={this.handleClearFilters}
                            handleRemoveAppliedFilter={
                              this.handleRemoveAppliedFilter
                            }
                            customWidth={this.state.width}
                          />
                        ) : null}
                        {this.state.isLoaded ? (
                          <>
                            {/* {this.state.response.length > 0 &&
                                                    <> */}
                            {this.state.error === ""
                              ? this.resultListCount(
                                this.state.itemsPerPage,
                                this.state.currentPage
                              )
                              : ""}
                            {this.state.cskpSearchData.length > 0 &&
                              this.state.response.length > 0 && (
                                <div
                                  class="results-count-wrapper"
                                  style={{
                                    display: "block",
                                    margin: "0.5% 0 0 0",
                                  }}
                                >
                                  <div className="clinicalStudies-container">
                                    <KnowledgePanelData
                                      searchKeyword={this.state.searchKeyword}
                                      searchTypeRes={this.state.cskpSearchData}
                                      appliedFilters={this.state.appliedFilters}
                                    />
                                  </div>
                                </div>
                              )}
                            {/* </>
                                                } */}
                            <div className="results-wrapper">
                              {/* {this.resultList(
                                this.state.response,
                                this.state.itemsPerPage,
                                this.state.currentPage
                              )} */}
                              {this.state.error === "" ? (
                                this.resultList(
                                  this.state.response,
                                  this.state.itemsPerPage,
                                  this.state.currentPage
                                )
                              ) : (
                                <>
                                  {(() => {
                                    let service = new Services();
                                    service.logService(
                                      this.state.error.stack,
                                      "error"
                                    );
                                  })()}

                                  <div style={{ margin: "2rem 2rem" }}>
                                    <h2>Sorry, something went wrong</h2>
                                    <p
                                      style={{
                                        fontSize: "0.9rem",
                                        marginTop: "30px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          errorExpand: !this.state.errorExpand,
                                        });
                                      }}
                                    >
                                      {this.state.errorExpand
                                        ? `SHOW LESS`
                                        : `SHOW DETAILS`}
                                    </p>
                                    {this.state.errorExpand ? (
                                      <p
                                        style={{
                                          backgroundColor: "#f8d4d4",
                                          borderColor: "#f5a6a7",
                                        }}
                                      >
                                        {this.createErrorHtml()}
                                        {/* {JSON.stringify(
                                          this.state.error.response
                                            ? this.state.error.response.data
                                            : this.state.error.message
                                        )} */}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                            {this.state.error === "" ? (
                              <Pagination
                                metadata={this.state.documentCount}
                                handlePageChange={this.handlePageChange}
                                itemsPerPage={this.state.itemsPerPage}
                                page={this.state.currentPage}
                                customWidth={this.state.width}
                                customHeight={this.state.height}
                              />
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <div style={{ textAlign: "center", padding: "10%" }}>
                            <img
                              src={imsLoader}
                              alt="Loading Data"
                              style={{ width: "10%" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                  <Footer
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                </div>
                {this.state.globalEmailPopUp && (
                  <GlobalSearchFeaturePopUpModal
                    showModal={this.state.globalEmailPopUp}
                    showEmailModal={true}
                    handleClose={this.handleEmailPopUpClose}
                    selectedResults={this.state.selectedResults}
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                )}
                {this.state.globalNotifyMePopUp && (
                  <NotifyMePopUp
                    showPersonalAlerts={this.state.globalNotifyMePopUp}
                    handleClose={this.handleNotifyMePopUpClose}
                    customWidth={this.state.width}
                  />
                )}
                {this.state.bookmarkPopUp && (
                  <BookmarkPopUp
                    showModal={this.state.bookmarkPopUp}
                    handleClose={this.handleCloseBookmark}
                    customWidth={this.state.width}
                  />
                )}
                {this.state.readingListPopup && (
                  <ReadingListPopup
                    showModal={this.state.readingListPopup}
                    handleClose={this.handleCloseReadingList}
                    width={this.state.width}
                    selectedResults={this.state.selectedResults}
                  />
                )}
              </>
            ) : (
              <div style={{ textAlign: "center", padding: "10%" }}>
                <img
                  src={imsLoader}
                  alt="Loading Data"
                  style={{ width: "20%" }}
                />
              </div>
            )}
            {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
            {userAccessGroup.includes(Answers) && <ChatBotSection customWidth={this.state.width}
              customHeight={this.state.height} />}
          </ErrorBoundary>
        </div>
      );
    } else if (this.state.width > 1000) {
      return (
        <div>
          <ErrorBoundary>
            {this.state.userExists ? (
              <>
                <div className="resultsInnerWrapper">
                  <NavigationMenu
                    pathName={window.location.pathname}
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                  <div className="header-wrapper">
                    <HeaderSearch
                      history={this.props.history}
                      dropdownValue={category}
                      searchKeyword={query}
                      appliedFilters={this.state.appliedFilters}
                      handleChangeInSearch={this.handleChangeInSearch}
                      handleClearSearch={this.handleClearSearch}
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                      handelSelectedCitation={this.getSelectedCitationDetails}
                      isCitationSelected={this.state.selectedResults}
                      handleCollapseAllGlobal={this.handleCollapseAllGlobal}
                      globalCollapseAll={this.state.globalCollapseAll}
                      globalSelectAll={this.state.globalSelectAll}
                      handleSelectAll={this.handleSelectAll}
                      selectedResults={this.state.selectedResults}
                      csPath={this.state.csPath}
                      csproduct={this.state.csproduct}
                      documentsUrl={this.documentsUrl}
                      markedDocumentsUrl={this.markedDocumentsUrl}
                      itemsPerPage={this.state.itemsPerPage}
                    />
                  </div>
                  <div className="results-contents-wrapper">
                    <div
                      className={
                        this.state.showFilters
                          ? "filterMenuWrapper filterMenuWrapperExpanded"
                          : "filterMenuWrapper"
                      }
                      style={{ float: "left" }}
                    >
                      {this.state.showFilters ? (
                        <>
                          <div className="filterMenuHeader">
                            <span className="filter-menu-icon-wrapper">
                              <img src={filterIconWhite} alt="" />
                            </span>
                            <span>Filters</span>
                            <span
                              style={{ float: "right" }}
                              onClick={(e) =>
                                this.setState({
                                  showFilters: !this.state.showFilters,
                                })
                              }
                            >
                              <img src={collapseFilterWhite} alt="" />
                            </span>
                          </div>
                          <div className="filter-menu-content">
                            {this.state.isLoaded ? (
                              this.state.error === "" ? (
                                <SearchFilter
                                  allFilters={this.state.allFilters}
                                  filters={this.state.filters}
                                  appliedFilters={this.state.appliedFilters}
                                  handleOnChangeInfilter={
                                    this.handleOnChangeInfilter
                                  }
                                  selectedParentFilterArr={
                                    this.state.selectedParentFilterArr
                                  }
                                  handleChangeInSearch={
                                    this.handleChangeInSearch
                                  }
                                  selectedFilters={this.state.selectedFilters}
                                  querytext={this.state.searchKeyword}
                                  documentCount={this.state.documentCount}
                                  response={this.state.response}
                                  isProcessing={this.state.isProcessing}
                                  csPath={this.state.csPath}
                                  csproduct={this.state.csproduct}
                                  customWidth={this.state.width}
                                  recentlyUsedFilters={
                                    this.state.recentlyUsedFilters
                                  }
                                  fromRecentlyAddedUpdated={
                                    this.state.fromRecentlyAddedUpdated
                                  }
                                  toRecentlyAddedUpdated={
                                    this.state.toRecentlyAddedUpdated
                                  }
                                  gotoPageRecentlyAddedUpdated={
                                    this.gotoPageRecentlyAddedUpdated
                                  }
                                />
                              ) : (
                                <SearchFilter
                                  allFilters={[]}
                                  filters={[]}
                                  appliedFilters={[]}
                                  handleOnChangeInfilter={
                                    this.handleOnChangeInfilter
                                  }
                                  selectedParentFilterArr={[]}
                                  handleChangeInSearch={
                                    this.handleChangeInSearch
                                  }
                                  selectedFilters={[]}
                                  querytext={this.state.searchKeyword}
                                  documentCount={0}
                                  response={[]}
                                  isProcessing={this.state.isProcessing}
                                  csPath={this.state.csPath}
                                  csproduct={this.state.csproduct}
                                  customWidth={this.state.width}
                                  recentlyUsedFilters={[]}
                                  fromRecentlyAddedUpdated={[]}
                                  toRecentlyAddedUpdated={[]}
                                  gotoPageRecentlyAddedUpdated={
                                    this.gotoPageRecentlyAddedUpdated
                                  }
                                />
                              )
                            ) : (
                              <div
                                style={{ textAlign: "center", padding: "10%" }}
                              >
                                <img
                                  src={imsLoader}
                                  alt="Loading Filters"
                                  style={{ width: "20%" }}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="result-filter-expand-wrapper"
                            onClick={(e) =>
                              this.setState({
                                showFilters: !this.state.showFilters,
                              })
                            }
                          >
                            <img src={expandFilterWhite} alt="" />
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={
                        this.state.showFilters
                          ? "imsResultsMenuWrapper imsResultsMenuCollapse"
                          : "imsResultsMenuWrapper"
                      }
                      style={{ float: "right", borderBottom: "none" }}
                    >
                      <div className="imsResultsMenuHeader">
                        <span>iMS Search Results</span>
                      </div>
                      <div
                        className="pubmedResultsMenuHeader pubmedResultsMenuHeader-deactive"
                        onClick={(e) => this.handleOnPubMedTabClick()}
                      >
                        <span>PubMed Search Results</span>
                      </div>
                      {/* <div className="global-search-features-wrapper">
                                    <div className="clear-search-wrapper">
                                        <button className="clear-search-button" onClick={evt=>this.handleClearSearch()}>Clear Search</button>
                                    </div>    
                                </div>*/}
                      {/* this.state.searchKeyword != "" && this.state.showMajorTopicSearch &&
                                <div className="results-page-major-search-wrapper">
                                    <MajorTopic searchKeyword={this.state.searchKeyword} 
                                    majorTopicSearchState={this.state.majorTopicSearchState}
                                    dropdownValue={category}
                                    appliedFilters={this.state.appliedFilters}
                                    onClickRefiner={this.handleMajorTopicSearch}/>
                                </div>
                                 */}
                      <div className="resultsContentWrapper">
                        {this.showFieldedSearch()}
                        {this.showContentShortcutsSearch()}
                        {this.state.appliedFilters.length > 0 ? (
                          <FilterDisplay
                            allFilters={this.state.allFilters}
                            appliedFilters={this.state.appliedFilters}
                            handleClearFilters={this.handleClearFilters}
                            handleRemoveAppliedFilter={
                              this.handleRemoveAppliedFilter
                            }
                            customWidth={this.state.width}
                          />
                        ) : null}
                        {this.state.isLoaded ? (
                          <>
                            {this.state.response.length > 0 && (
                              <>
                                {this.state.error === ""
                                  ? this.resultListCount(
                                    this.state.itemsPerPage,
                                    this.state.currentPage
                                  )
                                  : ""}
                              </>
                            )}
                            <div className="results-wrapper">
                              {/* {this.resultList(
                                this.state.response,
                                this.state.itemsPerPage,
                                this.state.currentPage
                              )} */}
                              {this.state.error === "" ? (
                                this.resultList(
                                  this.state.response,
                                  this.state.itemsPerPage,
                                  this.state.currentPage
                                )
                              ) : (
                                <>
                                  {(() => {
                                    let service = new Services();
                                    service.logService(
                                      this.state.error.stack,
                                      "error"
                                    );
                                  })()}

                                  <div style={{ margin: "2rem 2rem" }}>
                                    <h2>Sorry, something went wrong</h2>
                                    <p
                                      style={{
                                        fontSize: "0.9rem",
                                        marginTop: "30px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          errorExpand: !this.state.errorExpand,
                                        });
                                      }}
                                    >
                                      {this.state.errorExpand
                                        ? `SHOW LESS`
                                        : `SHOW DETAILS`}
                                    </p>
                                    {this.state.errorExpand ? (
                                      <p
                                        style={{
                                          backgroundColor: "#f8d4d4",
                                          borderColor: "#f5a6a7",
                                        }}
                                      >
                                        {this.createErrorHtml()}
                                        {/* {JSON.stringify(
                                          this.state.error.response
                                            ? this.state.error.response.data
                                            : this.state.error.message
                                        )} */}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                            {this.state.error === "" ? (
                              <Pagination
                                metadata={this.state.documentCount}
                                handlePageChange={this.handlePageChange}
                                itemsPerPage={this.state.itemsPerPage}
                                page={this.state.currentPage}
                                customWidth={this.state.width}
                                customHeight={this.state.height}
                              />
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <div style={{ textAlign: "center", padding: "10%" }}>
                            <img
                              src={imsLoader}
                              alt="Loading Data"
                              style={{ width: "10%" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                  <Footer
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                </div>{" "}
              </>
            ) : (
              <div style={{ textAlign: "center", padding: "10%" }}>
                <img
                  src={imsLoader}
                  alt="Loading Data"
                  style={{ width: "20%" }}
                />
              </div>
            )}
            {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
            {userAccessGroup.includes(Answers) && <ChatBotSection customWidth={this.state.width}
              customHeight={this.state.height} />}
          </ErrorBoundary>
        </div>
      );
    }
  }
}
export default Results;
