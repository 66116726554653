import { useEffect, useState, useRef } from "react";
import sampleAudio from '../../assets/SampleAudioTest.mp3';
import '../../components/TTS/AudioContent.css';

import {
    PauseButton,
    PlayButton,
    SquareIcon,
    ExportBlueIcon, SaveIcon, FileBlueIcon, arrowIconBlue, SaveIconDeactivate, ExportDeactivate,
    ImportantIcon,ArrowRight,imsLoader
} from "../../assets";
import { useHistory, useParams } from "react-router-dom";
import ApiServices from "../../utils/apiServices";
import MainFolder from "../../components/TTS/MainFolder";
import TTSSidebar from "../../components/TTS/TTSSidebar";
import RightPanel from "../../components/TTS/RightPanel";
import axios from "axios";
import { data } from "jquery";
import SaveAsPopUp from "../../components/TTS/SaveAsPopUp";


const AudioContentPage = (props) => {
    //const [isPlaying ,setIspalying] = useState(false);
    const [trackProgress, setTrackProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const { id } = useParams();
    const [folderStructure, setFolderStructure] = useState([]);
    const [fileSelected, setFileSelected] = useState({});
    const [prevFolder, setPrevFolder] = useState([])

    const [legacyFolderData, setLegacyFolderData] = useState([]);
    const [legacyDocumentData, setLegacyDocumentData] = useState([]);
    const [audio, setAudio] = useState();

    const [displayNew, setDisplayNew] = useState(false);
    const [newTextFileSelected, setNewTextFileSelected] = useState(false);
    const [newLexionFileSelected, setNewLexiconFileSelected] = useState(false);
    const [textValue, setTextValue] = useState('');
    const [saveClicked , setSaveClicked] = useState(false);

    const [voiceSelected, SetVoiceSelected] = useState(false);
    const [lang ,setLang ] = useState('English (United States)');
    const [isVoiceChanged,setIsVoiceChanged] =useState(false);
    const [ voice, setVoice ] = useState('Jenny');
    const [rate, setRate] = useState(0);
    const [isRateSelected,setIsRateSelected] = useState(false)

    const history = useHistory();
    const audioSrc = useRef(new Audio(sampleAudio));
    const [onTextChangeAudio,setonTextChangeAudio] = useState(false);
    const [isTextFocused,setIsTextFocused] = useState(false);
    const [istextChange , setIsTextChange] = useState(false);
    const [loader,setLoader] = useState(false);
    const [saveAsPop,setSaveAsPopUp] = useState(false);
    
    useEffect(()=>{
    audioSrc.current = new Audio(audio);
    },[audio])
    
    useEffect(()=>{
     setFileSelected({});
     setPrevFolder([])
     setFolderStructure([]);
     setLegacyDocumentData([]);
     setLegacyFolderData([]);
     setAudio();
     fetchRes();
     setLoader(false);
     setIsPlaying(false);
     setIsRateSelected(false);
    }, [id])

   /*useEffect(()=>{
       if(istextChange && !isTextFocused){
        //fetchaudioFile(fileSelected,textValue)
        setonTextChangeAudio(false);
        setIsTextChange(false);
        /*if(audio){
            audioSrc.current = new Audio(audio);
        }
        return fetchaudioFile(fileSelected,textValue)
       }
    },[isPlaying])*/

    /*useEffect(() => {
        SetVoiceSelected(false)
        fetchRes();
    }, [])*/
    
    const intervalRef = useRef();
    const isReady = useRef(false);
    const { duration } = audioSrc.current;
    //---------------------function------------------
    const fetchRes = async () => {
        let service = new ApiServices();
        let res1 = await service.getAllDocDetails();
        let res2 = await service.getAllFolderDetails();
        let array1 = [];
        let array2 = [];
        let filterdoc, fol, filterFol
        if (res1) {
            setLegacyDocumentData(res1)
            filterdoc = res1?.filter((val) => {
                return val.docid == id
            });
            setFileSelected(filterdoc[0])
            setTextValue(filterdoc[0].doctext)
            setVoice(filterdoc[0].voice)
            setRate(0)
            fetchaudioFile(filterdoc[0],filterdoc[0].doctext,filterdoc[0].voice)
        }
        if (res2) {
            setLegacyFolderData(res2)
            fol = filterdoc[0].folderid
            if (fol === 0) {
                let array4 = ['All Files'].concat([filterdoc[0].doctitle]);
                setFolderStructure(array4)
            }
            else {
                filterFol = res2.filter((val) => {
                    return val.folderid === fol
                })
                array1 = array1.concat([filterdoc[0].doctitle]).concat([filterFol[0].foldername])
                array2 = array2.concat(filterFol[0].folderid)

                while (filterFol[0]?.folderid != filterFol[0]?.parentfolderid) {

                    let response = res2.filter((val) => {
                        return val.folderid === filterFol[0].parentfolderid
                    })
                    array1 = array1.concat(response[0].foldername)
                    array2 = array2.concat(response[0].folderid)
                    filterFol = response
                }
                let reverse = ["My Files"].concat(array1.reverse());
                let reverse1 = array2.reverse();
                setFolderStructure(reverse);
                setPrevFolder(reverse1);
            }

        }
    }
    //------------------DtaURI To Binary
    //--------------fetchAudio-------------//
    const fetchaudioFile = async (fileSelected, text , voice) => {
        let service = new ApiServices();
        let voice1;
        if(voice){
            voice1=voice
        }
        else{
            voice1 = 'Jenny'
            setVoice('Jenny')
        }
        setLoader(true);
        let rate1 ;
        if(typeof(rate) === "string"){
            rate1 = rate
        }
        else{
            rate1 = `${rate}%`
        }
        
        let res;
        /*let payload = {
            "doctitle": `${fileSelected1.doctitle}`,
            "doctext": `${fileSelected1.doctext}`,
            "ssml": `acutal ssml`,
            "folderid": `${fileSelected1.folderid}`,
            "datemodified": `${fileSelected1.datemodified}`,
            "tag": "",
            "filetype": "txt"
        }*/
            //console.log(rate)
            let payload = {
                "doctitle": `${fileSelected.doctitle}`,
                "doctext": `${text}`,
                "ssml": `<speak version=\"1.0\" xmlns=\"http://www.w3.org/2001/10/synthesis\" xml:lang=\"en-US\">\n                        <voice name=\"en-US-${voice1}Neural\">\n                           
                <prosody rate=\"${rate1}\">${text}</prosody>\n </voice>\n                    </speak>`,
                "folderid": `${fileSelected.folderid}`,
                "datemodified": `${fileSelected.datemodified}`,
                "tag": "tags"
              }
        await axios.post(
            `https://predev-ims.jnj.com/api/tts/ssml`,
            payload,
            {
              responseType: "blob",
            }
          ).then((response) => {
            const data = new Blob([response.data], { type: 'audio/wav' });
           const blobUrl = URL.createObjectURL(data);
            setAudio(blobUrl);
            setLoader(false)
           }).catch((error) => {
            console.log(error)
        })
    }
    
    const startTimer = () => {
        // Clear any timers already running
        clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
            if (audioSrc.current.ended) {
                setTrackProgress(0)
                setIsPlaying(false)
            } else {
                setTrackProgress(audioSrc.current.currentTime);
            }
        }, [1000]);
    };
    const onScrub = (value) => {
        // Clear any timers already running
        clearInterval(intervalRef.current);
        audioSrc.current.currentTime = value;
        setTrackProgress(audioSrc.current.currentTime);
    };

    const onScrubEnd = () => {
        // If not already playing, start
        if (!isPlaying) {
            setIsPlaying(true);
        }
        startTimer();
    };
    useEffect(() => {
        if (isPlaying ) {
            audioSrc.current.play();
            startTimer();
        } else {
            audioSrc.current.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        // Pause and clean up on unmount
        return () => {
            audioSrc.current.pause();
            clearInterval(intervalRef.current);
        };
    }, []);

    const onPlayClick = async(bool) => {
        //setIsPlaying(true);
       // await fetchRes();
       // await fetchaudioFile();
       if(istextChange && !isTextFocused){
        await fetchaudioFile(fileSelected,textValue,voice)
        setonTextChangeAudio(false);
        setIsTextChange(false);
    }
    if(isVoiceChanged){
        await fetchaudioFile(fileSelected,textValue,voice)
        setIsVoiceChanged(false)
    }
    if (isRateSelected){
        await fetchaudioFile(fileSelected,textValue,voice)
        setIsRateSelected(false)
    }
       ///------------------------------------
           setonTextChangeAudio(true);
            setIsPlaying(true);
            audioSrc.current.play();
    }
    const onPauseClick = (bool) => {
        setIsPlaying(false);
        audioSrc.current.pause();
    }
    const handleNewTextFile = () => {
        const doctext = '';
        setNewTextFileSelected(true)
        setTextValue('')
    }
    const handleLexiconFile = () => {
        setNewLexiconFileSelected(true)
    }
    const handleSaveAs = () =>{
        setSaveAsPopUp(true)
    }
    return (<>
        <div className="TTS-home-contents-wrapper" >
            <TTSSidebar />
            <div className="ms-Stack container-337" style={{ width: "100%" }}>
                <div className="fixedPaneContainer-162" style={{ width: "210px", height:"95vh" }}>
                    <div className="file-tree-panel-pnpi7ihwoh">
                        <span className="sc-dnqmqq jteVcr nav-callout-wrapper-i4cd0385ia">
                            <div>
                                <nav className="ms-Nav selected-root-parent root-300">
                                    <div className="ms-Nav-group is-expanded">
                                        <button className="ms-Nav-chevronButton chevronButton-304">
                                            <img className='Icon-482' src={arrowIconBlue}></img>
                                            All Files
                                        </button>
                                        <div className="ms-Nav-groupContent groupContent-310">
                                            <MainFolder
                                                legacyDocumentData={legacyDocumentData}
                                                legacyFolderData={legacyFolderData}
                                                fileSelected={fileSelected}
                                                prevFolder={prevFolder}
                                            />
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </span>
                    </div>
                </div>
                <div tabIndex="0" className="css-338"></div>
                <div className="ms-StackItem flexiblePane-340">
                    <div className="ms-StackItem css-262" style={{ height: "100%" }}>
                        <div className="ms-StackItem css-343">
                            <div className="banner"></div>
                            <div style={{ height: "37px", display: "flex" }}>{folderStructure.map((value,i) => {
                                return (
                                    (i !== folderStructure.length -1) ? <div className="container-top"><button className="button-123"
                                    onClick={()=>history.push(`/TTSHome`)}
                                    >{value}</button><span><img src={ArrowRight}/></span></div>:<div className="container-top"><button className="button-123"
                                    >{value}</button></div>
                                )
                            })}</div>
                            <div className="main wrapper">
                                <div className="ms-Stack editor-panel">
                                    <div className="tuning-command-bar-ur8d2yznta flex-row">
                                        <span class="button-bar">
                                            <div style={{ position: "relative" }}>
                                                <div className="ms-FocusZone">
                                                    <div className="ms-OverflowSet ms-CommandBar-primaryCommand primarySet-192">
                                                        <div className="ms-OverflowSet-item item-193">
                                                            <button style={{ backgroundColor: "white" }}
                                                                onClick={() => { setDisplayNew(true) }}
                                                                onMouseLeave={() => setDisplayNew(false)}>
                                                                <span className="ms-Button-flexContainer flexContainer-177">
                                                                    <img className="icon-345" src={FileBlueIcon}></img>
                                                                    <span className="ms-Button-textContainer textContainer-178">
                                                                        <span className="ms-Button-label label-346" id="id__334">File</span>
                                                                    </span>
                                                                    {(displayNew) ?
                                                                        <svg width="16px" height="14px" style={{ marginTop: "2px" }} viewBox="0 0 1024.00 1024.00" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#3276c3" transform="rotate(180)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="45.056"></g><g id="SVGRepo_iconCarrier"><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#3276c3"></path></g></svg>
                                                                        // <img src={arrowIconBlue} alt="" style={{ transform: "rotate(180deg)" }} />
                                                                        :
                                                                        <svg width="16px" height="14px" style={{ marginTop: "2px" }} viewBox="0 0 1024.00 1024.00" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#3276c3"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#3276c3"></path></g></svg>
                                                                        // <img src={arrowIconBlue} />
                                                                    }
                                                                </span>
                                                                {(displayNew) && <ul className="TSS-sub-wrapper-menu">
                                                                    <li onClick={() => handleNewTextFile()}>New File</li>
                                                                    <li onClick={() => { handleLexiconFile() }}>Lexion File</li>
                                                                </ul>}
                                                            </button>
                                                        </div>
                                                        <div className="ms-OverflowSet-item item-193">
                                                            <button disabled={(voiceSelected || isRateSelected) ? false : true} style={{ backgroundColor: "white" }}
                                                            onClick={()=>{setSaveClicked(true)}}
                                                            onMouseLeave={()=>{setSaveClicked(false)}}>
                                                                <span className="ms-Button-flexContainer flexContainer-177">
                                                                    {(voiceSelected || isRateSelected) ? <img className="icon-345" src={SaveIcon}></img>
                                                                        : <img className="icon-345" src={SaveIconDeactivate} />}
                                                                    <span className="ms-Button-textContainer textContainer-178">
                                                                        <span className="ms-Button-label label-346" id="id__334">Save
                                                                        </span>
                                                                        <span className="css-534" aria-hidden="true"></span>
                                                                    </span>
                                                                    <img className="" src={arrowIconBlue}></img>
                                                                </span>
                                                                {(saveClicked) && <ul className="TSS-sub-wrapper-menu">
                                                                    <li onClick={() => handleSaveAs()}>SaveAs</li>
                                                                </ul>}
                                                            </button>
                                                        </div>
                                                        <div className="ms-OverflowSet-item item-193">
                                                            <button disabled={(voiceSelected || isRateSelected) ? false : true} style={{ backgroundColor: "white" }}>
                                                                <span className="ms-Button-flexContainer flexContainer-177">
                                                                    {(voiceSelected || isRateSelected) ? <img className="icon-345" src={ExportBlueIcon}></img>
                                                                        : <img className="icon-345" src={ExportDeactivate} />}
                                                                    <span className="ms-Button-textContainer textContainer-178">
                                                                        <span className="ms-Button-label label-346" id="id__334">Export</span>
                                                                    </span>
                                                                    <img src=""></img>
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="ms-OverflowSet-item item-193">
                                                            <button style={{ backgroundColor: "white" }}>
                                                                <span className="ms-Button-flexContainer flexContainer-177">
                                                                    <img src=""></img>
                                                                    <span className="ms-Button-textContainer textContainer-178">
                                                                        <span className="ms-Button-label label-346" id="id__334">...</span>
                                                                    </span>
                                                                    <img src=""></img>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    {(loader)?
                                      <div style={{ textAlign: "center", marginBottom: "10px" }}>
                                     <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                                      </div>:<>
                                    <div className="audio-player">
                                        <div className="audio-player-wrapper">
                                            <div className="synthesis-audio-player-container"
                                                style={{ display: "flex" }}>
                                                {isPlaying ? (
                                                    <button
                                                        type="button"
                                                        className="circlebutton"
                                                        onClick={() => onPauseClick(true)}
                                                        aria-label="Pause"
                                                    ><span className="flexContainer-177">
                                                            <img src={PauseButton} />
                                                        </span>
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="circlebutton"
                                                        onClick={() => onPlayClick(false)}
                                                        aria-label="Play"
                                                    >
                                                        <span className="flexContainer-177">
                                                            <img src={PlayButton} />
                                                        </span>
                                                    </button>
                                                )}
                                                <div style={{ display: "flex" }}>
                                                    <button
                                                        type="button"
                                                        className="circlebutton"
                                                        aria-label="Pause"
                                                        onClick={() => onPauseClick(false)}
                                                    >
                                                        <span className="flexContainer-177"><img src={SquareIcon} /></span>
                                                    </button>
                                                    < div className="play-bar-wrap">
                                                        <span>{(parseInt(trackProgress/60) < 10)?`0${parseInt(trackProgress / 60)}`:
                                                        `${parseInt(trackProgress/10)}`}{`:`}{(parseInt(trackProgress % 60)<10)?`0${parseInt(trackProgress % 60)}`:
                                                        `${parseInt(trackProgress % 60)}`}</span>
                                                        <input
                                                            type="range"
                                                            value={trackProgress}
                                                            step="1"
                                                            min="0"
                                                            max={duration ? duration : `${duration}`}
                                                            className="progress"
                                                            onChange={(e) => onScrub(e.target.value)}
                                                            onMouseUp={() => onScrubEnd()}
                                                            onKeyUp={() => onScrubEnd()}
                                                            style={{
                                                                backgroundColor: "#c7e0f4",
                                                                minWidth: "269px",
                                                                marginTop: "5px",
                                                                display: "inline-block"
                                                            }}
                                                        />
                                                        <span>{(duration) ? <>{(parseInt(duration/60) < 10) ?`0${parseInt(duration/60)}`:`${parseInt(duration/60)}`}
                                                        <>{`:`}</><>{(parseInt(duration % 60) < 10) ? `0${parseInt(duration % 60)}` : `${parseInt(duration % 60)}`}</>
                                                        </>: `00:00`}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-StackItem content-editor-wrapper css-393">
                                        <div className="ms-Stack container-394">
                                            <div className="ms-StackItem flexiblePane-396">
                                                <div className="content-editor theme-tuning multi-color">
                                                    <div className="editor-view-text notranslate">
                                                    <div className="voice-text">[ {voice} ]</div>
                                                        <textarea className='editor-view-text notranslate' style={{ width: "100%", minHeight: "220px" }} 
                                                        value={textValue} onChange={(e) => {
                                                            setTextValue(e.target.value)
                                                            setIsTextFocused(true)
                                                            setIsTextChange(true)
                                                        }} onBlur={()=>setIsTextFocused(false)}
                                                            ></textarea></div>
                                                </div>
                                            </div>
                                            <div tabindex="0" className="css-517"></div>
                                            <div className="fixedPaneContainer-162 consoleWrapper-164" >
                                                <div className="consoleToolbar-165">
                                            <div role="tablist" class="ms-FocusZone css-191 ms-Pivot root-504" data-focuszone-id="FocusZone903">
                                            <button type="button" 
                                            role="tab" aria-selected="true" id="Pivot902-Tab0" class="ms-Button ms-Button--action ms-Button--command ms-Pivot-link is-selected linkIsSelected-507" 
                                            name="SSML format checking" data-content="SSML format checking" data-is-focusable="true" tabindex="0">
                                                <span class="ms-Button-flexContainer flexContainer-320" data-automationid="splitbuttonprimary">
                                                    <span class="ms-Pivot-linkContent linkContent-431"
                                                    ><span class="ms-Pivot-text text-432"> SSML format checking</span>
                                                    </span>
                                                    </span>
                                                    </button>
                                                    </div>
                                                    {(!voiceSelected && !isRateSelected) && <div classname='format-checking-message-wrapper'>
                                                   <div className="check">
                                                    <div class="ms-MessageBar-content content-509">
                                                    <div class="ms-MessageBar-icon iconContainer-510" aria-hidden="true">
                                                       <img src={ImportantIcon}/></div>
                                                    <div class="ms-MessageBar-text text-512" id="MessageBar907" role="status" aria-live="polite">
                                                        <span class="ms-MessageBar-innerText innerText-513">
                                                            <span><div class="message-wrapper">
                                                                <span>Make sure you've selected voices and rate for the text.
                                                                </span>
                                                            </div>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    </div>
                                                    </div>
                                                </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div></>}
                                    <div className="tuning-footer-98cfl5vjew"><div class="counter"
                                    style={{display:"flex",justifyContent:"flex-end"}}><label>Total characters:<span></span></label><b><span>{textValue.length}</span> / 20,000</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <aside>
                            <div className="control-panel-cxz21dnd6r">
                            <div class="control-panel-cxz21dnd6r">
                                <RightPanel
                                setVoiceSelected = {SetVoiceSelected}
                                setlang ={setLang}
                                setIsVoiceChanged = {setIsVoiceChanged}
                                setVoice = {setVoice}
                                setIsRateSelected = {setIsRateSelected}
                                setRate = {setRate}
                                id={id}/>
                            </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
    {(saveAsPop) && <SaveAsPopUp
    showModal = {saveAsPop}
    handleClose = {()=>{setSaveAsPopUp(false)}}
    fileSelected ={fileSelected}
    textValue = {textValue}
    voice ={voice}
    rate = {rate}/>}
    </>)
}
export default AudioContentPage;