import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { dropdownArrowGray } from '../../assets';
import { LocationOrigin } from '../../utils/utilityServices';
import { Modal } from "react-bootstrap";
import axios from "axios";
import { imsLoader, GenAiDOCIcon, GenAiPDFIcon, GenAiPPTIcon } from '../../assets/index';
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";
import ApiServices from "../../utils/apiServices";
import threadingService from "../../services/threadingService";
import GenAIDocumentTypesFilters from './GenAIDocumentTypesFilters';
import ToggleSwitch from '../ToggleSwitch/index';
let _locationOrigin = LocationOrigin;
//_locationOrigin = 'https://predev-ims.jnj.com';
let ts = new threadingService();
class GenAIAskQuestion extends Component {
    constructor(props) {
        super(props);
        var guid = uuidv4();
        var threadGuid = uuidv4();
        this.state = {
            sessionID: guid,
            threadID: threadGuid,
            newThreadID: threadGuid,
            newQuestion: '',
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            attachedFiles: [],
            uploadFilePlaceHolder: "",
            uploadFilePlaceHolderTitle: "",
            isAttachedFile: false,
            attachedFilePopupCollapsed: false,
            customeKey: props.customeKey,
            isProcessing: false,
            updateAnyFile: false,
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
            newAttachedFiles: [],
            removedAttachedFileIndex: -1,
            spinnerMessage: '',
            isRedirectOnGenAISearch: false,
            selectedFilters: [],
            renderfilters: [],
            isComprehensive: false,
        }
    }
    async componentWillUnmount() {
        let { sessionID, isRedirectOnGenAISearch, attachedFiles } = this.state;
        if (!isRedirectOnGenAISearch && attachedFiles.length > 0) {
            let response = await ts.deleteIndexBySessionId(sessionID);
        }
    }
    successAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#00c12c",
            alertMessageShowModal: true
        })
    }
    errorAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#de1327",
            alertMessageShowModal: true
        })
    }
    closeAlertMessage = () => {
        this.setState({
            alertMessageContent: "",
            alertMessageModelColor: "",
            alertMessageShowModal: false,
            showPreview: false
        })
    }


    handleOnClickAttachedFile = () => {
        const { attachedFilePopupCollapsed } = this.state;
        this.setState({ attachedFilePopupCollapsed: !attachedFilePopupCollapsed })
    }

    snippetLongStringForKeyword = (snippet, strLength = 25) => {
        if (snippet.length > strLength) {
            let text = snippet.substring(0, strLength)//cuts to 70
            text = text + `...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }
    async fileInputChange(event) {
        const files = event.target.files;
        let { attachedFiles } = this.state;
        if ((attachedFiles.length + Object.keys(files).length) > 5) {
            this.errorAlertMessage("You can only upload a maximum of 5 files");
        } else {
            let _attachedFiles = [];
            let isUploadSuccessfully = true;
            for (var i = 0; i < Object.keys(files).length; i++) {
                let file = files[i];
                if (file.name.toLowerCase().endsWith('.pdf')) {
                    file.guid = uuidv4();
                    file.newFileName = file.name;
                    _attachedFiles.push(file);
                } else {
                    this.setState({ isProcessing: true, attachedFilePopupCollapsed: false });
                    let API_ENDPOINT = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}PreviewService/PDFMaker`;
                    if (file.name.toLowerCase().endsWith('.doc') || file.name.toLowerCase().endsWith('.docx')) {
                        API_ENDPOINT += "?fileType=Document"
                    } else {
                        API_ENDPOINT += "?fileType=Presentation"
                    }
                    const formData = new FormData();
                    formData.append('form_filed_name', file)
                    await axios.post(API_ENDPOINT, formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        },
                        responseType: "blob",
                    }).then((res) => {
                        let newFileName = file.name.split('.').slice(0, -1).join('.');
                        const fileName = `${newFileName}.pdf`;
                        const data = new Blob([res.data], { type: 'application/pdf' });
                        var newFile = new File([data], file.name, { type: 'application/pdf' });
                        newFile.guid = uuidv4();
                        newFile.newFileName = fileName;
                        _attachedFiles.push(newFile);
                    }).catch((error) => {
                        console.log(error);
                        isUploadSuccessfully = false;
                    });
                }
                if (!isUploadSuccessfully) {
                    break;
                }
            }
            if (!isUploadSuccessfully) {
                this.setState({ newAttachedFiles: [], removedAttachedFileIndex: -1, isProcessing: false }, () => {
                    this.errorAlertMessage("Upload failed due to some reason. Kindly try after some time.");
                });
            } else {
                var threadGuid = uuidv4();
                this.setState({ newAttachedFiles: _attachedFiles, newThreadID: threadGuid }, async () => {
                    await this.multipleFilesUpload();
                });
            }
        }
    }
    manageFilePlaceHolder() {
        let { attachedFiles } = this.state;
        let uploadFilePlaceHolder = '', uploadFilePlaceHolderTitle = '';
        if (attachedFiles.length > 0) {
            let singleFile = attachedFiles[0];
            uploadFilePlaceHolder = this.getAttachedFileName(singleFile);
            uploadFilePlaceHolderTitle = singleFile.name;
        }
        this.setState({ uploadFilePlaceHolder: uploadFilePlaceHolder, uploadFilePlaceHolderTitle: uploadFilePlaceHolderTitle, isAttachedFile: attachedFiles.length > 0 });
    }
    getAttachedFileName(singleFile) {
        let filename = singleFile.name.split('.').slice(0, -1).join('.');
        let fileExtention = singleFile.name.substring(singleFile.name.lastIndexOf('.') + 1, singleFile.name.length) || singleFile.name;
        filename = this.snippetLongStringForKeyword(filename);
        return `${filename} .${fileExtention}`;;
    }
    handleRemoveAllAttachedFile = async () => {
        this.setState({
            uploadFilePlaceHolder: "",
            uploadFilePlaceHolderTitle: "",
            attachedFiles: [],
            newAttachedFiles: [],
            isAttachedFile: false,
            attachedFilePopupCollapsed: false,
            newAttachedFiles: [],
            removedAttachedFileIndex: -1,
            updateAnyFile: false,
        }, async () => {
            let { sessionID } = this.state;
            let response = await ts.deleteIndexBySessionId(sessionID);
        })

    }
    handleRemoveAttachedFile = async (index) => {
        let { attachedFiles } = this.state;
        attachedFiles = attachedFiles.filter((file, fileIndex) => fileIndex !== index);
        if (attachedFiles.length > 0) {
            var threadGuid = uuidv4();
            this.setState({ removedAttachedFileIndex: index, newThreadID: threadGuid }, async () => {
                await this.multipleFilesUpload();
            });
        } else {
            this.setState({
                uploadFilePlaceHolder: "",
                uploadFilePlaceHolderTitle: "",
                attachedFiles: [],
                newAttachedFiles: [],
                isAttachedFile: false,
                attachedFilePopupCollapsed: false,
                newAttachedFiles: [],
                removedAttachedFileIndex: -1,
                updateAnyFile: false,
            }, async () => {
                let { sessionID } = this.state;
                let response = await ts.deleteIndexBySessionId(sessionID);
            })
        }
    }
    handleNewQuestion = (e) => {
        this.setState({ newQuestion: e.target.value })
    }
    multipleFilesUpload = async (e) => {
        this.setState({ isProcessing: true, attachedFilePopupCollapsed: false });
        let { attachedFiles, sessionID, updateAnyFile, newAttachedFiles, removedAttachedFileIndex, newThreadID } = this.state;
        var url = `${_locationOrigin}${process.env.REACT_APP_IMS_API_NLP_ANSWERS}multi_upload`
        if (window.location.origin === 'https://predev-ims.jnj.com') {
            url = `${window.location.origin}${process.env.REACT_APP_IMS_API_NLP_ANSWERS}multi_upload`
        }
        const formData = new FormData();
        attachedFiles.concat(newAttachedFiles).filter((file, fileIndex) => fileIndex !== removedAttachedFileIndex).map((item, index) => {
            formData.append(`file${index + 1}`, item);
            formData.append(`fileGuid${index + 1}`, item.guid);
        })
        formData.append(`fileCount`, attachedFiles.concat(newAttachedFiles).filter((file, fileIndex) => fileIndex !== removedAttachedFileIndex).length);
        formData.append(`sessionID`, sessionID);
        formData.append(`threadID`, newThreadID);
        formData.append(`username`, localStorage.getItem('username').toLowerCase().replace(/"/g, ''));
        formData.append(`updateAnyFile`, updateAnyFile);

        let config = {
            method: 'POST',
            url: url,
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        }
        await axios(config).then((response) => {
            this.setState({ updateAnyFile: true, attachedFiles: attachedFiles.concat(newAttachedFiles).filter((file, fileIndex) => fileIndex !== removedAttachedFileIndex), newAttachedFiles: [], removedAttachedFileIndex: -1, threadID: newThreadID, selectedFilters: [], renderfilters: [], isComprehensive: false, }, () => {
                this.manageFilePlaceHolder();
            });

        }).catch((error) => {
            this.setState({ newAttachedFiles: [], removedAttachedFileIndex: -1 }, () => {
                this.errorAlertMessage("Upload failed due to some reason. Kindly try after some time.");
            });

            console.log(error)
        }).finally(() => {
            this.setState({ isProcessing: false });
        });
    }
    handleKeyDown = async (event) => {
        if (['Enter'].includes(event.key) && !event.shiftKey && this.state.newQuestion && this.state.newQuestion.trim()) {
            event.preventDefault();
            event.returnValue = false;
            await this.handleOnClickSend();
        }
    }
    handleOnClickSend = async () => {

        let { sessionID, newQuestion, attachedFiles, threadID, selectedFilters, renderfilters, isComprehensive } = this.state;
        let data = {
            sessionID: sessionID,
            newQuestion: newQuestion,
            attachedFiles: attachedFiles,
            threadID: threadID,
            selectedFilters: selectedFilters,
            renderfilters: renderfilters,
            isComprehensive: isComprehensive
        }
        let { isOpenInModelPopup, collectionDetails } = this.props;
        if (!isOpenInModelPopup && collectionDetails) {
            this.setState({ spinnerMessage: "Please wait while finding answers.", isProcessing: true, isRedirectOnGenAISearch: true })
            let service = new ApiServices();
            let wwid = JSON.parse(localStorage.getItem("wwid"));
            await service.createCollection(
                wwid,
                collectionDetails.collection_id,
                collectionDetails.collection_name,
                sessionID
            );
            this.props.history.push({
                pathname: '/genAISearch',
                state: data // your data array of objects
            })
        }
        else {
            this.setState({ isRedirectOnGenAISearch: true }, () => {
                this.props.history.push({
                    pathname: '/genAISearch',
                    state: data // your data array of objects
                })
                if (this.props.handelCloseNewThreadModal) {
                    this.props.handelCloseNewThreadModal();
                }
            })

        }
    }

    closeAttachedFilePopupCollapsed = () => {
        this.setState({ attachedFilePopupCollapsed: false })

    }

    handleSelectedFilters = (selectedFilters) => {
        this.setState({
            selectedFilters: selectedFilters
        })
    }
    handleRenderfilters = (renderfilters) => {
        this.setState({
            renderfilters: renderfilters
        })
    }
    handleOnChangeToggleSwitch = (checked) => {
        this.setState({
            isComprehensive: checked
        })
    }
    renderFileIcon = (name) => {
        if (name.toLowerCase().endsWith('.pdf')) {
            return (<><img src={GenAiPDFIcon} /> &nbsp;</>)
        }
        else if (name.toLowerCase().endsWith('.doc') || name.toLowerCase().endsWith('.docx')) {
            return (<><img src={GenAiDOCIcon} /> &nbsp;</>)
        }
        else if (name.toLowerCase().endsWith('.ppt') || name.toLowerCase().endsWith('.pptx')) {
            return (<><img src={GenAiPPTIcon} /> &nbsp;</>)
        }
        else {
            return (<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="file" class="svg-inline--fa fa-file fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"></path></svg>)
        }
    }

    renderAttachedFile = () => {
        let { attachedFilePopupCollapsed, attachedFiles, customeKey } = this.state;
        if (attachedFilePopupCollapsed && attachedFiles.length > 0) {
            return (<div className={attachedFilePopupCollapsed ? "gen-ai-answering-main-attachedfile-wrapper gen-ai-answering-main-attachedfile-open" : "gen-ai-answering-main-attachedfile-wrapper gen-ai-answering-main-attachedfile-close"}>
                <div className="gen-ai-answering-main-attachedfile-header">
                    <div className="gen-ai-answering-main-attachedfile-header-title">
                        Attached Files
                    </div>
                    <div className="gen-ai-answering-main-attachedfile-header-controle">
                        {attachedFiles.length < 5 &&
                            <>
                                <label for={`${customeKey}-answeringAttachedfile`} style={{ cursor: "pointer" }} className="gen-ai-answering-main-attachedfile-header-controle-add">
                                    <div className="flex items-center leading-none justify-center gap-xs">
                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" className="svg-inline--fa fa-plus fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path fill="currentColor" d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"></path>
                                        </svg>
                                        <div class="text-align-center relative">Add</div>
                                    </div>
                                </label>
                                <input type="file" key={`${customeKey}${Math.random().toString()}`} name={`${customeKey}-answeringAttachedfile`} id={`${customeKey}-answeringAttachedfile`} multiple accept=".doc,.docx,.ppt,.pptx,.pdf" onChange={e => { this.fileInputChange(e) }} style={{ display: "none" }} />
                                &nbsp;
                            </>}
                        <button type="button" class="gen-ai-answering-main-attachedfile-header-controle-delete" onClick={e => this.handleRemoveAllAttachedFile()}>
                            <div class="flex items-center leading-none justify-center gap-xs">
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash" className="svg-inline--fa fa-trash fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M177.1 48h93.7c2.7 0 5.2 1.3 6.7 3.6l19 28.4h-145l19-28.4c1.5-2.2 4-3.6 6.7-3.6zM354.2 80L317.5 24.9C307.1 9.4 289.6 0 270.9 0H177.1c-18.7 0-36.2 9.4-46.6 24.9L93.8 80H80.1 32 24C10.7 80 0 90.7 0 104s10.7 24 24 24H35.6L59.6 452.7c2.5 33.4 30.3 59.3 63.8 59.3H324.6c33.5 0 61.3-25.9 63.8-59.3L412.4 128H424c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8H367.9 354.2zm10.1 48L340.5 449.2c-.6 8.4-7.6 14.8-16 14.8H123.4c-8.4 0-15.3-6.5-16-14.8L83.7 128H364.3z"></path>
                                </svg>
                                <div class="text-align-center relative">Clear</div>
                            </div>
                        </button>
                    </div>
                </div>
                <div className="gen-ai-answering-main-attachedfile-items">
                    <div className="gen-ai-answering-main-attachedfile-item-wrapper">
                        {
                            attachedFiles.map((fileItem, fileIndex) => {
                                return (<div className="gen-ai-answering-main-attachedfile-item" key={`gen-ai-answering-main-attachedfile-${customeKey}${Math.random().toString()}-${fileIndex}`} title={fileItem.name}>
                                    <span className="gen-ai-answering-main-attachedfile-item-span">
                                        {this.renderFileIcon(fileItem.name)}
                                        {this.getAttachedFileName(fileItem)}
                                    </span>
                                    <button style={{ background: "none" }} title="Clear Attached" onClick={e => this.handleRemoveAttachedFile(fileIndex)}>
                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="xmark" class="svg-inline--fa fa-xmark fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                            <path fill="currentColor" d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"></path></svg>
                                    </button>
                                </div>)
                            })
                        }
                    </div>
                </div>
            </div>)
        }
        else {
            return (<></>);
        }


    }

    render() {
        let { newQuestion, uploadFilePlaceHolder, spinnerMessage, uploadFilePlaceHolderTitle, customeKey, attachedFiles } = this.state;
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
        let { selectedFilters, renderfilters, isComprehensive } = this.state;
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<div className="gen-ai-answering-main-ask-question-wrapper">
                <div className="gen-ai-answering-main-ask-question">
                    <textarea name="citationText" value={newQuestion} onKeyDown={this.handleKeyDown} onClick={(e) => { this.closeAttachedFilePopupCollapsed() }} placeholder="Ask a question…" onChange={(e) => this.handleNewQuestion(e)} />
                    <div className="gen-ai-answering-main-action-section">
                        <div class="gen-ai-answering-main-action-section-dropdown-wrapper">
                            {(!uploadFilePlaceHolder) ? <React.Fragment>
                                <div className="gen-ai-answering-main-action-section-file">
                                    <input type="file" key={`${customeKey}${Math.random().toString()}`} name={`${customeKey}-gen-ai-answering-main-action-section-file`} id={`${customeKey}-gen-ai-answering-main-action-section-file`} multiple accept=".doc,.docx,.ppt,.pptx,.pdf" onChange={e => { this.fileInputChange(e) }} style={{ display: "none" }} />
                                    <label for={`${customeKey}-gen-ai-answering-main-action-section-file`} style={{ cursor: "pointer" }}>
                                        <svg width="30px" height="30px" viewBox="0 0 64.00 64.00" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#ffffff" stroke="#ffffff" stroke-width="0.00064">
                                            <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
                                                <rect x="0" y="0" width="64.00" height="64.00" rx="32" fill="#2a498a" strokewidth="0"></rect>
                                            </g>
                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.384"></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2zm5.143 28.305V49H26.857V30.305H16L32 15l16 15.305H37.143z" fill="#ffffff"></path>
                                            </g>
                                        </svg> <b>Upload Documents</b>
                                    </label>
                                </div></React.Fragment>
                                : <React.Fragment>
                                    <div className="gen-ai-answering-main-action-section-listfile">
                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="file" class="svg-inline--fa fa-file fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"></path></svg>
                                        <label className="gen-ai-answering-main-action-section-upload" title={uploadFilePlaceHolderTitle} style={{ cursor: "pointer" }} onClick={() => this.handleOnClickAttachedFile()}>
                                            {uploadFilePlaceHolder}</label>
                                    </div>
                                </React.Fragment>}
                            {/* {attachedFiles.length === 0 && <div className="gen-ai-answering-main-action-section-comprehensive"><b>Comprehensive Answer</b>&nbsp;<ToggleSwitch customKey={`gen-ai-ask-toggle-${this.props.conversationIndex}`} isComprehensive={isComprehensive} handleOnChangeToggleSwitch={this.handleOnChangeToggleSwitch} /></div>} */}
                        </div>
                        <div className="gen-ai-answering-main-action-section-send-wrrap">
                            <button type="button" disabled={!newQuestion} className="gen-ai-answering-main-action-section-send" onClick={(e) =>
                                this.handleOnClickSend()}>
                                <svg fill="#ffffff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="-11.19 -11.19 52.62 52.62" stroke="#ffffff">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0">
                                        <rect x="-11.19" y="-11.19" width="52.62" height="52.62" rx="26.31" fill={!newQuestion ? "#cecece" : "#2a498a"} strokewidth="0"></rect>
                                    </g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <g>
                                            <path d="M20.194,3.46c-4.613-4.613-12.121-4.613-16.734,0c-4.612,4.614-4.612,12.121,0,16.735 c4.108,4.107,10.506,4.547,15.116,1.34c0.097,0.459,0.319,0.897,0.676,1.254l6.718,6.718c0.979,0.977,2.561,0.977,3.535,0 c0.978-0.978,0.978-2.56,0-3.535l-6.718-6.72c-0.355-0.354-0.794-0.577-1.253-0.674C24.743,13.967,24.303,7.57,20.194,3.46z M18.073,18.074c-3.444,3.444-9.049,3.444-12.492,0c-3.442-3.444-3.442-9.048,0-12.492c3.443-3.443,9.048-3.443,12.492,0 C21.517,9.026,21.517,14.63,18.073,18.074z"></path>
                                        </g>
                                    </g>
                                </svg>
                                {/* <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M440.6 273.4c4.7-4.5 7.4-10.8 7.4-17.4s-2.7-12.8-7.4-17.4l-176-168c-9.6-9.2-24.8-8.8-33.9 .8s-8.8 24.8 .8 33.9L364.1 232 24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l340.1 0L231.4 406.6c-9.6 9.2-9.9 24.3-.8 33.9s24.3 9.9 33.9 .8l176-168z"></path>
                                </svg> */}
                            </button>
                        </div>
                    </div>
                </div>
                {this.renderAttachedFile()}
                {attachedFiles.length === 0 && <GenAIDocumentTypesFilters
                    handleSelectedFilters={this.handleSelectedFilters}
                    handleRenderfilters={this.handleRenderfilters}
                    selectedFilters={selectedFilters}
                    renderfilters={renderfilters}
                    customHeight={this.state.customHeight} customWidth={this.state.customWidth}
                    isOpenInPopup={false} />
                }
                {this.state.isProcessing &&
                    <Modal
                        show={this.state.isProcessing}
                        size="lg md sm xs"
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName="savePopUp"
                        className="document-processing-newthread"
                        backdropClassName="document-processing-newthread-backdrop"
                        scrollable={true}>
                        <div style={{ padding: "0 5%" }}>
                            <div style={{ fontSize: "14px", textAlign: "center" }}>
                                <Modal.Body>
                                    <img src={imsLoader} alt="" width={100} height={100} />
                                    <div className="saveModal-heading" style={{ color: "#0074B0" }}>{spinnerMessage ? spinnerMessage : `Please wait while uploading the document.`}</div>
                                </Modal.Body>
                            </div>
                        </div>
                    </Modal>}
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
            </div>)
        }
    }

}
export default withRouter(GenAIAskQuestion);