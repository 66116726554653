import './MainFolder.css';
import { arrowIconBlue, folderIcon, FileIcon } from "../../assets";
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { useHistory } from "react-router-dom";

const MainFolder = (props) => {
    const legacyDocumentData = props.legacyDocumentData;
    const legacyFolderData = props.legacyFolderData;
    const fileSelected = props.fileSelected;
    const [prevFolderName, setPrevFolderName] = useState([]);
    const [prevFolder,setPrevFolder] = useState([])
    const [allFile, setAllFile] = useState([])
    const [expandedItems, setExpandedItems] = useState(prevFolder);
    let history = useHistory();

    useEffect(() => {
        let filterAllFile = legacyDocumentData.filter((val) => {
            return val.folderid === 0
        })
        let filterAllFolder = legacyFolderData.filter((val) => {
            return val.folderid === val.parentfolderid
        })
        let all = filterAllFolder.concat(filterAllFile);
        setAllFile(all)
        let prevFolderName1 = []
        setPrevFolder(props.prevFolder)
        setExpandedItems(props.prevFolder)
        let data = props.prevFolder;
        for (let i = 0; i < data.length; i++) {
            legacyFolderData.forEach((ele) => {
                if (ele.folderid === data[i])
                    prevFolderName1 = prevFolderName1.concat(ele)
            })
        }
        prevFolderName1 = prevFolderName1.concat([fileSelected])
        //let prevFolderName1 = prevFolderName.reverse()
        let start = [], end = [], index1;
        let data1 = [];
        let data9 = [fileSelected];
        let data10 = [];
        let data11 = [];

        let res = filterAllFolder.concat(filterAllFile);
        //-------------------start-folder----------------------//
        for (let i = 0; i < prevFolderName1.length; i++) {

            res.forEach((element, index) => {
                if (element.folderid == prevFolderName1[i].folderid) {
                    index1 = index
                }
                start = res.slice(0, index1 + 1)
                end = res.slice(index1 + 1, res.length + 1)
            });
            //-------------------start -end block end-------------///
            /*let data3 = legacyDocumentData.filter((val) => {
                return val.folderid === prevFolderName1[i].folderid
            })
            let data4 = legacyFolderData.filter((val) => {
                return val.parentfolderid === prevFolderName1[i].folderid && val.parentfolderid !== val.folderid
            })
            data1 = data1.concat(data4).concat(data3)*/
        }let indi, forwardFolder = [], forwardFile =[],backwardFolder = [],backwardFile = []
        for (let i = 1; i < prevFolderName1.length ; i++) {
            let data3 ,value1=[],value2=[], value4=[];
            let value3;
            if(  prevFolderName1[i].foldername){
                 value1 = legacyFolderData.filter((val)=>{
                    return prevFolderName1[i].parentfolderid === val.parentfolderid && val.parentfolderid !== val.folderid
                })
                 value2 = legacyDocumentData.filter((val)=>{
                    return prevFolderName1[i].parentfolderid === val.folderid && val.docid > 0
                })
            }
            
            
            if(i == prevFolderName1 .length - 1 ){
                 value3 = legacyFolderData.filter((val)=>{
                    return (val.parentfolderid === prevFolderName1[i].folderid && val.parentfolderid !== val.folderid)})
                    value4 = legacyDocumentData.filter((val)=>{
                        return (val.folderid === prevFolderName1[i].folderid)
                    })
            }
            if(value3?.length > 0){
                value1 = value1.concat(value3)
            }
            if(value4?.length > 0){
                value1= value1.concat(value4)
            }

            for(let j = 0 ; j < value1.length ; j++){
                if(value1[j].folderid == prevFolderName1[i].folderid){
                    indi = j;
                }
               
            }
            //indi = value1.indexOf(prevFolderName1[i+1]);
            if(indi > -1){
                forwardFolder = forwardFolder.concat(value1.slice(0,indi+1));
                forwardFile = forwardFile.concat(value2.slice(0,indi+1));
                backwardFolder = backwardFolder.concat(value1.slice(indi+1,value1.length));
                backwardFolder = backwardFolder.reverse()
                backwardFile = backwardFile.concat(value2.slice(indi+1, value2.length));  
            }
        }
        let backfol = backwardFolder.reverse();
        let backfile = backwardFile.reverse();
        /*if(indi > -1){
                data10 = data10.slice(0,indi+1)}
            }*/
        //------------------end portion-----------////
        /*for (let i = prevFolderName1.length - 1; 0 <= i; i--) {
            if (prevFolderName1[i].parentfolderid !== prevFolderName1[i].folderid) {
                let data5 = legacyDocumentData.filter((val) => {
                    return val.folderid === prevFolderName1[i].parentfolderid
                })
                let data6 = legacyFolderData.filter((val) => {
                    return val.parentfolderid === prevFolderName1[i].parentfolderid && val.parentfolderid !== val.folderid
                })
                data9 = data9.concat(data6).concat(data5)
                const index = data9.indexOf(prevFolderName1[i]);
                if (index > -1) { // only splice array when item is found
                    data9.splice(index, 1);
                }
            }
        }*/
        //---------------end-portion-----------------///

        //setPrevFolderName(final)
        /*const result = Object.groupBy(data1, (data1) => (data1.folderid));
        */
        let final
        if(prevFolderName1?.length == 1){
            final = start
        }else{
            final = start.concat(forwardFolder).concat(forwardFile).concat(backfol).concat(backfile).concat(end);
        }
        setPrevFolderName(final)

    }, [legacyDocumentData, legacyFolderData, props.prevFolder])
    const handleEvent = (e) =>{
        
    }
    const handleExpandedItemsChange = (event, itemIds) => {
        setExpandedItems(itemIds);
      };
    
    //--------------------------------function------------------
    const printdata = (allFile) => {
        let i = 0;
        let array = prevFolder ?? '';
        return (
            allFile.map((data,index) => {
                return(
                <SimpleTreeView classes={'ms-Nav-navItem navItem-308'}
                expandedItems={expandedItems}
                onExpandedItemsChange={handleExpandedItemsChange}>
                    
                    {(data.parentfolderid === data.folderid && data.folderid !== prevFolder[i]) && 
                    <div className={(fileSelected.docid === data.docid) ? 'ms-Nav-compositeLink compositeLink-304-selected' : 'ms-Nav-compositeLink compositeLink-304'}><span><img src={folderIcon} /></span>
                    <TreeItem itemId={data.folderid} label={data.foldername}style={{fontSize:"14px",fontWeight:"400"}}>{printSubFolderOfAll(data)}</TreeItem></div>}
                    
                    {(data.folderid === 0 && data.docid > -1) && 
                    <div className={(fileSelected.docid === data.docid) ? 'ms-Nav-compositeLink compositeLink-304-selected' : 'ms-Nav-compositeLink compositeLink-304'}><img src={FileIcon} />
                    <TreeItem onClick={() => history.push(`/TTSAudioContentPage/${data.docid}`)} itemId={index} label={data.doctitle}style={{fontSize:"14px",fontWeight:"400"}}></TreeItem></div>}
                    
                    {(data.folderid === prevFolder[i] && data.foldername?.length >0) && 
                    <div className={(fileSelected.docid === data.docid) ? 'ms-Nav-compositeLink compositeLink-304-selected' : 'ms-Nav-compositeLink compositeLink-304'}><span><img src={folderIcon} /></span>
                    <TreeItem itemId={data.folderid} label={data.foldername} style={{fontSize:"14px",fontWeight:"400"}} 
                     >{printSubFolder(i+1)}</TreeItem></div>}
                
                </SimpleTreeView>)
                }))
            }
            const printSubFolderOfAll =(val)=>{
                const resAll = legacyFolderData.concat(legacyDocumentData)
                return(
                   resAll.map((value1,i)=>{
                        if(value1.parentfolderid === val.folderid && value1.folderid !== value1.parentfolderid){
                            return <div className={(fileSelected.docid === value1.docid) ? 'ms-Nav-compositeLink compositeLink-304-selected' : 'ms-Nav-compositeLink compositeLink-304'}><span><img src={folderIcon} /></span>
                            <TreeItem itemId={i+value1.folderid} label ={value1.foldername} style={{fontSize:"14px",fontWeight:"400"}}>{printSubFolderOfAll(value1)}</TreeItem></div>
                        }
                        else if(value1.folderid === val.folderid && value1?.docid > -1){
                            return<div className={(fileSelected.docid === value1.docid) ? 'ms-Nav-compositeLink compositeLink-304-selected' : 'ms-Nav-compositeLink compositeLink-304'}><span><img src={FileIcon} /></span>
                             <TreeItem onClick={()=>history.push(`/TTSAudioContentPage/${value1.docid}`)} itemId={i+value1.docid} label = {value1.doctitle} style={{fontSize:"14px",fontWeight:"400"}}></TreeItem></div>
                        }
                    })
                )
            }
            const printSubFolder1 = (i) => {
                if(i <= prevFolder.length){
                    return(
                        prevFolderName.map((val,index)=>{
                          if(val.parentfolderid === prevFolder[i-1] && val.parentfolderid !== val.folderid && val.folderid !==prevFolder[i]&& val.foldername?.length > 0){
                               return <TreeItem itemId={(i+index)} label={val.foldername}></TreeItem>
                            }
                            else if(val.folderid === prevFolder[i] && val.foldername?.length > 0){
                                return <TreeItem itemId={(i+index)} label={val.foldername}>{printSubFolder(i+1)}</TreeItem>
                            }
                            else if(val.folderid === prevFolder[i] && val.docid > -1){
                                return <TreeItem itemId={(i+index)} label={val.doctitle}></TreeItem>
                            }
                            
                        })
                    )
                }
        
            }

    const printSubFolder = (i) => {
        if(i <= prevFolder.length){
            return(
                prevFolderName.map((val,index)=>{
                  if(val.parentfolderid === prevFolder[i-1] && val.parentfolderid !== val.folderid && val.folderid !==prevFolder[i] && val.foldername?.length > 0){
                       return <div className={(fileSelected.docid === val.docid) ? 'ms-Nav-compositeLink compositeLink-304-selected' : 'ms-Nav-compositeLink compositeLink-304'}><span><img src={folderIcon} /></span><TreeItem itemId={val.folderid} label={val.foldername} style={{fontSize:"14px",fontWeight:"400"}} >
                        {printSubFolderOfAll(val)}</TreeItem></div>
                    }
                    else if(val.folderid === prevFolder[i] && val.foldername?.length > 0){
                        return <div className={(fileSelected.docid === val.docid) ? 'ms-Nav-compositeLink compositeLink-304-selected' : 'ms-Nav-compositeLink compositeLink-304'}><span><img src={folderIcon} /></span><TreeItem itemId={val.folderid} label={val.foldername} style={{fontSize:"14px",fontWeight:"400"}} 
                        >{printSubFolder(i+1)}</TreeItem></div>
                    }
                    else if(val.folderid === prevFolder[i-1] && val.docid > -1){
                        return <div className={(fileSelected.docid === val.docid) ? 'ms-Nav-compositeLink compositeLink-304-selected' : 'ms-Nav-compositeLink compositeLink-304'}><img src={FileIcon} /><TreeItem itemId={(index+ val.docid)} label={val.doctitle} style={{fontSize:"14px",fontWeight:"400"}}
                        onClick={()=>history.push(`/TTSAudioContentPage/${val.docid}`)} ></TreeItem></div>
                    }
                   
                })
            )
        }

    }
    const printPrevData = (allFile) => {
        let result = Object.keys(allFile).map((key) => [key, allFile[key]]);
        return (
            result.map(ele => {
                let folfil = ele[1]
                return (
                    folfil.map((val) => {
                        return (
                            <li className="ms-Nav-navItem navItem-308">
                                <div className={(fileSelected.docid === val.docid) ? 'ms-Nav-compositeLink compositeLink-304-selected' : 'ms-Nav-compositeLink compositeLink-304'}>
                                    <button className='ms-Nav-chevronButton chevronButton-314'>{(val.foldername) && <img style={{ transform: "rotate(-90deg)" }} src={arrowIconBlue} />}</button>
                                    <button className='ms-Button ms-Button--action ms-Button--command ms-Nav-link link-486'>
                                        <span className='ms-Button-flexContainer flexContainer-320'>
                                            {(val.foldername) ? <><img src={folderIcon} /><span>{val.foldername}</span></> :
                                                <><img src={FileIcon} /><span>{val.doctitle}</span></>}
                                        </span>
                                    </button>
                                </div>
                            </li>
                        )
                    }))
            }))
    }
    return (<>
            {printdata(prevFolderName)}
    </>)
}
export default MainFolder;